<template>
    <div class="mailbox">
        <!-- 自定义nav-->
        <div class="Phonenumber-title">
            <van-icon
                name="arrow-left"
                size="2rem"
                @click="$router.push('/install')" />
            <div class="title">{{$t('addmy.ibosan')}}</div>
            <div></div>
        </div>
        <!-- 绑定邮箱/没有邮箱时-->
        <div class="ChangePassword">
            <van-form>
                <div>
                    <div class="Email">{{$t('mail.mailbox')}}</div>
                    <van-field v-model="oldpassword" :rules="[
                            {
                                required: true,
                                message: this.$t('mail.srmail'),
                                trigger: ['blur', 'onSubmit']
                            }
                        ]"/>
			
                </div>
                <!-- <div>
                    <div class="Email">{{$t('mail.yzm')}}</div>
                    <van-field v-model="newpassword" :rules="[
                            {
                                required: true,
                                message: this.$t('mail.sryzm'),
                                trigger: ['blur', 'onSubmit']
                            }
                        ]" >
                        <template #button>
                            <button class="codebutton" @click="sendcode" :disabled="codestatus" type="button">{{codecontent}}</button>
                        </template>
                    </van-field>
                </div> -->
                <div class="mailts" style="color: red;margin: 1rem 0;">
                  * {{$t('addmy.yxts')}}
                </div>
                <div class="submit">
					<div v-if="unlock==0" class="btn" @click="tishi">{{$t('addmy.appsi')}}</div>
                    <div v-else>
						<van-button v-if="conta==1" round block type="info" @click="onSubmit" native-type="submit">{{$t('ChangePassword.Determine')}}</van-button>
						<van-button v-else round block type="info" @click="tishi" native-type="submit">{{$t('ChangePassword.Determine')}}</van-button>
					</div>
					
                </div>
            </van-form>
        </div>
        <!-- 有邮箱的情况-->
        <!-- <div v-else>
            <div class="chneggong">
                <img src="../assets/chengong.png" alt="">
            </div>
            <div class="successfully">{{$t('addmy.yx')}}</div>
            <div class="info">
                <div>{{$t('addmy.appsan')}} </div>
                <div>{{conta}}</div>
            </div>
            <div class="btn" @click="tishi">{{$t('addmy.appsi')}}</div>
        </div> -->
    </div>
</template>

<script>
import { scode, emailbind, myuserapi } from '@/api/index'
import { Toast } from 'vant'
export default {
  data () {
    return {
      isemail: false,
      oldpassword: '',
      newpassword: '',
      rules: {},
      codecontent: this.$t('mail.sendcode'),
      timer: null,
      codestatus: false,
      conta: '',
	  unlock:1
    }
  },
  created () {
	  this.myuser()
    if (localStorage.getItem('is_email') == 0) { // eslint-disable-line
      this.isemail = false
    } else {
      this.isemail = true
      this.myuser()
    }
  },
  methods: {
    async sendcode () {
      const that = this
      if (that.oldpassword == '') { // eslint-disable-line
        Toast({
          message: that.$t('mail.emptyemail'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      const data = {
        email: that.oldpassword,
        event: 'changeemail'
      }
      const res = await scode(data)
      if(res.code == 0){
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      that.codecontent = 60
      that.codestatus = true
      that.timer = setInterval(() => {
        if (that.codecontent > 0) {
          that.codecontent--
        }
        if (that.codecontent == 0) { // eslint-disable-line
          that.codecontent = that.$t('mail.sendcode')
          that.codestatus = false
        }
      }, 1000)
    },
    onSubmit (values) {
      // console.log('submit', values)
      this.Changepassword()
    },
    async Changepassword () {
      const data = {
        email: this.oldpassword.trim(),
        code: this.newpassword.trim()
      }
      console.log(data)
      const res = await emailbind(data)
      if (res.code == 1) { // eslint-disable-line
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        localStorage.setItem('is_email', 1)
        this.$router.push('/my')
      }else{
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
      }
    },
    async SendCode () {
      const that = this
      const data = {
        email: that.oldpassword,
        event: 'changeemail'
      }
      const res = await scode(data)
      this.sencode = res.code
      Toast({
        message: res.msg,
        duration: 1200,
        className: 'noticeWidth' // 所加样式名
      })
      console.log(res)
    },
    async myuser () {
      const res = await myuserapi()
      // console.log('111', res)
      if(res.data.user.email) {
		  this.oldpassword = res.data.user.email
		  this.unlock = res.data.unlock
	  }else{
		  this.conta=1
	  }
      this.show = true
    },
    tishi () {
      Toast({
        message: this.$t('addmy.appts'),
        duration: 1200,
        className: 'noticeWidth' // 所加样式名
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../style/theme/style.less'; // 引入主题样式文件
    .mailbox{
      background-color: rgba(@baseColor,1);
      // background-size: 100% 25rem;
      min-height: 100vh;
      // background-size: cover;
      padding: 0rem 1.6rem;
      width: 100%;
      ::v-deep .van-icon{
        color: rgba(@apptext);
      }
        // min-height: 100vh;
      .Phonenumber-title {
        padding-top: 2.5rem;
        display: flex;
        justify-content: space-between;
        margin-bottom: 3.5rem;
        .title {
          color: rgba(@apptext);
          font-size: 1.8rem;
          // margin-left: 9rem;
          font-weight: 700;
        }
        ::v-deep .van-icon-arrow-left:before {
          margin-top: 0.4rem;
        }
      }
    }
    .aaa{
        color: red;
    }
    /* 没有邮箱时*/
    .ChangePassword {
        // background-color: #060606;
        padding: 0rem 1.6rem;
        // padding-top: 2.5rem;
        width: 100%;
        // min-height: 100vh;
        .ChangePassword-title {
            padding-top: 2.5rem;
            display: flex;
            justify-content: space-between;
            margin-bottom: 3.5rem;
            .title {
              color: rgba(@apptext);
              font-size: 1.8rem;
              // margin-left: 9rem;
              font-weight: 700;
            }
            ::v-deep .van-icon-arrow-left:before {
            margin-top: 0.4rem;
            }
        }
        ::v-deep .van-cell {
              background-color: rgba(@pageBgColor,1);
              color: rgba(@apptext);
              border: 1px solid #F6F6F6;
              border-radius: 1rem;
              // margin-top: 2rem;
              padding-left: 2rem;
              font-size: 1.3rem;
            &::after {
              border-bottom: 0px solid #000;
            }
        }
        ::v-deep .van-field__control {
          color: rgba(@apptext);
        }
        ::v-deep .position {
            position: relative;
        }
        .code {
            color: #15b4fb;
            font-size: 1.4rem;
            position: absolute;
            top: 1.2rem;
            right: 2.2rem;
        }
        ::v-deep .van-button {
            border-radius: 2.2rem;
            height: 4.4rem;
        }
        ::v-deep .van-button--info {
          background-color: #EFBB0B;
          border: none;
          border-radius: 10px;
          font-weight: bold;
          font-size: 1.6rem;
          color: #333;
        }
        ::v-deep .van-field__error-message {
            font-size: 1.4rem;
        }
        .Email {
            color: rgba(@apptext);
            margin-top: 2rem;
            margin-bottom: 1rem;
            font-size: 1.4rem;
        }
        .submit {
            margin-top: 11rem;
        }
        .bottom {
            width: 100%;
            height: 4.4rem;
            background-color: #15b4fb;
            text-align: center;
            line-height: 4.4rem;
            // margin-top: 11rem;
            border-radius: 2.75rem;
            font-size: 1.6rem;
            color: rgba(@apptext);
        }
    }
    /* 修改*/
    .codebutton{
        padding: 0 0.4rem;
        background-color: #EFBB0B;
        border: none;
        border-radius: 0.2rem;
        font-weight: bold;
        // color: rgba(@apptext);
    }
    .chneggong {
        width: 8.4rem;
        height: 8.8rem;
        margin: 0 auto;
        margin-top: 5rem;
    }
    .successfully {
        color: #858585;
        text-align: center;
        margin-top: 2rem;
        font-size: 1.3rem;
    }
    .info {
        display: flex;
        color: rgba(@apptext);
        margin-left: 50%;
        transform: translateX(-50%);
        justify-content: center;
        margin-top: 2rem;
        font-size: 1.6rem;
    }
    .btn {
      width: 80%;
      height: 4.4rem;
      background-color: #EFBB0B;
      text-align: center;
      line-height: 4.4rem;
      margin: 0 auto;
      margin-top: 15rem;
      border-radius: 10px;
      font-weight: bold;
      font-size: 1.6rem;
      color: #333;
    }
</style>
