<template>
	<!-- 地址 -->
	<div class="Address">
		<div style="height: 100vh;overflow-y: scroll;padding: 0rem 1.6rem;">
			<div class="Address-title">
				<van-icon @click="$router.back()" name="arrow-left" size="2rem" />
				<div class="title">Detalles de la garantía</div>
				<div class="img">
					<!-- <img src="../../assets/jjjl.png" alt="" /> -->
				</div>
			</div>

			<div class="activect">


				<van-list v-model="loading" :finished="finished" :finished-text="$t('Mypurse.Theres')"
					:loading-text="$t('Mypurse.Loading')" @load="onLoad">
					<div class="infoitem" v-for="(i,v) in periodlist" :key="v">
						<div class="leftbox">
							<div style="font-weight: 700;">{{i.name}}</div>
							<div style="margin-top: .8rem;">{{i.createtime}}</div>
						</div>
						<div class="rightbox">
							<div style="font-size: 1.3rem;color: #2DBE64;margin-right: .8rem;font-weight: 700;">{{i.price}} USDT</div>
							<div class="fhbox">
								<img src="../../assets/helpright.png" alt="" />
							</div>
						</div>
					</div>
				</van-list>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		buyfundls
	} from '@/api/index'
	import {
		Toast
	} from 'vant'
	export default {
		name: 'FUND',
		data() {
			return {
				perioddata: { // 历史记录
					limit: 13,
					page: 1
				},
				periodlist: [],
				periodtotal: '',
				loading: false,
				finished: false,
			}
		},
		created() {

		},
		methods: {
			async onLoad() {
				this.loading = true
				await this.getperiod()
				this.perioddata.page++
				this.loading = false
			},

			async getperiod() {
				let data = {
					limit: this.perioddata.limit,
					page: this.perioddata.page
				}
				const res = await buyfundls(data)
				this.periodtotal = res.data.total
				console.log(this.periodlist.length,this.periodtotal);
				if (this.periodlist.length >= this.periodtotal) {
					this.finished = true
				}
				
				this.periodlist.push(...res.data.data)
			},
		}
	}
</script>

<style lang="less" scoped>
	@import '../../style/theme/style.less'; // 引入主题样式文件

	.Address {
		// background: url('../../assets/jjbj.png') no-repeat;
		// background-size: 100% 90vh;
		// background-position: 0rem 8rem;
		background-color: #242533;
		// padding: 0rem 1.6rem;
		// padding-top: 2.5rem;
		width: 100%;
		height: 100vh;

		::v-deep .van-icon {
			color: rgba(@apptext);
		}

		::v-deep .van-tabs__nav {
			background-color: rgba(@baseColor, 1);
		}

		.Address-title {
			padding-top: 2.5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.title {
				color: rgba(@apptext);
				font-size: 1.6rem;
			}

			.img {
				width: 1.55rem;
				height: 1.75rem;
			}
		}

		.activect {
			margin-top: 1.5rem;

			.infoitem {

				display: flex;
				justify-content: space-between;
				align-items: center;
				color: #fff;
				font-size: 1.2rem;
				border-bottom: 1px solid #3D3D3D;
				padding: 1.5rem 0rem;
				line-height: 1;
				margin-bottom: 1.5rem;

				.leftbox {}

				.rightbox {
					display: flex;
					align-items: center;

					.fhbox {
						width: .8rem;
						height: 1.3rem;

						img {
							width: 100%;
							height: 100%;
							vertical-align: middle;
						}
					}
				}
			}
		}

		::v-deep .van-tab__text--ellipsis {
			font-size: 1.4rem;
		}

		.Bitcoin {
			width: 100%;
			height: 5.8rem;
			// background-color: #fff;
			border-radius: 1rem;
			margin-top: 1.5rem;
			// border: 2px solid #f6f6f6;
			background-color: rgba(@baseColor, 1);
			color: rgba(@apptext);
			font-size: 1.2rem;
			font-weight: 700;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0rem 1.5rem;
			margin-top: 2.5rem;


			.Bitcoin-left {
				display: flex;
				align-items: center;

				.Bitcoin-img {
					width: 2.84rem;
					height: 2.84rem;
					margin-right: 1rem;
					// margin-top: 0.5rem;
				}

				.Bitcoin-name {
					width: 24rem;
					margin-top: 0.3rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					-o-text-overflow: ellipsis;
				}
			}
		}
	}

	.message {
		margin-top: 1rem;
		color: red;
		font-weight: 700;
	}

	.colon {
		display: inline-block;
		margin: 0 4px;
		color: #ee0a24;
	}

	.block {
		display: inline-block;
		width: 22px;
		color: #fff;
		font-size: 12px;
		text-align: center;
		background-color: #ee0a24;
	}
</style>