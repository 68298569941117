<template>
  <!-- 领取红包 -->
  <div class="Redemption">
    <div class="Redemption-title">
      <van-icon
        @click="$router.back()"
        name="arrow-left"
        size="2rem"/>
      <div class="title">{{$t('Redemption.title')}}</div>
      <!-- <div class="img" @click="torecord">
        <img src="../assets/jia.png" alt="" />
      </div> -->
      <div class="img">
        <img src="" alt="" />
      </div>
    </div>
    <!-- 选择红包或代币-->
    <div class="tarcode">
      <div :class="{'tarcodeact':tarindex == 1}" @click="tabqh(1)">{{$t('addmy.codeyi')}}</div>
      <div :class="{'tarcodeact':tarindex == 2}" @click="tabqh(2)">{{$t('addmy.codeer')}}</div>
    </div>
    <div class="name">{{$t('Redemption.Please')}}</div>
    <div class="money">
      <input type="text" v-model="code"/>
    </div>
    <div class="bottom" @click="Receiveredpackets">{{$t('Redemption.Determine')}}</div>
  </div>
</template>

<script>
import { Receiveredpacketsapi } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'AddRess',
  data () {
    return {
      address: '',
      getnew: false,
      code: '',
      show: false,
      tarindex: 1,
      url: '/api/active/receive_packet'
    }
  },
  methods: {
    tabqh (index) {
      if(index == 1){
        this.url = '/api/active/receive_packet'
      }else{
        this.url = '/api/active/receive_coin_packet'
      }
      this.tarindex = index
    },
    // 领取红包
    async Receiveredpackets () {
      if (this.code !== '') {
        const data = {
          code: this.code
        }
        const res = await Receiveredpacketsapi(this.url,data)
        // console.log(res)
        if (res.code === 1) {
          Toast.success({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        } else {
          Toast.fail({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        }
      } else {
        Toast({
          message: this.$t('Redemption.Exchange'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../style/theme/style.less'; // 引入主题样式文件
.Redemption {
  // background: url('../assets/ampbk.png') no-repeat;
  // background-size: cover;
  background-color: rgba(@baseColor,1);
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  ::v-deep .van-icon{
    color: rgba(@apptext);
  }
  .Redemption-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: rgba(@apptext);
      font-size: 1.6rem;
    }
    .img {
      width: 1.55rem;
      height: 1.75rem;
    }
  }
  .name {
    color: rgba(@apptext);
    font-size: 1.5rem;
    margin-top: 3rem;
    margin-bottom: -1.5rem;
  }
  .money {
    height: 5.5rem;
    border: 1px solid #f6f6f6;
    border-radius: 1rem;
    // border-radius: 1rem;
    background-color: rgba(@pageBgColor,1);
    color: rgba(@apptext);
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    padding: 0rem 1rem;
    margin-top: 3rem;

    input {
      width: 100%;
      height: 100%;
      background-color: rgba(@pageBgColor,1);
      color: rgba(@apptext);
      border: none;
      padding: 0rem 2.5rem;
    }
    .all {
      color: #15b4fb;
      margin-left: 0.8rem;
    }
  }
  .bottom {
    width: 100%;
    height: 4.4rem;
    background-color: #EFBB0B;
    text-align: center;
    line-height: 4.4rem;
    margin-top: 27rem;
    border-radius: 10px;
    font-size: 1.6rem;
    color: #333;
  }
  ::v-deep .van-popup {
    z-index: 999;
    width: 80%;
    border-radius: 1rem;
    padding: 0rem 1.5rem;
    padding-bottom: 3rem;
    padding-top: 5rem;
    background-color: #2c2c2c;
    text-align: center;
    color: #fff;
    font-size: 1.3rem;
    .successimg {
     width: 7.9rem;
     height: 7.9rem;
     margin: 0 auto;
     margin-bottom: 3rem;
    }
    .redeemed {
    margin-top: 3rem;
    font-size: 1.8rem;
    font-weight: 600;
    span {
        color: #15b4fb;
    }
    }
  }
  //
  .tarcode{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4rem;
    div{
      padding: 1rem;
      width: 100%;
      border: 1px solid #f6f6f6;
      background-color: rgba(@pageBgColor,1);
      color: rgba(@apptext);
      text-align: center;
      font-size: 1.3rem;
    }
  }
  .tarcodeact{
    background-color: #EFBB0B !important;
    color: #333 !important;
  }
}
</style>
