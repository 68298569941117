<template>
	<!-- 地址 -->
	<div class="Address">
		<div style="height: 100vh;overflow-y: scroll;padding: 0rem 1.6rem;">
			<div class="Address-title">
				<van-icon @click="$router.back()" name="arrow-left" size="2rem" />
				<div class="title">Cambio de divisas</div>
				<div class="img">
					<!-- <img src="../../assets/jjjl.png" alt="" /> -->
				</div>
			</div>

			<div class="titbox">
				<div>Intercambio<span>(Precio OPT actual: {{zhobj.per_money}})</span></div>
				<!-- <div class="lsimg">
					<img src="../../assets/newimg/lsjl.png" alt="" />
				</div> -->
			</div>

			<div class="infobox">
				<div class="infotop">
					<div>consumir</div>
					<div class="jtbox">
						<div>Disponible: {{xzbzpic}} {{xzbz}}</div>
						<van-icon class="icon" name="arrow" size="1.3rem" />
					</div>
				</div>
				<div class="infocenter">

					<van-popover v-model="showPopover" trigger="click" :actions="actions" @select="onSelect">
						<template #reference>
							<div class="ctrle">
								<div class="yuan">
									<img :src="bimg1" alt="" />
								</div>
								<div class="txt">{{xzbz}}</div>
								<div class="xlimg">
									<img src="../../assets/newimg/xlsj.png" alt="" />
								</div>
							</div>
						</template>
					</van-popover>

					<div style="font-size: 1.5rem;">
						<input class="input" type="number" maxlength="13" @input="changeinput()" @change="changechange()"
							@blur="changeblur()" v-model="money1"  />
					</div>
				</div>

				<div style="text-align: right;color: #49A8FB;font-size: 1.3rem;" @click="allpic()">Todo</div>
			</div>

			<div class="zhbox" @click="xhzh()">
				<img src="../../assets/newimg/zh.png" alt="" />
			</div>

			<div class="infobox" style="margin-top: -.8rem;">
				<div class="infotop">
					<div>obtener</div>
					<div class="jtbox">
						<div>Disponible:{{wxzbzpic}} {{wxzbz}}</div>
						<van-icon class="icon" name="arrow" size="1.3rem" />
					</div>
				</div>
				<div class="infocenter">

					<van-popover v-model="showPopover1" trigger="click" :actions="actions" @select="onSelect1">
						<template #reference>
							<div class="ctrle">
								<div class="yuan">
									<img :src="bimg2" alt="" />
								</div>
								<div class="txt">{{wxzbz}}</div>
								<div class="xlimg">
									<img src="../../assets/newimg/xlsj.png" alt="" />
								</div>
							</div>
						</template>
					</van-popover>

					<div style="font-size: 1.5rem;">
						<input class="input" type="text" disabled v-model="money2" />
					</div>
				</div>
			</div>

			<div class="feebox">
				<div>Tarifa de manejo {{zhobj.fee}}%</div>
				<div style="font-size: 1.3rem;">{{feepic}} {{xzbz}}</div>
			</div>
			<div class="fee">Handling fee rules</div>


			<div class="blbox">1 OPT ≈ {{zhobj.per_money}} USDT</div>

			<div class="btn" @click="Conversion">Intercambio</div>
		</div>

	</div>
</template>

<script>
	import {
		Conversion,
		getConversion
	} from '@/api/index'
	import {
		Toast
	} from 'vant'
	export default {
		name: 'COnversionIndex',
		data() {
			return {
				money1: '0.00',
				money2: '0.00',
				bimg1: require('../../assets/newimg/optbi.png'),
				bimg2: require('../../assets/newimg/usdtbi.png'),
				showPopover: false,
				showPopover1: false,
				actions: [{
					text: 'OPT',
					value: 1,
					img: require('../../assets/newimg/optbi.png')
				}, {
					text: 'USDT',
					value: 2,
					img: require('../../assets/newimg/usdtbi.png')
				}],
				xzbzvalue: 1,
				xzbz: 'OPT',
				wxzbz: 'USDT',
				xzbzpic: '',
				wxzbzpic: '',
				zhobj: {},
				feepic: '0.00',
				settime:null
			}
		},
		async created() {
			await this.getConversion()
			 this.xzbzpic = this.zhobj.coin
			 this.wxzbzpic = this.zhobj.money
			this.settime = setInterval(()=>{
				this.getConversion()
			},3000)
		},
		destroyed() {
			clearInterval(this.settime)
		},
		methods: {
			onSelect(e) {
				this.money1 = '0.00'
				this.money2 = '0.00'
				this.feepic = '0.00'
				if (e.value == 1) {
					this.xzbz = 'OPT'
					this.wxzbz = 'USDT'
					this.bimg1 = this.actions[0].img
					this.bimg2 = this.actions[1].img
					this.xzbzpic = this.zhobj.coin
					this.wxzbzpic = this.zhobj.money
					this.xzbzvalue = e.value
				} else {
					this.xzbz = 'USDT'
					this.wxzbz = 'OPT'
					this.bimg1 = this.actions[1].img
					this.bimg2 = this.actions[0].img
					this.xzbzpic = this.zhobj.money
					this.wxzbzpic = this.zhobj.coin
					this.xzbzvalue = e.value
				}
			},
			onSelect1(e) {
				this.money1 = '0.00'
				this.money2 = '0.00'
				this.feepic = '0.00'
				if (e.value == 1) {
					this.xzbz = 'USDT'
					this.wxzbz = 'OPT'
					this.bimg1 = this.actions[1].img
					this.bimg2 = this.actions[0].img
					this.xzbzpic = this.zhobj.money
					this.wxzbzpic = this.zhobj.coin
					this.xzbzvalue = e.value
				} else {
					this.xzbz = 'OPT'
					this.wxzbz = 'USDT'
					this.bimg1 = this.actions[0].img
					this.bimg2 = this.actions[1].img
					this.xzbzpic = this.zhobj.coin
					this.wxzbzpic = this.zhobj.money
					this.xzbzvalue = e.value
				}
			},
			xhzh() {
				this.money1 = '0.00'
				this.money2 = '0.00'
				this.feepic = '0.00'
				if (this.xzbzvalue == 1) {
					this.xzbz = 'USDT'
					this.wxzbz = 'OPT'
					this.bimg1 = this.actions[1].img
					this.bimg2 = this.actions[0].img
					this.xzbzpic = this.zhobj.money
					this.wxzbzpic = this.zhobj.coin
					this.xzbzvalue = 2
				} else {
					this.xzbz = 'OPT'
					this.wxzbz = 'USDT'
					this.bimg1 = this.actions[0].img
					this.bimg2 = this.actions[1].img
					this.xzbzpic = this.zhobj.coin
					this.wxzbzpic = this.zhobj.money
					this.xzbzvalue = 1
				}
			},
			allpic() {
				if (this.xzbzvalue == 1) {
					this.money1 = this.zhobj.coin
					this.money2 = parseFloat(this.money1 * this.zhobj.per_money).toFixed(2)
					this.feepic = parseFloat(this.money1 * (this.zhobj.fee / 100)).toFixed(2)
				} else {
					this.money1 = this.zhobj.money
					this.money2 = parseFloat(this.money1 / this.zhobj.per_money).toFixed(2)
					this.feepic = parseFloat(this.money1 * (this.zhobj.fee / 100)).toFixed(2)
				}
			},
			changeinput() {
				if (this.xzbzvalue == 1) {
					this.money2 = parseFloat(this.money1 * this.zhobj.per_money).toFixed(2)
					this.feepic = parseFloat(this.money1 * (this.zhobj.fee / 100)).toFixed(2)
				} else {
					this.money2 = parseFloat(this.money1 / this.zhobj.per_money).toFixed(2)
					this.feepic = parseFloat(this.money1 * (this.zhobj.fee / 100)).toFixed(2)
				}
			},
			changeblur() {
				if (!this.money1 || this.money1 <= 0) {
					this.money1 = '0.00'
					this.feepic = '0.00'
				}
			},

			async getConversion() {
				const res = await getConversion()
				// console.log(res,'111');
				this.zhobj = res.data
			},
			async Conversion() {
				
				if(parseFloat(this.money1)<=0) {
					Toast({
						message: 'Por favor ingresa la cantidad que deseas transferir',
						duration: 2000,
						className: 'noticeWidth' // 所加样式名
					})
					return
				}
				
				if (this.xzbzvalue == 1) { //o转u

					let data = {
						num: this.money1,
						type: 1
					}
					const res = await Conversion(data)

					Toast({
						message: res.msg,
						duration: 2000,
						className: 'noticeWidth' // 所加样式名
					})
					if (res.code == 1) {
						setTimeout(() => {
							this.$router.go(0)
						}, 1000)
					}

				} else { //u转o

					let data = {
						num: this.money1,
						type: 2
					}
					const res = await Conversion(data)

					Toast({
						message: res.msg,
						duration: 2000,
						className: 'noticeWidth' // 所加样式名
					})

					if (res.code == 1) {
						setTimeout(() => {
							this.$router.go(0)
						}, 1000)
					}

				}

			},
		}
	}
</script>

<style lang="less" scoped>
	@import '../../style/theme/style.less'; // 引入主题样式文件

	.Address {

		background-color: rgba(@zhbg);

		color: rgba(@apptext);
		width: 100%;
		height: 100vh;

		::v-deep .van-icon {
			color: rgba(@apptext);
		}

		::v-deep .van-tabs__nav {
			background-color: rgba(@baseColor, 1);
		}

		.input {
			border: none;
			background-color: transparent;
			text-align: right;
			color: rgba(@zhtxtcolor);
		}

		.Address-title {
			padding-top: 2.5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.title {
				color: rgba(@apptext);
				font-size: 1.6rem;
			}

			.img {
				width: 1.55rem;
				height: 1.75rem;
			}
		}

		.activect {
			width: 100%;
			// height: 70vh;
			// background: url('../../assets/gzbj.png') no-repeat;
			background-size: 100% 100%;
			margin-top: 1.5rem;
			overflow-y: scroll;
			padding: 1.5rem;
			font-size: 1.3rem;
			color: #fff;
		}

		::v-deep .van-tab__text--ellipsis {
			font-size: 1.4rem;
		}

		.Bitcoin {
			width: 100%;
			height: 5.8rem;
			// background-color: #fff;
			border-radius: 1rem;
			margin-top: 1.5rem;
			// border: 2px solid #f6f6f6;
			background-color: rgba(@baseColor, 1);
			color: rgba(@apptext);
			font-size: 1.2rem;
			font-weight: 700;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0rem 1.5rem;
			margin-top: 2.5rem;

			.Bitcoin-left {
				display: flex;
				align-items: center;

				.Bitcoin-img {
					width: 2.84rem;
					height: 2.84rem;
					margin-right: 1rem;
					// margin-top: 0.5rem;
				}

				.Bitcoin-name {
					width: 24rem;
					margin-top: 0.3rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					-o-text-overflow: ellipsis;
				}
			}
		}
	}

	.message {
		margin-top: 1rem;
		color: red;
		font-weight: 700;
	}

	.colon {
		display: inline-block;
		margin: 0 4px;
		color: #ee0a24;
	}

	.block {
		display: inline-block;
		width: 22px;
		color: #fff;
		font-size: 12px;
		text-align: center;
		background-color: #ee0a24;
	}

	.lsimg {
		width: 2rem;
		height: 2rem;
	}

	.titbox {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: rgba(@apptext);
		font-size: 1.5rem;
		margin: 2.5rem 0rem;
		font-weight: 600;
		margin-top: 3rem;

		span {
			font-size: 1.2rem;
			margin-left: .3rem;
			font-weight: 500;
		}
	}

	.infobox {
		background-color: rgba(@zhbgcolor);
		padding: 1.5rem;
		border-radius: .5rem;
		font-size: 1.3rem;

		.infotop {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.jtbox {
				display: flex;
				align-items: center;
				font-size: 1.2rem;
				color: rgba(@zhtxtcolor);

				.icon {
					margin-top: .1rem;
					color: rgba(@zhtxtcolor);
				}
			}
		}

		.infocenter {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 2.5rem 0rem;

			.ctrle {
				display: flex;
				align-items: center;

				.yuan {
					width: 2.5rem;
					height: 2.5rem;
					border-radius: 50%;

					// background: #15B4FB;
					img {
						width: 100%;
						height: 100%;
					}
				}

				.txt {
					margin-left: .5rem;
					margin-right: 1rem;
				}

				.xlimg {
					width: 1rem;
					height: .7rem;
					vertical-align: middle;
					margin-top: -1.3rem;
				}
			}
		}
	}

	.zhbox {
		width: 4.3rem;
		height: 4.3rem;
		padding: .8rem;
		border-radius: 50%;
		background-color: rgba(@zhbgcolor);
		margin: 0 auto;
		margin-top: -.8rem;
	}

	.feebox {
		width: 100%;
		border: 1.5px solid rgba(@zhtxtcolor);
		border-radius: .5rem;
		height: 5rem;
		display: flex;
		line-height: 5rem;
		justify-content: space-between;
		align-items: center;
		padding: 0rem 1.5rem;
		font-size: 1.2rem;
		margin-top: 3rem;
	}

	.fee {
		color: #6BE4B8;
		font-size: 1.2rem;
		text-align: right;
		margin-top: .8rem;
	}

	.blbox {
		font-size: 1.2rem;
		color: rgba(@zhtxtcolor);
		text-align: center;
		margin-top: 5rem;
	}

	.btn {
		width: 100%;
		height: 5rem;
		text-align: center;
		line-height: 5rem;
		background-color: #EFBB0B;
		color: #fff;
		box-shadow: 0rem .1rem .3rem .1rem rgba(17, 114, 198, 0.3);
		border-radius: 4rem;
		margin-top: 2rem;
		font-size: 1.5rem;
	}
</style>