import request from '@/utils/require'

// 登录
export function getlogin (data) {
  return request({
    url: '/api/user/login',
    method: 'post',
    data
  })
}
// 忘记密码
export function forgotpasswordapi (data) {
  return request({
    url: '/api/user/resetpwd',
    method: 'post',
    data
  })
}
// 签到按钮
export function getreceive () {
  return request({
    url: '/api/index/signin',
    method: 'post'
  })
}
// 签到天数页面
export function getSignin () {
  return request({
    url: '/api/index/sign',
    method: 'post'
  })
}
// 签到日志
export function getCheckinlog () {
  return request({
    url: '/api/index/signRecord',
    method: 'post'
  })
}
// 首页详情数据
export function gethomeapi () {
  return request({
    url: '/api/index/index',
    method: 'post'
  })
}
// 公告数据
export function Noticelistapi (data) {
  return request({
    url: '/api/index/notice',
    method: 'post',
    data
  })
}
// 团队页面
export function getpartyapi (data) {
  return request({
    url: '/api/team/index',
    method: 'post',
    data
  })
}
// 帮助详情
export function assistdetailsapi (data) {
  return request({
    url: '/api/index/helpList',
    method: 'post',
    data
  })
}
// 购买矿机
export function Miningmachineapi () {
  return request({
    url: '/api/mining/products',
    method: 'post'
  })
}
// 产品购买
export function Productpurchaseapi (data) {
  return request({
    url: '/api/mining/buy',
    method: 'post',
    data
  })
}
// 开启设备
export function startdeviceapi () {
  return request({
    url: '/api/mining/startMiner',
    method: 'post'
  })
}
// 开始倒计时
export function startCountdownapi () {
  return request({
    url: '/api/mining/runningStatus',
    method: 'post'
  })
}
// 团队详情
export function teamdetailsapi (params) {
  return request({
    url: '/api/team/levelDetail',
    method: 'post',
    params
  })
}
// usdt充值
export function rechargeapi (data) {
  return request({
    url: '/api/recharge/do_recharge',
    method: 'post',
    data
  })
}
// 银行卡页面的所有充值通道
export function bankapi (data, url) {
  return request({
    url,
    method: 'post',
    data
  })
}
// // 投注猫充值
// export function bettingcatsapi (data) {
//   return request({
//     url: '/api/recharge/do_betcat_pay',
//     method: 'post',
//     data
//   })
// }
// 充值记录
export function RechargeRecordapi (data) {
  return request({
    url: '/api/recharge/history',
    method: 'post',
    data
  })
}
// 提现页面
export function withdrawalapi () {
  return request({
    url: '/api/withdraw/index',
    method: 'post'
  })
}
// 提现接口
export function withdrawalinfoapi (data) {
  return request({
    url: '/api/withdraw/do_withdraw',
    method: 'post',
    data
  })
}
// 提现记录
export function Withdrawalsrecordapi (data) {
  return request({
    url: '/api/withdraw/history',
    method: 'post',
    data
  })
}
// 修改会员
export function modifyvipapi () {
  return request({
    url: '/api/user/updateMemberData',
    method: 'post'
  })
}
// 新增提现地址
export function addressapi (data) {
  return request({
    url: '/api/user/updateBindWalletAddress',
    method: 'post',
    data
  })
}
// 购买记录
export function Purchaserecordapi () {
  return request({
    url: '/api/mining/myMiner',
    method: 'post'
  })
}
// 我的页面
export function myuserapi () {
  return request({
    url: '/api/user/getdata',
    method: 'post'
  })
}
// 修改信息
export function Modifyinformationapi (data) {
  return request({
    url: '/api/user/updateMemberData',
    method: 'post',
    data
  })
}
// 修改密码
export function Changepasswordapi (data) {
  return request({
    url: '/api/user/changepwd',
    method: 'post',
    data
  })
}
// 修改电话
export function Changenumberapi (data) {
  return request({
    url: '/api/user/changemobile',
    method: 'post',
    data
  })
}
// 获取验证码
export function obtaincodeapi (data) {
  return request({
    url: '/api/Sms/send',
    method: 'post',
    data
  })
}
// k线图
export function Candlestickchartapi (data) {
  return request({
    url: '/api/huobi/get_trade',
    method: 'post',
    data
  })
}
// 获取图片地址
export function Uploadpicturesapi (data) {
  return request({
    url: '/api/common/upload',
    method: 'post',
    data
  })
}
// 上传图片接口
export function Uploadimgapi (data) {
  return request({
    url: '/api/recharge/upload_image',
    method: 'post',
    data
  })
}
// 上传头像
export function headimgapi (data) {
  return request({
    url: '/api/user/upAvatar',
    method: 'post',
    data
  })
}
// 领取红包
export function Receiveredpacketsapi (url,data) {
  return request({
    url,
    method: 'post',
    data
  })
}
// 所有信息
export function allrecordsapi (data) {
  return request({
    url: '/api/user/funds_record',
    method: 'post',
    data
  })
}
// 视频介绍
export function Videointroductionapi () {
  return request({
    url: '/api/index/viewList',
    method: 'post'
  })
}
// 公司介绍
export function CompanyProfileapi () {
  return request({
    url: '/api/index/aboutGs',
    method: 'post'
  })
}
// 注册接口
export function registerapi (data) {
  return request({
    url: '/api/user/register',
    method: 'post',
    data
  })
}
// 团队页面领取工资
export function Receivesalaryapi () {
  return request({
    url: '/api/team/receive',
    method: 'post'
  })
}
// 活动列表
export function activitylistapi () {
  return request({
    url: '/api/active/get_lists',
    method: 'post'
  })
}
// 热门矿机
export function Hotminingapi () {
  return request({
    url: '/api/active/hot_products',
    method: 'post'
  })
}
// 折扣矿机
export function discountedproductsapi () {
  return request({
    url: '/api/active/discount_products',
    method: 'post'
  })
}
// 大转盘抽奖
// export function bigturntableapi () {
//   return request({
//     url: '/api/active/discount_products',
//     method: 'post'
//   })
// }
// 余额宝
export function balancepreciousapi () {
  return request({
    url: '/api/yuebao/products',
    method: 'post'
  })
}
// 余额宝购买
export function PurchaseonYuebaoapi (data) {
  return request({
    url: '/api/yuebao/buy',
    method: 'post',
    data
  })
}
// 余额宝购买记录
export function yebpurchaserecordapi (data) {
  return request({
    url: 'api/yuebao/myProducts',
    method: 'post',
    data
  })
}
// 设备点击量
export function DeviceHitsapi (data) {
  return request({
    url: 'api/mining/click',
    method: 'post',
    data
  })
}
// 新增银行卡
export function postbankapi (data) {
  return request({
    url: 'api/user/updateBindWalletBank',
    method: 'post',
    data
  })
}
// 退出登录
export function loginoutapi (data) {
  return request({
    url: '/api/user/logout',
    method: 'post',
    data
  })
}
// 绑定应用程序
export function bangdingapi (data) {
  return request({
    url: '/api/user/updateMemberData',
    method: 'post',
    data
  })
}
// 充值通道
export function Rechargechannelapi () {
  return request({
    url: '/api/Recharge/index',
    method: 'post'
  })
}
// 大转盘数据
export function turntablelistapi () {
  return request({
    url: '/api/Turntable/data',
    method: 'post'
  })
}
// 转盘开始游戏
export function Turntablestartapi (data) {
  return request({
    url: '/api/Turntable/run',
    method: 'post',
    data
  })
}
// 转盘记录
export function turntablerecordapi (data) {
  return request({
    url: '/api/Turntable/history',
    method: 'post',
    data
  })
}
// 发送邮箱验证码
export function scode (data) {
  return request({
    url: '/api/ems/send',
    method: 'post',
    data
  })
}

// 发送手机验证码
export function mcode (data) {
  return request({
    url: '/api/sms/send',
    method: 'post',
    data
  })
}

// 绑定邮箱
export function emailbind (data) {
  return request({
    url: '/api/user/emailbind',
    method: 'post',
    data
  })
}
// 获取今日利润
export function getMyBonus () {
  return request({
    url: '/api/team/getMyBonus',
    method: 'post'
  })
}
// 审核充值信息上传图片
export function uploadimg (data) {
  return request({
    url: '/api/common/upload',
    method: 'post',
    data
  })
}
// 请求获取信息充值
export function getoffline (url) {
  return request({
    url,
    method: 'get'
  })
}
// 发送充值
export function postoffline (data) {
  return request({
    url: '/api/recharge/offline',
    method: 'post',
    data
  })
}
// 获取绑定电报url
export function getservice (url) {
  return request({
    url,
    method: 'get'
  })
}
// 团队收取佣金
export function dailyTeamIncome (url) {
  return request({
    url,
    method: 'get'
  })
}
// 获取充值通道外链
export function geturl (url, data) {
  return request({
    url,
    method: 'post',
    data
  })
}
// 获取当前期数接口
export function get_period (url,data) {
  return request({
    url,
    method: 'post',
    data
  })
}
// 参数--K线
export function prop (url,data) {
  return request({
    url,
    method: 'post',
    data
  })
}
// 下注
export function bet (url,data) {
  return request({
    url,
    method: 'post',
    data
  })
}
// 下注记录
export function bet_records (url,data) {
  return request({
    url,
    method: 'post',
    data
  })
}
//往期中奖历史记录
export function period_histroy (url,data) {
  return request({
    url,
    method: 'post',
    data
  })
}
// k线图--title
export function get_huobi (url) {
  return request({
    url,
    method: 'get'
  })
}
//k线图
export function get_game_trade (url,data) {
  return request({
    url,
    method: 'post',
    data
  })
}
// 预测机器人列表
export function robotList () {
  return request({
    url: '/api/robot/robotList',
    method: 'post'
  })
}
// 预测信息
export function robotInfo (data) {
  return request({
    url: '/api/robot/robotInfo',
    method: 'post',
    data
  })
}
// 预测下注
export function betpre (data) {
  return request({
    url: '/api/btc/betpre',
    method: 'post',
    data
  })
}
// 活动列表 api/task/list
export function hdlist (data) {
  return request({
    url: '/api/task/list',
    method: 'post',
    data
  })
}
// 活动奖励领取
export function hdreceive (data) {
  return request({
    url: '/api/task/receive',
    method: 'post',
    data
  })
}
// 活动奖励领取
export function upshare (data) {
  return request({
    url: '/api/task/share',
    method: 'post',
    data
  })
}
// 用户在线时长
export function onlinetime (data) {
  return request({
    url: '/api/task/onlinetime',
    method: 'post',
    data
  })
}
// 活动详情
export function postactive (data) {
  return request({
    url: '/api/active/get_detail',
    method: 'post',
    data
  })
}

// 用户信息
export function yhxx () {
  return request({
    url: '/api/msgboard/index',
    method: 'post'
  })
}

// 评论
export function add_msg (data) {
  return request({
    url: '/api/msgboard/add_msg',
    method: 'post',
    data
  })
}

// 评论
export function newmin (data) {
  return request({
    url: '/api/active/discount_products',
    method: 'post',
    data
  })
}

// 活动机器人
export function newprods (data) {
  return request({
    url: '/api/active/products',
    method: 'post',
    data
  })
}

// 基金列表
export function getfund (data) {
  return request({
    url: '/api/fund/index',
    method: 'post',
    data
  })
}

// 购买基金
export function buyfund (data) {
  return request({
    url: '/api/fund/buy',
    method: 'post',
    data
  })
}

// 购买记录
export function buyfundls (data) {
  return request({
    url: '/api/fund/myProducts',
    method: 'post',
    data
  })
}

// 退还余额
export function backfundls (data) {
  return request({
    url: '/api/fund/back_money',
    method: 'post',
    data
  })
}

// 转换
export function Conversion (data) {
  return request({
    url: '/api/active/transfer',
    method: 'post',
    data
  })
}

// 获取转换信息
export function getConversion (data) {
  return request({
    url: '/api/active/transfer_index',
    method: 'post',
    data
  })
}
