<template>
  <!-- 购买记录 -->
  <div class="Purchaserecord">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh"
    :pulling-text="$t('addpull.pullyi')" :loosing-text="$t('addpull.puller')" :loading-text="$t('addpull.pullsan')" pull-distance='100'>
      <div style="min-height: 100vh;">
    <div class="Purchaserecord-title">
      <van-icon
        @click="$router.back()"
        name="arrow-left"
        size="2rem"
      />
      <div class="title">{{$t('Purchaserecord.title')}}</div>
      <div class="img">
        <img src="" alt="" />
      </div>
    </div>
    <div
      class="Bitcoin"
      v-for="(i, v) in list"
      :key="v"
      @click="popupshow(i.id)"
    >
      <div class="left">
        <div style="font-weight: 700;font-size: 1.3rem;">{{ i.category }}</div>
        <div class="Bitcoinbox">
          <div>{{ i.mining_name }}</div>
          <div style="color:#02D79A;font-weight: 700;margin-top: -1rem;">{{ i.mining_price }} USDT</div>
        </div>
      </div>
      <div class="right">
        <div class="RightArrow">
          <img src="../assets/RightArrow.png" alt="" />
        </div>
      </div>
    </div>
    <van-popup v-model="show" closeable>
      <div class="popupbox">
        <div class="title">* {{$t('Purchaserecord.types')}}</div>
        <div>{{info.category}}</div>
      </div>
      <div class="popupbox">
        <div class="title">* {{$t('Purchaserecord.product')}}</div>
        <div>{{info.mining_name}}</div>
      </div>
      <div class="popupbox">
        <div class="title">* {{$t('Purchaserecord.price')}}</div>
        <div>{{info.mining_price}}</div>
      </div>
      <div class="popupbox">
        <div class="title">* {{$t('Purchaserecord.Daily')}}</div>
        <div>{{info.daily_income}}</div>
      </div>
      <div class="popupbox">
        <div class="title">* {{$t('Purchaserecord.start')}}</div>
        <div>{{info.starttime}}</div>
      </div>
      <div class="popupbox">
        <div class="title">*  {{$t('Purchaserecord.end')}}</div>
        <div>{{info.endtime}}</div>
      </div>
    </van-popup>
     </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { Purchaserecordapi } from '@/api/index'
export default {
  name: 'PurchaseRecord',
  data () {
    return {
      show: false,
      list: [],
      info: {},
      isLoading: false
    }
  },
  created () {
    this.Purchaserecord()
  },
  methods: {
    onRefresh () {
      setTimeout(() => {
        this.$router.go(0)
      }, 1000)
    },
    async Purchaserecord () {
      const res = await Purchaserecordapi()
      // console.log('111', res)
      this.list = res.data.list
    },
    popupshow (id) {
      this.show = true
      this.list.forEach((i, v) => {
        if (i.id === id) {
          this.info = this.list[v]
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../style/theme/style.less'; // 引入主题样式文件
.Purchaserecord {
  background-color: rgba(@baseColor,1);
  // background-image: url('../assets/suoyou.png');
  background-size: 100%  25rem;
  background-repeat:no-repeat;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  ::v-deep .van-icon{
    color: rgba(@apptext);
  }  
  .Purchaserecord-title {
    padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: rgba(@apptext);
      font-size: 1.6rem;
    }
    .img {
      width: 1.55rem;
      height: 1.75rem;
    }
  }
  .Bitcoin {
    width: 100%;
    // background-color: #F6F6F6;
    border-bottom: 1px solid #F1F1F1;
    // border-radius: 1rem;
    margin-top: 2.5rem;
    color: rgba(@apptext);
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      width: 100%;
      .Bitcoinbox {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        margin-top: 0.6rem;
        color: #A7A7A7;
      }
    }
    .right {
      margin-left: 2rem;
      .RightArrow {
        width: 0.8rem;
        height: 0.8rem;
        margin-top: -0.5rem;
      }
    }
  }
  ::v-deep .van-popup {
    width: 30rem;
    border-radius: 1rem;
    padding: 0rem 1.5rem;
    padding-bottom: 3rem;
    padding-top: 5rem;
    background-color: rgba(@baseColor,1);
    color: rgba(@apptext);
    .popupbox {
      display: flex;
      justify-content: space-between;
      margin: 2rem 0rem;
      font-size: 1rem;
      .title {
        color: #8b8886;
        font-size: 1rem;
      }
    }
  }
}
</style>
