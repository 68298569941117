<template>
  <div class="Activity">
    <!-- 自定义nav-->
    <div class="nav">
      <div class="navpos">
        <div @click="$router.push('/my')">
          <img src="../assets/hdback.png" />
        </div>
        <div style="margin-left: 13rem;">{{$t('addtask.title')}}</div>
      </div>
      <!-- <div @click="$router.push('/TaskRecord')">Task Record</div> -->
    </div>
    <!-- 内容-->
    <div class="body">
      <div class="content" v-for="(item,index) in hdarr" :key="index">
        <div class="conimg">
          <img :src="item.image" />
        </div>
        <div class="concenter" sty="width: 100%">
          <div>
            <div class="one">{{item.title}}（{{item.plan}}）</div>
            <div class="two">{{item.content}}</div>
            <div class="san">
              <img src="../assets/hdjb.png" />
              +{{item.award_money}} 
              <span v-if="item.award_type == 1">USDT</span>
              <span v-if="item.award_type == 2">Points</span>
              <span v-if="item.award_type == 3">OPT</span>
            </div>
          </div>
          <div class="conright" @click="joinnow(item.status,item.id,item.type)">{{$t('addtask.conright')}}<br />{{$t('addtask.conrighter')}}</div>
        </div>
      </div>
    </div>
    <!-- 内容-->
  </div>
</template>
<script>
import { hdlist, hdreceive } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'ActiVity',
  data (){
    return {
      hdarr: [],
      isbolean: true
    }
  },
  created (){
    this.gethdlist()
  },
  methods: {
    async gethdlist (){
      const res = await hdlist()
      console.log(res)
      if(res.code == 1){
        this.hdarr = res.data
      }
    },
    async joinnow (status,id,type){
      if (this.isbolean) {
        this.isbolean = false
        if(type == 3 && status == 0){ // 跳转分享
          this.$router.push({
            path: '/Share',
            query:{
              id
            }
          })
          return false
        }
        if(status == 1){
          Toast({
            message: this.$t('addtask.tsyi'),
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
          this.isbolean = true
          return false
        }else if(status == 0){
          Toast({
            message: this.$t('addtask.tser'),
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
          this.isbolean = true
          return false
        }
        const data = {
          id: id
        }
        const res = await hdreceive(data)
        if(res.code == 1){
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
          this.isbolean = true
          this.gethdlist()
        }else{
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
          this.isbolean = true
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '../style/theme/style.less'; // 引入主题样式文件
.Activity{
  // min-height: 300vh;
  // height: 200vh;
  // background-image: url('../assets/hdbk.png');
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  background-color: rgba(@baseColor,1);
  // background-position: center center;
  min-height: 100vh;
  padding-top: 2rem;
  padding-bottom: 3rem;
  .nav{
    color: rgba(@apptext);
    font-weight: 700;
    display: flex;
    // justify-content: space-between;   background-image: url('../assets/hdbk2.png');
    align-items: center;
    font-size: 1.5rem;
    padding: 0 3rem;
    height: 8rem;
    width: 100%;
    position: relative;
    // position: absolute
    .navpos{
      position: absolute;
      top: 1.3rem;
      display: flex;
      align-items: center;
    }
    img{
      width: 1rem;
      height: 1.5rem;
    }
  }
  .body{
    margin: 0 2rem;
    margin-bottom: 0;
    .content{
      display: flex;
      // justify-content: space-between;
      align-items: center;
      color: #fff;
      border: 2px solid #5A50E8;
      padding: 1.5rem;
      border-radius: 1rem;
      margin-top: 2rem;
      .conimg{
        // margin-left: 4rem;
        width: 5rem;
        margin-right: 1rem;
        img{
          width: 100%;
        }
      }
      .concenter{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .conright{
          background: #5A50E8;
          box-shadow: 0px 3px 6px 1px rgba(88,78,228,0.5);
          opacity: 1;
          border-radius: 50%;
          padding: .5rem 1rem;
          font-weight: 700;
          margin-left: 2rem;
        }
        .one{
          font-size: 1.5rem;
          font-weight: 700;
        }
        .two{
          color: #9E9E9E;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          font-size: 1rem;
          margin: .5rem 0;
        }
        .san{
          color: #9E9E9E;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          img{
            width: 2rem;
            height: 2rem;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
