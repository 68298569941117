<template>
	<!-- 地址 -->
	<div class="Address">
		<div class="Address-title">
			<van-icon @click="$router.back()" name="arrow-left" size="2rem" />
			<div class="title">Centro De Actividades</div>
			<div class="img">

			</div>
		</div>

		<div class="activect">
			<div class="activectitem" @click="toinfo(i.id)" v-for="(i,v) in axtivelist" :key="v">
				<img style="width: 100%;height: 100%;" :src="i.image" alt="" />
				<div class="title">
					<div v-if="i.days>0" style="display: flex;font-size: 14px;white-space:nowrap;">Tiempo Restante : 
					<!-- <van-count-down style="line-height: 3rem;color: #fff;margin-left: .5rem;" @finish="finish" :time="i.days" /> -->
					<van-count-down style="line-height: 3rem;color: #fff;margin-left: .5rem;white-space:nowrap;" @finish="finish" :time="i.days" format="DD Cielo HH : mm : ss " />
					</div>
					<div v-else>El evento ha terminado</div>
					<div></div>
					<!-- <div>Número de personas: {{i.num}} Gente</div> -->
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		activitylistapi
	} from '@/api/index'
	import {
		Toast
	} from 'vant'
	export default {
		name: 'ActivityCenter',
		data() {
			return {
				active: 0,
				address: '',
				// getnew: false,
				bank: '',
				revise: '', // 修改地址
				emali: '',
				jiaurl: 0,
				unlock: '',
				axtivelist:[],
				time:30 * 60 * 60 * 1000,
			}
		},
		created() {
	      this.activitylistapi()
		},
		methods: {
			toinfo(id) {
				this.activitylistapi()
				this.axtivelist.forEach((i,v)=>{
					if(i.id == id) {
						if(i.status_switch==1) {
							if(i.type==8) {
									this.$router.push({
										path: '/Activityinfos',
										query: {
											id
										}
									})
							}else {
								this.$router.push({
									path: '/Activityinfo',
									query: {
										id
									}
								})
							}
						}else{
							Toast({
							  message: 'El evento no ha comenzado.',
							  duration: 1200,
							  className: 'noticeWidth' // 所加样式名
							})
						}
					}
				})
				
			},
			async activitylistapi() {
				const res = await activitylistapi()
				// console.log(res);
				this.axtivelist = res.data
			},
			finish() {
				this.activitylistapi()
			}
		}
	}
</script>

<style lang="less" scoped>
	@import '../style/theme/style.less'; // 引入主题样式文件

	.Address {
		// background: url('../assets/ampbk.png') no-repeat;
		// background-size: cover;
		background-color: rgba(@baseColor, 1);
		padding: 0rem 1.6rem;
		// padding-top: 2.5rem;
		width: 100%;
		min-height: 100vh;

		::v-deep .van-icon {
			color: rgba(@apptext);
		}

		::v-deep .van-tabs__nav {
			background-color: rgba(@baseColor, 1);
		}

		.Address-title {
			padding-top: 2.5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.title {
				color: rgba(@apptext);
				font-size: 1.6rem;
			}

			.img {
				width: 1.55rem;
				height: 1.75rem;
			}
		}

		.activect {
			margin-top: 3rem;

			.activectitem {
				height: 12rem;
				// background: url('../assets/itemhd.png') no-repeat;
				// background-size: 100% 100%;
				display: flex;
				justify-content: space-between;
				// align-items: center;
				// padding: 1.5rem 1rem;
				// border: 1px solid rgba(@indextext);
				// background-color: rgba(@indextext);
				border-radius: .5rem;
				margin-bottom: 2rem;
				position: relative;

				img {
					width: 3.5rem;
					margin-bottom: .5rem;
				}

				// div{
				//   // text-align: center;
				//   // color: rgba(@indextext);
				//     color: rgba(@baseColor,1);
				// }
				.title {
					width: 100%;
					height: 3rem;
					line-height: 3rem;
					font-size: 1.2rem;
					padding: 0rem .5rem;
					color: #fff;
					display: flex;
					justify-content: space-between;
					background: rgba(0, 0, 0, .4);
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}

		::v-deep .van-tab__text--ellipsis {
			font-size: 1.4rem;
		}

		.Bitcoin {
			width: 100%;
			height: 5.8rem;
			// background-color: #fff;
			border-radius: 1rem;
			margin-top: 1.5rem;
			// border: 2px solid #f6f6f6;
			background-color: rgba(@baseColor, 1);
			color: rgba(@apptext);
			font-size: 1.2rem;
			font-weight: 700;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0rem 1.5rem;
			margin-top: 2.5rem;

			.Bitcoin-left {
				display: flex;
				align-items: center;

				.Bitcoin-img {
					width: 2.84rem;
					height: 2.84rem;
					margin-right: 1rem;
					// margin-top: 0.5rem;
				}

				.Bitcoin-name {
					width: 24rem;
					margin-top: 0.3rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					-o-text-overflow: ellipsis;
				}
			}
		}
	}

	.message {
		margin-top: 1rem;
		color: red;
		font-weight: 700;
	}
	  .colon {
	    display: inline-block;
	    margin: 0 4px;
	    color: #ee0a24;
	  }
	  .block {
	    display: inline-block;
	    width: 22px;
	    color: #fff;
	    font-size: 12px;
	    text-align: center;
	    background-color: #ee0a24;
	  }
</style>