<template>
	<!-- 地址 -->
	<div class="Address">
		<div class="Address-title" style="position: fixed;top: 0;left: 0; width: 100%;">
			<van-icon style="z-index: 99999;" @click="$router.back()" name="arrow-left" size="2rem" />
			<div class="title">{{itemobj.title}}</div>
			<div class="img">
		
			</div>
			
		</div>
		<div style="position: relative;">
			<img class="bjimg" src="../assets/newinfos.png" alt="" />
			<div class="infobox">
				<div> {{itemobj.begintime}} -- {{itemobj.endtime}}</div>
			</div>
		</div>
	</div>
</template>

<script>

	import { Miningmachineapi, Productpurchaseapi, DeviceHitsapi,postactive,newmin,newprods } from '@/api/index'
	import {
		Toast
	} from 'vant'
	export default {
		name: 'ActivityCenters',
		data() {
			return {
				active: 0,
				isid:'',
				itemobj:{},
				typelist:[],
				show: false,
				checked: true,
				list:{},
				add_num: 1,
				imgUrl:''
			}
		},
		async created() {
	         // console.log(this.$route.query.id,'123');
			 this.isid = this.$route.query.id
			await this.postactive()
			await this.Miningmachine()
		},
		methods: {
			toinfo(id) {
				this.$router.push({
					path: '/Activityinfo',
					query: {
						id
					}
				})
			},
			async postactive() {
				let data = {
					id:this.isid
				}
				const res = await postactive(data)
				this.itemobj = res.data
				this.imgUrl = res.data.head_img
			},
			torecharge () {
			  // 去充值页面
			  this.$router.push('/recharges')
			},
			async showcell (id) {
			  this.checked = true
			  this.id = id
			  this.show = true
			  this.typelist.forEach(async (i) => {
			    if (i.id === id) {
			      this.list = await i
			      // console.log('6666', this.Miningmachinelist[v])
			      this.$refs.boxinfo.innerHTML = await this.list.detail
			    }
			  })
			  const data = {
			    pro_id: id
			  }
			  await DeviceHitsapi(data)
			},
			onSelect (item) {
			  // 默认情况下点击选项时不会自动收起
			  // 可以通过 close-on-click-action 属性开启自动收起
			  this.show = false
			  this.checked = true
			  //   Toast(item.name)
			},
			jiannum() { // -
			  if(this.add_num == 0){
			    Toast({
			      message: this.$t('addmin.tser'),
			      duration: 1200,
			      className: 'noticeWidth' // 所加样式名
			    })
			    return false
			  }
			  this.add_num--
			},
			// 购买数量
			addnum (){ // +
			  // console.log(this.list.can_buy)
			  if(this.list.can_buy == 0 || this.add_num >= this.list.can_buy){
			    Toast({
			      message: this.$t('addmin.tsyi'),
			      duration: 1200,
			      className: 'noticeWidth' // 所加样式名
			    })
			    return false
			  }
			  this.add_num++
			},
			// 产品购买
			async Purchase () {
			  if(this.list.can_buy == 0){
			    Toast({
			      message: this.$t('addmin.tssan'),
			      duration: 1200,
			      className: 'noticeWidth' // 所加样式名
			    })
			    return false
			  }
			  if(this.add_num == 0){
			    Toast({
			      message: this.$t('addmin.tssi'),
			      duration: 1200,
			      className: 'noticeWidth' // 所加样式名
			    })
			    return false
			  }
			  if (this.checked) {
			    const data = {
			      pro_id: this.id,
			      buy_num: this.add_num
			    }
			    const res = await Productpurchaseapi(data)
			    // console.log('0123', res)
			    Toast({
			      message: res.msg,
			      duration: 1200,
			      className: 'noticeWidth' // 所加样式名
			    })
			    this.show = false
			    this.checked = false
			    this.typelist1 = []
			    this.typelist2 = []
			    // 购买之后重新获取数据
			    this.Miningmachine()
			  } else {
			    Toast({
			      message: this.$t('Mining.first'),
			      duration: 1200,
			      className: 'noticeWidth' // 所加样式名
			    })
			  }
			},
			// 购买矿机
			async Miningmachine () {
				let data = {
					id:this.isid
				}
			  const res = await newprods(data)
			  
			  this.Experience = res.data.product_list.category
			  // this.Advanced = res.data.product_type[1].category
			   this.typelist = res.data.product_list
			
			 
			},
		}
	}
</script>

<style lang="less" scoped>
	@import '../style/theme/style.less'; // 引入主题样式文件

	.Address {
		width: 100%;
		min-height: 100vh;
		overflow-y: scroll;
		// background: url('../assets/newinfos.png') no-repeat;
		
		// background-size: 100% 100%;
		background-color: rgba(@baseColor, 1);
		// padding: 0rem 1.6rem;
		// padding-top: 2.5rem;
		// padding: 0rem 1.6rem;
		
        // padding-bottom: 3rem;
		
		::v-deep .van-icon {
			color: rgba(@apptext);
		}

		::v-deep .van-tabs__nav {
			background-color: rgba(@baseColor, 1);
		}


    .kjbox {
		margin-top: 2rem;
		// padding: 1rem 1.6rem;
		// background-color: red;
		background-color: rgba(0, 0, 0, .5);
		color: rgba(@apptext);
		border-radius: 2rem;
	.Content {
    width: 100%;
    // height: 39.1rem;
    background-color: rgba(0, 0, 0, .5);
    // margin-top: 2rem;
    box-shadow: 0px 3px 12px 1px rgba(0,0,0,0.08);
    border-radius: 2rem;
    padding-bottom: 1.55rem;
	
    .top {
      width: 100%;
      position: relative;
      height: 20.25rem;
      border-radius: 1rem 1rem 0rem 0rem;
      .img {
        width: 100%;
        height: 18.25rem;
        img {
          width: 100%;
          height: 18.25rem;
        }
      }
      .Content-title {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        color: #fff;
        width: 100%;
        // height: 3rem;
        line-height: 3rem;
        font-size: 1.3rem;
        font-weight: bold;
        padding: .5rem 1.3rem;
        background-color: rgba(0, 0, 0, .6);
		border-radius: 2rem;
        // opacity: 0.7;
        // border-top-left-radius: 1rem;
        // border-top-right-radius: 1rem;
        .green {
          right: 0;
          color: rgba(40, 180, 251, 1);
        }
      }
      .number {
        position: absolute;
        bottom: 0.5rem;
        right: 1rem;
        color: #fc9900;
        //  font-weight: 600;
        font-size: 1.3rem;
      }
    }
    .Content-info {
      color: rgba(@apptext);
      display: flex;
      justify-content: space-between;
      padding: .5rem 0rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin: 0rem 1.3rem;
      font-size: 1.2rem;
      .left {
        color: rgba(@apptext,1);
      }
      .right{
        color: #2DBE64;
        font-weight: 700;
      }
    }
    .tips {
      color: #fb8815;
      font-size: 1.1rem;
      margin-left: 1.3rem;
      // margin-top: 1rem;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      margin: 0rem 1.3rem;
      margin-top: .75rem;
      .left,
      .right {
        width: 14.9rem;
        height: 4.4rem;
        background-color: #EFBB0B;
        border-radius: 0.7rem;
        text-align: center;
        line-height: 4.4rem;
        color: #333;
        font-size: 1.3rem;
        font-weight: 600;
      }
      .right {
        background-color: rgba(@baseColor,1);
        color: rgba(@apptext);
        border: 1px solid rgba(@apptext);
        // border: 2px solid #161618;
      }
    }
  }
	  }


		.Address-title {
			padding: 2.5rem 1.6rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
            z-index: 9999;
			position: relative;
			.title {
				color: rgba(@apptext);
				font-size: 1.6rem;
				 z-index: 9999;
			}

			.img {
				width: 1.55rem;
				height: 1.75rem;
				 z-index: 9999;
			}
		}
		
		.newinfobox {
			width: 100%;
			padding: 1rem;
			border: .5rem solid #fff47b;
			border-radius: 1rem;
			color: #fff;
			line-height: 1.5;
			font-size: 1.5rem;
			// text-indent: 2em;
			text-align: center;
			// margin-top: 10rem;
			
			
		}
		
		.infobox {
			width: 100%;
			z-index: 999;
			text-align: center;
			color: #fff;
			font-size: 1.2rem;
			line-height: 1.5;
			position: absolute;
			top: 33rem;
			left: 50%;
			transform: translateX(-50%);
			div {
				width: 100%;
				font-size: 1.3rem;
				color: #fff47b;
			}
		}
		
		.bjimg {
			width: 100%;
			min-height: 100vh;
			// position: absolute;
			// top: 0;
			// left: 0;
			z-index: 99;
		}

		.activect {
			// margin-top: 13rem;
			// min-height: 80vh;
			// background: url('../assets/hdinfobj.png') no-repeat;
			// background-size: 180% 40rem;
			// background-position: center -15rem;
            // text-align: center;
			font-size: 1.2rem;
			padding: 1.5rem 1rem;
			
			
			
			.textbox {
				color: rgba(@apptext);
				line-height: 1.8;
				margin-top: 1.5rem;
			    text-indent: 2em;
			}
			
			.activectitem {
				height: 12rem;
				// background: url('../assets/itemhd.png') no-repeat;
				// background-size: 100% 100%;
				display: flex;
				justify-content: space-between;
				// align-items: center;
				// padding: 1.5rem 1rem;
				// border: 1px solid rgba(@indextext);
				// background-color: rgba(@indextext);
				border-radius: .5rem;
				margin-bottom: 2rem;
				position: relative;

				img {
					width: 3.5rem;
					margin-bottom: .5rem;
				}

				// div{
				//   // text-align: center;
				//   // color: rgba(@indextext);
				//     color: rgba(@baseColor,1);
				// }
				.title {
					width: 100%;
					height: 3rem;
					line-height: 3rem;
					font-size: 1.2rem;
					padding: 0rem 1rem;
					color: #fff;
					display: flex;
					justify-content: space-between;
					background: rgba(0, 0, 0, .4);
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}

		::v-deep .van-tab__text--ellipsis {
			font-size: 1.4rem;
		}

		.Bitcoin {
			width: 100%;
			height: 5.8rem;
			// background-color: #fff;
			border-radius: 1rem;
			margin-top: 1.5rem;
			// border: 2px solid #f6f6f6;
			background-color: rgba(@baseColor, 1);
			color: rgba(@apptext);
			font-size: 1.2rem;
			font-weight: 700;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0rem 1.5rem;
			margin-top: 2.5rem;

			.Bitcoin-left {
				display: flex;
				align-items: center;

				.Bitcoin-img {
					width: 2.84rem;
					height: 2.84rem;
					margin-right: 1rem;
					// margin-top: 0.5rem;
				}

				.Bitcoin-name {
					width: 24rem;
					margin-top: 0.3rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					-o-text-overflow: ellipsis;
				}
			}
		}
		
		::v-deep .van-action-sheet {
		  background-color: rgba(@baseColor,1);
		  z-index: 9999;
		  // padding: 0rem 1.6rem;
		  height: 66vh;
		  .boxcontent {
		    width: 34.3rem;
		    margin: 0 auto;
			z-index: 9999;
			padding-bottom: 3rem;
		    .title {
		      margin-top: 2rem;
		      color: rgba(@apptext);
		    }
			
			.titleer{
			  color: rgba(@apptext);
			  font-size: 1.6rem;
			  text-align: center;
			  font-weight: 600;
			  margin-bottom: 2.5rem;
			  padding-top: 2.5rem;
			}
			
		    .power {
		      padding: .7rem 0rem;
		      display: flex;
		      justify-content: space-between;
		      color: rgba(@apptext);
		      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		      font-size: 1.2rem;
		      .Price {
		        font-size: 1.4rem;
		        color: rgba(@apptext);
		      }
		    }
		    .agreement {
		      display: flex;
		      color: rgba(@apptext);
		      font-size: 1.4rem;
		      margin-top: 1.9rem;
		      .Agree {
		        margin-left: 1.3rem;
		      }
		    }
		    .boxinfo {
		      width: 90%;
		      color: rgba(@apptext);
		      font-size: 1.4rem;
		      margin-top: 3rem;
		      margin-bottom: 7rem;
		      p {
		        // margin: 1.5rem 0rem;
		        span {
		          background-color: #1f1f1f !important;
		        }
		      }
		    }
		
		    .btn {
		      position: fixed;
		      bottom: 1.5rem;
		      left: 50%;
		      transform: translateX(-50%);
		      width: 34.3rem;
		      height: 4.4rem;
		      background-color: #EFBB0B;
		      margin: 0 auto;
		      font-weight: bold;
		      // margin-top: 5rem;
		      border-radius: 10px;
		      text-align: center;
		      line-height: 4.4rem;
		      color: #333;
		      // margin-bottom: 5rem;
		    }
		  }
		}
		
	}

	.message {
		margin-top: 1rem;
		color: red;
		font-weight: 700;
	}
	.tishi{
	  font-size: 1rem;
	  font-weight: 700;
	  color: rgba(@apptext);
	}
</style>