<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { setTheme } from "./style/theme/theme" // 引入切换主题方法
export default {
  mounted () {
    setTheme(localStorage.getItem('theme') || 'dark')
  }
}
</script>
<style>
@import "./fontstyle/font.css";
@import './style/theme/style.less';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* body {
  background-color: #060606;
} */
img {
  width: 100%;
  vertical-align: middle;
}
.noticeWidth {
  background-color: #ccc !important;
  color: #000 !important;
  font-size: 1.3rem !important;
}
body {
  /* background-color: #fff; */
  font-family:  Roboto, Arial, sans-serif !important;
  /* background-color: rgba(@baseColor,1); */
}
</style>
