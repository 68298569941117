<template>
  <!-- 公告 -->
  <div class="Announcement">
    <div class="Announcement-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        @click="$router.back()"
      />
      <div class="title">{{$t('gonggao.title')}}</div>
      <div></div>
    </div>
    <div class="info" v-for="(i, v) in gonggaolist" :key="v" @click="todetails(i.id)">
      <div class="left">
        <div class="left-title">{{i.title}}</div>
        <p class="time">{{ i.createtime }}</p>
      </div>
      <div class="right">
        <img src="../assets/RightArrow.png" alt="" />
      </div>
    </div>
    <!-- <div class="suoyou">
      <img src="../assets/suoyou.png" alt="" />
    </div> -->
  </div>
</template>

<script>
import { Noticelistapi } from '@/api/index'
export default {
  name: 'AnnounCement',
  data () {
    return {
      gonggaolist: []
    }
  },
  created () {
    this.Noticelist()
  },
  methods: {
    async Noticelist () {
      const res = await Noticelistapi()
      // console.log(res)
      this.gonggaolist = res.data
    },
    // 去公告详情
    todetails (id) {
      // const data = {
      //   id: id
      // }
      this.$router.push({
        path: '/gonggaodetails',
        query: {
          id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../style/theme/style.less'; // 引入主题样式文件
.Announcement {
  // background: url('../assets/ampbk.png') no-repeat;
  background-color: rgba(@baseColor,1);
  // background-size: 100% 25rem;
  background-size: cover;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  ::v-deep .van-icon{
    color: rgba(@apptext);
  }
  .Announcement-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
     z-index: 9 !important;
    .title {
      color: rgba(@apptext);
      font-size: 1.8rem;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;

    }
  }
  .info {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
    .left {
      color: rgba(@apptext);
      .left-title {
        width: 28.5rem;
      }
      .time {
        margin-top: 1.5rem;
        color: #858585;
      }
    }
    .right {
      width: 1rem;
      height: 1.1rem;
      line-height: 7rem;
      margin-top: -1.3rem;
    }
  }
  // .suoyou {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   z-index: 1;
  // }
}
</style>
