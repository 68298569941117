<template>
  <!-- 活动页面 -->
    <!-- k线图-->
  <div class="kx">
    <!-- nav-->
    <div class="nav">
      <div class="navtitle">{{$t('addgift.nav')}}</div>
      <div class="vipdiv">
        <img src="./pic/vippic.png" class="vip" />
        <span class="spanvip">V{{proplist.user_game_level}}</span>
      </div>
      <!-- 气泡弹出框-->
      <!-- <van-popover v-model="showPopover" trigger="click" :actions="actions" @select="onSelect">
        <template #reference>

        </template>
      </van-popover> -->
      <div class="normal" @click="$router.push('/TrenDanalysis')">{{$t('addgift.normal')}}</div>
      <div class="rightimg" @click="$router.push('/HistoricalLottery')">
        <img src="./pic/xinjilu.png" v-if="gifturl == 0"/>
        <img src="../assets/xinjilu2.png" v-if="gifturl == 1"/>
        <div>{{$t('addgift.navrightyi')}}</div>
      </div>
      <div class="rightimg" @click="$router.push('/Rules')">
        <img src="./pic/guize.png" v-if="gifturl == 0"/>
        <img src="../assets/guize2.png" v-if="gifturl == 1"/>
        <div>{{$t('addgift.navrighter')}}</div>
      </div>
    </div>
    <!-- 切换模式-->
    <div class="qh">
      <div :class="{'qhactive' : qhindex == 0}" @click="SwitchMode(0)">{{$t('addgift.qhyi')}}</div>
      <div :class="{'qhactive' : qhindex == 1}" @click="SwitchMode(1)">{{$t('addgift.qher')}}</div>
    </div>
    <!-- 信息-->
    <div class="mesg">
      <div class="mesgone">
        <div>{{kxtitle.close}}</div>
        <div>1.96%</div>
      </div>
      <div class="mesgtwo">
        <div class="marbottom">{{$t('addgift.msgyi')}}</div>
        <div class="marbottom">{{$t('addgift.msger')}}</div>
        <div>{{$t('addgift.msgsan')}}</div>
      </div>
      <div class="mesgthree">
        <div class="marbottom">{{kxtitle.high}}</div>
        <div class="marbottom">{{kxtitle.low}}</div>
        <div>{{kxtitle.vol}}</div>
      </div>
    </div>
    <!-- k线图选项-->
    <div class="kxbody">
      <div class="kxtabar">
        <div :class="{'kxictive':kxindex == 0}" @click="kxtabar(0)">1min k</div>
        <div :class="{'kxictive':kxindex == 1}" @click="kxtabar(1)">5min k</div>
        <div :class="{'kxictive':kxindex == 2}" @click="kxtabar(2)">30min k</div>
        <div :class="{'kxictive':kxindex == 3}" @click="kxtabar(3)">60min k</div>
      </div>
      <!-- k线图-->
      <div class="kbox">
        <!-- <div id="mychart" class="fenmain" style="width: 100%;height: 300px;"></div> -->
        <div v-show="kxindex == 0" style="width: 100%;height: 300px;" class="fenmain"></div>
        <div v-show="kxindex == 1" style="width: 100%;height: 300px;" class="rimain"></div>
        <div v-show="kxindex == 2" style="width: 100%;height: 300px;" class="zhoumain"></div>
        <div v-show="kxindex == 3" style="width: 100%;height: 300px;" class="yuemain"></div>
      </div>
    </div>
    <!-- 复选框-->
    <!-- <div class="fxk">
      <van-checkbox v-model="checked" checked-color="rgb(255, 67, 0)" icon-size="15px">
        <span style="color: rgb(255, 67, 0);font-weight: 700;">{{$t('addgift.fxk')}}</span>
      </van-checkbox>
    </div> -->
    <!-- 内容one-->
    <div class="bone">
      <van-tabs v-model="active" :border="false"  title-inactive-color="#858585"
						title-active-color="#EFBB0B" color="#6076B2" @change="onperiod()">
        <van-tab :title="$t('addgift.tarbaryi')">
          <div class="bonetop">
            <div>{{$t('addgift.bonex')}}-{{periodarr.old_period}}</div>
            <div style="font-weight: 700;">{{periodarr.lottery}}</div>
            <div style="font-weight: 700;">{{NumArr[0]}}+{{NumArr[1]}}+{{NumArr[2]}}={{periodarr.sum_lottery}}</div>
            <div style="font-weight: 700;" v-if="result.length > 0">
              {{result[0]}},{{result[1]}},{{result[2]}},
            </div>
          </div>
          <div class="bonex"></div>
          <div class="bonebottom">
            <div style="font-weight: 700;">{{$t('addgift.bonex')}}-{{periodarr.period}}</div>
            <div>
              <van-count-down :time="time"  millisecond format="mm:ss" @finish="mintueone">
                <template #default="timeData">
                  <!-- <span class="block">{{ timeData.hours }}</span>
                  <span class="colon">:</span> -->
                  <span
											class="block">{{ timeData.minutes <10?'0'+timeData.minutes:timeData.minutes}}</span>
										<span class="colon">:</span>
										<span
											class="block">{{ timeData.seconds <10?'0'+timeData.seconds:timeData.seconds}}</span>
                </template>
              </van-count-down>
            </div>
          </div>
        </van-tab>
        <van-tab :title="$t('addgift.tarbarer')">
          <div class="bonetop">
            <div>{{$t('addgift.bonex')}}-{{periodarr.old_period}}</div>
            <div style="font-weight: 700;">{{periodarr.lottery}}</div>
            <div style="font-weight: 700;">{{NumArr[0]}}+{{NumArr[1]}}+{{NumArr[2]}}={{periodarr.sum_lottery}}</div>
            <div style="font-weight: 700;" v-if="result.length > 0">
              {{result[0]}},{{result[1]}},{{result[2]}},
            </div>
          </div>
          <div class="bonex"></div>
          <div class="bonebottom">
            <div style="font-weight: 700;">{{$t('addgift.bonex')}}-{{periodarr.period}}</div>
            <div>
              <van-count-down :time="timetwo" millisecond format="mm:ss" @finish="mintuetwo">
                <template #default="timeData">
                  <!-- <span class="block">{{ timeData.hours }}</span>
                  <span class="colon">:</span> -->
                  <span
                    class="block">{{ timeData.minutes <10?'0'+timeData.minutes:timeData.minutes}}</span>
                  <span class="colon">:</span>
                  <span
                    class="block">{{ timeData.seconds <10?'0'+timeData.seconds:timeData.seconds}}</span>
                </template>
              </van-count-down>
            </div>
          </div>
        </van-tab>
        <!-- <van-tab title="标签 3">内容 3</van-tab>
        <van-tab title="标签 4">内容 4</van-tab> -->
      </van-tabs>
    </div>
    <!-- 内容选择-->
    <div class="bxz">
      <div class="bxztop" v-for="(item, index) in proplist.prop" :key="index">
        <div class="bxztopdiv" :class="{'Big': item.type == 1,'Small': item.type == 2,'Odd': item.type == 3,'Even': item.type == 4,'Big-Odd': item.type == 5,
      'Small-Odd': item.type == 6,'Big-Even': item.type == 7,'Small-Even': item.type == 8}" @click="tobet(item.type, item.value)">
          <div :class="{'Big-Evenfff': item.type == 7}">{{item.name}}</div>
          <div :class="{'Big-Evenfff': item.type == 7}">odds {{item.value}}</div>
        </div>
      </div>
    </div>
    <!-- 内容two-->
    <div class="btwo">
      <van-tabs v-model="activetwo" :border="false"  title-inactive-color="#858585"
						title-active-color="#EFBB0B" color="#6076B2"  @change="btwochange">
            <van-tab :title="$t('addgift.tarbarsan')"><!-- Trade-->
              <van-tabs v-model="activethree" :border="false"  title-inactive-color="#858585"
						      title-active-color="#EFBB0B" color="#6076B2" class="tabarmagin" @change="chantrade" >
                  <van-tab :title="$t('addgift.tabarwu')"><!-- Trade-Waiting-->
                    <div v-if="recordslist != ''">
                      <div class="trade" v-for="(item,index) in recordslist" :key="index" > <!-- Trade-Transaction-->
                        <div class="waitin">
                          <div class="waitinleft">
                            <img src="./pic/home6.png" />
                            <div class="waitinleftzi">
                              <div>{{item.period}}</div>
                              <div style="font-size: 1rem;">{{$t('addgift.btwoyi')}} {{item.before_price}}</div>
                            </div>
                            <div style="color: red; margin-left: 1rem;">{{item.type}}</div>
                          </div>
                          <div class="waitinright">
                            <div class="waitinrightzi">
                              <div style="color: #db0003;">{{item.note}}</div>
                              <div style="color: #48DBA6;">{{item.profit}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="datanull">
                      <img src="../assets/datanull.png" />
                      <div>{{$t('addgift.datanull')}}</div>
                    </div>
                  </van-tab>
                  <van-tab :title="$t('addgift.tabarliu')">
                    <div v-if="recordslist != ''">
                      <div class="trade" v-for="(item,index) in recordslist" :key="index" > <!-- Trade-Transaction-->
                        <div class="waitin">
                          <div class="waitinleft">
                            <img src="./pic/home6.png" />
                            <div class="waitinleftzi">
                              <div>{{item.period}}</div>
                              <div style="font-size: 1rem;">{{$t('addgift.btwoyi')}} {{item.before_price}}</div>
                            </div>
                            <div style="color: red; margin-left: 1rem;">{{item.type}}</div>
                          </div>
                          <div class="waitinright">
                            <div class="waitinrightzi">
                              <div style="color: #db0003;">{{item.note}}</div>
                              <div style="color: #48DBA6;">{{item.profit}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="datanull">
                      <img src="../assets/datanull.png" />
                      <div>{{$t('addgift.datanull')}}</div>
                    </div>
                  </van-tab>
              </van-tabs>
            </van-tab>
            <van-tab :title="$t('addgift.tabarsi')">
              <van-tabs v-model="activefour" :border="false"  title-inactive-color="#858585"
						        title-active-color="#EFBB0B" color="#6076B2" class="tabarmagin" @change="chanminute" >
                  <van-tab :title="$t('addgift.tarbaryi')"><!-- Historical-1minute-->
                    <div v-if="periodlist">
                      <div class="historical" v-for="(item,index) in periodlist" :key="index">
                        <div class="minute">
                          <div class="minone">
                            <div style="color: rgb(255, 95, 86);font-size: 16px;">{{item.period}}</div>
                            <div style="opacity: 0.5;">{{item.stime}}</div>
                          </div>
                          <div class="mintwo">
                            <div>{{item.lottery}}</div>
                            <div>{{item.NumArr[0]}}+{{item.NumArr[1]}}+{{item.NumArr[2]}}={{Number(item.sum_lottery)}}</div>
                          </div>
                          <div class="minthree">
                            <div v-for="(value,i) in item.result" :key="i">
                              <div :class="{'Big': value.type == 1,'Small': value.type == 2,'Odd': value.type == 3,'Even': value.type == 4,'Big-Odd': value.type == 5,
      'Small-Odd': value.type == 6,'Big-Even': value.type == 7,'Small-Even': value.type == 8}">
                                <p :class="{'Big-Evenfff': value.type == 7}">{{value.name}}</p>
                              </div>
                            </div>
                            <!-- <div style="background-color: #03C0FF;">Even</div>
                            <div style="background-color: #000000;color: #fff;">Big-Even</div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="datanull">
                      <img src="../assets/datanull.png" />
                      <div>{{$t('addgift.datanull')}}</div>
                    </div>
                  </van-tab>
                  <van-tab :title="$t('addgift.tarbarer')">
                    <div v-if="periodlist">
                      <div class="historical" v-for="(item,index) in periodlist" :key="index">
                        <div class="minute">
                          <div class="minone">
                            <div style="color: rgb(255, 95, 86);font-size: 16px;">{{item.period}}</div>
                            <div style="opacity: 0.5;">{{item.stime}}</div>
                          </div>
                          <div class="mintwo">
                            <div>{{item.lottery}}</div>
                            <div>{{item.NumArr[0]}}+{{item.NumArr[1]}}+{{item.NumArr[2]}}={{Number(item.sum_lottery)}}</div>
                          </div>
                          <div class="minthree">
                            <div v-for="(value,i) in item.result" :key="i">
                              <div :class="{'Big': value.type == 1,'Small': value.type == 2,'Odd': value.type == 3,'Even': value.type == 4,'Big-Odd': value.type == 5,
      'Small-Odd': value.type == 6,'Big-Even': value.type == 7,'Small-Even': value.type == 8}">
                                <p :class="{'Big-Evenfff': value.type == 7}">{{value.name}}</p>
                              </div>
                            </div>
                            <!-- <div style="background-color: #03C0FF;">Even</div>
                            <div style="background-color: #000000;color: #fff;">Big-Even</div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="datanull">
                      <img src="../assets/datanull.png" />
                      <div>{{$t('addgift.datanull')}}</div>
                    </div>
                  </van-tab><!-- Historical-1minute-->
              </van-tabs>
            </van-tab>
      </van-tabs>
    </div>
    <!-- 弹出层-->
    <van-popup v-model="prposhow" :style="{ padding: '10px' }" position="bottom">
      <div class="prpott">
        <div class="ttone">{{$t('addgift.tcyi')}}</div>
        <input v-model="prpodata.amount" @input="prpo_money(prpodata.amount)" type="number" />
        <div class="tter">{{$t('addgift.tcer')}} {{prpomoney}} USDT</div>
        <div class="ttsan">{{$t('addgift.tcsan')}}: {{proplist.bet_fee}}%</div>
        <div class="ttsan">{{$t('addgift.tcsi')}} ({{$t('addgift.tcsitwo')}}：{{proplist.money}})</div>
        <div class="ttbutton" @click="buttonbet">
          <van-button type="primary" block color="#5078d7">{{$t('addgift.tcwu')}}</van-button>
        </div>
      </div>
    </van-popup>
    <!-- /弹出层-->
    <TabBar></TabBar>
  </div>
</template>

<script>
// import { activitylistapi } from '@/api/index'
// import { Toast } from 'vant'
// export default {
//   name: 'AllreCords',
//   data () {
//     return {
//       activity: [],
//       code: 0
//     }
//   },
//   created () {
//     this.activitylist()
//   },
//   methods: {
//     async activitylist () {
//       const res = await activitylistapi()
//       console.log('gift', res)
//       this.activity = res.data
//       this.code = res.code
//     },
//     toeventdetails (type, status) {
//       if (type === 1 || type === 2) {
//         if (parseFloat(status) === 1) {
//           this.$router.push({
//             path: '/eventdetails',
//             query: {
//               type
//             }
//           })
//         } else {
//           Toast({
//             message: 'The activity has not started yet, please continue to follow',
//             duration: 1200,
//             className: 'noticeWidth' // 所加样式名
//           })
//         }
//       } else if (type === 3) {
//         if (parseFloat(status) === 1) {
//           this.$router.push('/turntable')
//         } else {
//           Toast({
//             message: 'The activity has not started yet, please continue to follow',
//             duration: 1200,
//             className: 'noticeWidth' // 所加样式名
//           })
//         }
//       } else if (type === 4) {
//         if (parseFloat(status) === 1) {
//           this.$router.push('/yuebao')
//         } else {
//           Toast({
//             message: 'The activity has not started yet, please continue to follow',
//             duration: 1200,
//             className: 'noticeWidth' // 所加样式名
//           })
//         }
//       }
//     }
//   }
// }
import * as echarts from 'echarts'
import { Toast } from 'vant'
import { get_period, prop, bet, bet_records, period_histroy, get_huobi, get_game_trade } from '@/api/index'
export default { // k线图
  name: 'AllreCords',
  data() {
    return {
      kxindex: 0,
      qhindex: 0,
      kxtitle: {}, // k线顶部
      tradedata: { // k线图请求体
        symbol: 'btcusdt',
        period: '1M',
        limit: 15
      },
      klist: [], // k线图内容
      // showPopover: flase,
      actions: [
        { text: '选项一' },
        { text: '选项二' },
        { text: '选项三' }
      ],
      showPopover: false,
      checked: true, // 复选框
      active: 0, // 选项卡1
      qqperiod: { // 获取当前期数
        category : 1
      },
      NumArr: [],
      result: [],
      proplist: [], // 下注分类
      prop_data: {
        type: 1
      },
      periodarr: [], // 当前期数
      prposhow: false, // 下注弹出层
      prpodata: {
        type: '',
        amount: '',
        category: '1'
      },
      prpovalue: 0,
      prpomoney: 0, // 下注金额换算usdt
      timeData: [],
      time: 0,
      timetwo: 0,
      activetwo: 0, // 选项卡2
      activethree: 0, // 选项卡3
      activefour:0, // 选项卡4
      recordsdata:{ // 下注记录-- 待开奖已开奖
        status: 1,
        limit: 5,
        page: 1
      },
      recordslist: [],
      recordstotal: '',
      perioddata: { // 历史记录
        category: 1,
        limit: 5,
        page: 1
      },
      periodtotal: '',
      periodlist: [],
      periodNumber: [],
      timenull: null, // 每隔三秒的请求
      timenuller: null,
      timenullsan: null,
      timenullsi: null,
      periodurl: '/api/Btc/get_period', // 获取当前期数
      prpourl: '/api/base/prop', // 下注列表
      beturl: '/api/Btc/bet', // 下注
      betrecordsurl: '/api/Btc/bet_records', // 下注记录
      periodhistroyurl: '/api/Btc/period_histroy', // 获取历史记录
      gethuobiurl: '/api/huobi/get_huobi_symbol?symbol=btcusdt', // k线图数据title
      getgametradeurl: '/api/huobi/get_game_trade', // k线图数据
      gifturl: 0
    }
  },
  created() {
    if (localStorage.getItem('theme') == 'dark') {
      this.gifturl = 1
      // console.log(this.kfurlqh)
    } else {
      this.gifturl = 0
    }
    this.gethuobi() // k线图-title
    this.gettarde() // k线图
    this.getperiod()
    this.getprop()
    this.getrecords() // 下注记录
  },
  mounted() {
    // this.initEcharts()
    // 每隔三秒发送一次请求
    this.timenull = setInterval(() => {
      this.gethuobi()
    }, 3000);
    this.timenuller = setInterval(() => {
      this.gettarde()
    }, 3000);
    this.timenullsan = setInterval(() => {
      this.getrecords()
    }, 3000);
    this.timenullsi = setInterval(() => {
      this.getperiod_histroy()
    }, 3000);
    // window.addEventListener("scroll", this.giftdrown) // 触底刷新
  },
  methods: {
    onSelect(action){},
    SwitchMode(e) { // 切换模式
      const that = this
      if(e==0) {
        // that.periodurl = '/api/Btc/get_period' // 获取当前期数
        // that.prpourl = '/api/base/prop' // 下注列表
        that.$router.push('/gift')
        that.beturl = '/api/Btc/bet' // 下注
        // that.betrecordsurl = '/api/Btc/bet_records' // 下注记录
        // that.periodhistroyurl = '/api/Btc/period_histroy' // 获取历史记录
        // that.gethuobiurl = '/api/huobi/get_huobi_symbol?symbol=btcusdt' // k线图数据title
        // that.getgametradeurl = '/api/huobi/get_game_trade' // k线图数据
      }else{
        that.$router.push('/gifter')
        that.beturl = '/api/Btc/bet_active' // 包赔下注
      }
      that.qhindex = e
    },
    kxtabar (index) {
      // console.log(index)
      if(index == 0){
        this.tradedata.period = '1M'
        this.gettarde()
      }else if( index == 1){
        this.tradedata.period = '5M'
        this.gettarde()
      }else if( index == 2){
        this.tradedata.period = '30M'
        this.gettarde()
      }else if( index == 3){
        this.tradedata.period = '60M'
        this.gettarde()
      }
      this.kxindex = index
    },
    // k线图数据title
    gethuobi() {
      const url = this.gethuobiurl
      get_huobi(url).then(res=>{
        // console.log(res, 'k线title')
        this.kxtitle = res.data.tick
      })
    },
    // k线图数据
    gettarde() {
      const that = this
      get_game_trade(that.getgametradeurl,that.tradedata).then(res=>{
        // console.log(res, 'k线')
        that.klist = res.data.data
        if(that.kxindex == 0){
          that.initEcharts()
        }else if(that.kxindex == 1){
          that.gettwok()
        }else if(that.kxindex == 2){
          that.getseck()
        }else{
          that.getfourk()
        }
      })
    },
    // k线图--上证指数
    initEcharts() {
      let this_ = this
				let kbox = document.querySelector('.kbox')
				var chartDom = document.querySelector('.fenmain')
				var myChart = echarts.init(chartDom);
				var option;

				// prettier-ignore

				function calculateMA(dayCount, data) {
					var result = [];
					for (var i = 0, len = data.length; i < len; i++) {
						// alert(111)
						if (i < dayCount) {
							result.push('-');
							continue;
						}
						var sum = 0;
						for (var j = 0; j < dayCount; j++) {
							sum += +data[i - j][1];
						}
						result.push(sum / dayCount);
					}
					return result;
				}
				// console.log(this_.klist, 111);
				const dates = this_.klist.map(function(item) {
					var date = new Date(item.id * 1000);
					var Y = date.getFullYear() + '-';
					var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
					var D = date.getDate() + ' ';
					var h = date.getHours() + ':';
					var m = date.getMinutes() + ':';
					var s = date.getSeconds();
					// var Y = date.getFullYear() + '/';
					// var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
					// var D = date.getDate();
					item.id = Y + M + D + h + m + s
          // console.log(item.id)
					return item.id;
				});
				const data = this_.klist.map(function(item) {
					return [+item.open, +item.close, +item.low, +item.high];
				});

				option = {
					legend: {
						data: ['日K', 'MA5', 'MA10', 'MA20', 'MA30'],
						inactiveColor: '#777'
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							animation: false,
							type: 'cross',
							lineStyle: {
								color: '#376df4',
								width: 2,
								opacity: 1
							}
						}
					},
					xAxis: {
						type: 'category',
						data: dates,
						axisLine: {
							lineStyle: {
								color: '#8392A5'
							}
						}
					},
					yAxis: {
						scale: true,
						axisLine: {
							lineStyle: {
								color: '#8392A5'
							}
						},
						splitLine: {
							show: false
						}
					},
					grid: {
						bottom: 80,
						left: 3,
						containLabel: true
					},
					dataZoom: [],
					series: [{
							type: 'candlestick',
							name: 'Day',
							data: data,
							itemStyle: {
								color: '#FD1050',
								color0: '#0CF49B',
								borderColor: '#FD1050',
								borderColor0: '#0CF49B'
							}
						},
						{
							name: '',
							type: 'line',
							data: calculateMA(1, data),
							smooth: true,
							showSymbol: false,
							lineStyle: {
								width: 1
							}
						},
					]
				};
				option && myChart.setOption(option);
    },
    gettwok() {
      let this_ = this
      let kbox = document.querySelector('.rimain')
      var chartDom = kbox
      var myChart = echarts.init(chartDom);
      var option;

      // prettier-ignore

      function calculateMA(dayCount, data) {
        var result = [];
        for (var i = 0, len = data.length; i < len; i++) {
          // alert(111)
          if (i < dayCount) {
            result.push('-');
            continue;
          }
          var sum = 0;
          for (var j = 0; j < dayCount; j++) {
            sum += +data[i - j][1];
          }
          result.push(sum / dayCount);
        }
        return result;
      }
      // console.log(this_.klist);
      const dates = this_.klist.map(function(item) {
        var date = new Date(item.id * 1000);
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = date.getDate() + ' ';
        var h = date.getHours() + ':';
        var m = date.getMinutes() + ':';
        var s = date.getSeconds();
        // var Y = date.getFullYear() + '/';
        // var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
        // var D = date.getDate();
        item.id = Y + M + D + h + m + s
        return item.id;
      });
      const data = this_.klist.map(function(item) {
        return [+item.high, +item.close, +item.low, +item.high];
      });

      option = {
        legend: {
          data: ['日K', 'MA5', 'MA10', 'MA20', 'MA30'],
          inactiveColor: '#777'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            animation: false,
            type: 'cross',
            lineStyle: {
              color: '#376df4',
              width: 2,
              opacity: 1
            }
          }
        },
        xAxis: {
          type: 'category',
          data: dates,
          axisLine: {
            lineStyle: {
              color: '#8392A5'
            }
          }
        },
        yAxis: {
          scale: true,
          axisLine: {
            lineStyle: {
              color: '#8392A5'
            }
          },
          splitLine: {
            show: false
          }
        },
        grid: {
          bottom: 80,
          left: 3,
          containLabel: true
        },
        dataZoom: [],
        series: [{
            type: 'candlestick',
            name: 'Day',
            data: data,
            itemStyle: {
              color: '#FD1050',
              color0: '#0CF49B',
              borderColor: '#FD1050',
              borderColor0: '#0CF49B'
            }
          },
          {
            name: '',
            type: 'line',
            data: calculateMA(1, data),
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 1
            }
          },
        ]
      };
      option && myChart.setOption(option);
    },
    getseck() {
				let this_ = this
				let kbox = document.querySelector('.zhoumain')
				var chartDom = kbox
				var myChart = echarts.init(chartDom);
				var option;
				// prettier-ignore
				function calculateMA(dayCount, data) {
					var result = [];
					for (var i = 0, len = data.length; i < len; i++) {

						if (i < dayCount) {
							result.push('-');
							continue;
						}
						var sum = 0;
						for (var j = 0; j < dayCount; j++) {
							sum += +data[i - j][1];
						}
						result.push(sum / dayCount);
					}
					return result;
				}
				const dates = this_.klist.map(function(item) {
					var date = new Date(item.id * 1000);
					var Y = date.getFullYear() + '-';
					var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
					var D = date.getDate() + ' ';
					var h = date.getHours() + ':';
					var m = date.getMinutes() + ':';
					var s = date.getSeconds();
					// var Y = date.getFullYear() + '/';
					// var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
					// var D = date.getDate();
					item.id = Y + M + D + h + m + s
					return item.id;
				});
				const data = this_.klist.map(function(item) {
					return [+item.high, +item.close, +item.low, +item.high];
				});
				option = {
					legend: {
						data: ['日K', 'MA5', 'MA10', 'MA20', 'MA30'],
						inactiveColor: '#777'
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							animation: false,
							type: 'cross',
							lineStyle: {
								color: '#376df4',
								width: 2,
								opacity: 1
							}
						}
					},
					xAxis: {
						type: 'category',
						data: dates,
						axisLine: {
							lineStyle: {
								color: '#8392A5'
							}
						}
					},
					yAxis: {
						scale: true,
						axisLine: {
							lineStyle: {
								color: '#8392A5'
							}
						},
						splitLine: {
							show: false
						}
					},
					grid: {
						bottom: 80,
						left: 3,
						containLabel: true
					},
					dataZoom: [],
					series: [{
							type: 'candlestick',
							name: 'Day',
							data: data,
							itemStyle: {
								color: '#FD1050',
								color0: '#0CF49B',
								borderColor: '#FD1050',
								borderColor0: '#0CF49B'
							}
						},
						{
							name: '',
							type: 'line',
							data: calculateMA(1, data),
							smooth: true,
							showSymbol: false,
							lineStyle: {
								width: 1
							}
						},
					]
				};
				option && myChart.setOption(option);
		},
    getfourk() {
      let this_ = this
      let kbox = document.querySelector('.yuemain')
      var chartDom = kbox
      var myChart = echarts.init(chartDom);
      var option;

      // prettier-ignore

      function calculateMA(dayCount, data) {
        var result = [];
        for (var i = 0, len = data.length; i < len; i++) {
          // alert(111)
          if (i < dayCount) {
            result.push('-');
            continue;
          }
          var sum = 0;
          for (var j = 0; j < dayCount; j++) {
            sum += +data[i - j][1];
          }
          result.push(sum / dayCount);
        }
        return result;
      }
      // console.log(this_.klist);
      const dates = this_.klist.map(function(item) {
        var date = new Date(item.id * 1000);
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = date.getDate() + ' ';
        var h = date.getHours() + ':';
        var m = date.getMinutes() + ':';
        var s = date.getSeconds();
        // var Y = date.getFullYear() + '/';
        // var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
        // var D = date.getDate();
        item.id = Y + M + D + h + m + s
        return item.id;
      });
      const data = this_.klist.map(function(item) {
        return [+item.high, +item.close, +item.low, +item.high];
      });

      option = {
        legend: {
          data: ['日K', 'MA5', 'MA10', 'MA20', 'MA30'],
          inactiveColor: '#777'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            animation: false,
            type: 'cross',
            lineStyle: {
              color: '#376df4',
              width: 2,
              opacity: 1
            }
          }
        },
        xAxis: {
          type: 'category',
          data: dates,
          axisLine: {
            lineStyle: {
              color: '#8392A5'
            }
          }
        },
        yAxis: {
          scale: true,
          axisLine: {
            lineStyle: {
              color: '#8392A5'
            }
          },
          splitLine: {
            show: false
          }
        },
        grid: {
          bottom: 80,
          left: 3,
          containLabel: true
        },
        dataZoom: [],
        series: [{
            type: 'candlestick',
            name: 'Day',
            data: data,
            itemStyle: {
              color: '#FD1050',
              color0: '#0CF49B',
              borderColor: '#FD1050',
              borderColor0: '#0CF49B'
            }
          },
          {
            name: '',
            type: 'line',
            data: calculateMA(1, data),
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 1
            }
          },
        ]
      };
      option && myChart.setOption(option);
    },
    onperiod() { // 选择1和5
      console.log(this.active)
      if(this.active == 0){
        this.qqperiod.category = 1
        this.prpodata.category = 1
        this.getperiod()
      }else{
        this.qqperiod.category = 2
        this.prpodata.category = 2
        this.getperiod()
      }
    },
    mintueone() { // 1
      this.qqperiod.category = 1
      this.time = 0
      setTimeout(() => {
        this.getperiod()
      }, 1000)
    },
    mintuetwo() { // 5
      this.qqperiod.category = 2
      this.timetwo = 0
      setTimeout(() => {
        this.getperiod()
      }, 1000)
      // this.getperiod()
    },
    // 获取当前期数
    getperiod() {
      const that = this
      get_period(that.periodurl,that.qqperiod).then(res=>{
        // console.log(res)
        if(res.code == 1){
          that.periodarr = res.data
          if( that.active == 0 ){
            that.time = res.data.seconds
          }else if( that.active == 1 ){
            that.timetwo = res.data.seconds
          }
          var sum = res.data.lottery.replace(".", "")
					var lastThree = sum.substring(sum.length - 3)
					that.NumArr = lastThree.split("")

          that.result = res.data.result
        }else{
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        }
      })
    },
    // 下注列表
    getprop() {
      prop(this.prpourl,this.prop_data).then(res=>{
        console.log(res, '下注')
        if(res.code == 1){
          this.proplist = res.data
        }else{
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        }
      })
    },
    tobet(type,value) { // 打开弹窗
      if( this.checked == false ){
        Toast({
          message: this.$t('addgift.tsyi'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      this.prpodata.type = type
      this.prpovalue = value
      this.prposhow = true
    },
    prpo_money(amount) { // 换算usdt
      // console.log(parseFloat(this.proplist.money))
      if( amount > parseFloat(this.proplist.money)){
        this.prpodata.amount = parseFloat(this.proplist.money)
      }
      // return flase
      const value = parseFloat(this.prpodata.amount) - ( parseFloat(this.proplist.bet_fee) / 100 * parseFloat(this.prpodata.amount) )
      this.prpomoney = parseFloat( value * this.prpovalue ).toFixed(2)
    },
    buttonbet() { // 下注
      const that = this
      if( that.prpodata.amount == '' || that.prpodata.amount == 0 ){
        Toast({
          message: this.$t('addgift.tser'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      if( parseFloat(that.prpodata.amount) < parseFloat(that.proplist.bet_min) ){
        Toast({
          message: this.$t('addgift.tssan') + that.proplist.bet_min,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      // console.log(that.prpodata)
      bet(that.beturl,that.prpodata).then(res=>{
        if( res.code == 1 ){
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
          that.prposhow = false
          // that.$router.go(0)
        }else{
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        }
      })
    },
    // 下注记录
    getrecords() {
      bet_records(this.betrecordsurl,this.recordsdata).then(res=>{
        // console.log(res, '下注记录')
        this.recordstotal = res.data.total
        this.recordslist = res.data.data
        // console.log(this.recordslist)
      })
    },
    chantrade(e) { // 切换下注记录
      console.log(e)
      if( e == 0 ){
        this.recordslist = []
        this.recordsdata.page = 1
        this.recordsdata.status = 1
      }else{
        this.recordslist = []
        this.recordsdata.page = 1
        this.recordsdata.status = 2
      }
      this.getrecords()
    },
    btwochange(e) { // Trade/Historical
      if( e == 0 ){
        this.getrecords()
      }else{
        this.getperiod_histroy()
      }
    },
    chanminute(e) {
      if( e == 0 ){
        this.perioddata.category = 1
        this.periodlist = []
        this.perioddata.page = 1
        this.getperiod_histroy()
      }else{
        this.perioddata.category = 2
        this.periodlist = []
        this.perioddata.page = 1
        this.getperiod_histroy()
      }
    },
    getperiod_histroy() { // 获取历史记录
      period_histroy(this.periodhistroyurl,this.perioddata).then(res=>{
        // console.log(res);
        if( res.code == 1 ){
          this.periodtotal = res.data.total
          this.periodlist = res.data.data
          for( var i=0;i<this.periodlist.length;i++ ){
            var sum = this.periodlist[i].lottery.replace(".", "");
            var lastThree = sum.substring(sum.length - 3);
            this.periodlist[i].NumArr = lastThree.split("");
          }
          // console.log(this.periodlist)
        }else{
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        }
      })
    },
    giftdrown() { // 触底刷新
      // const a = document.documentElement.clientHeight
      // const b = document.documentElement.scrollTop
      // const c = document.documentElement.scrollHeight
      // const that = this
      // console.log(a,b,c)
      // if( a+b >= c ){
      //   console.log('触底了')
      //   if( that.activetwo == 0 ){ // Trade
      //     if( that.activethree == 0 ){ // 下注记录
      //       if( parseInt(that.recordstotal) / parseInt(that.recordsdata.page) < parseInt(that.recordsdata.page) ){
      //         Toast({
      //           message: 'What the hell is going on',
      //           duration: 1200,
      //           className: 'noticeWidth' // 所加样式名
      //         })
      //         return false
      //       }
      //       that.recordsdata.page++
      //       that.getrecords()
      //     }else{
      //       if( parseInt(that.recordstotal) / parseInt(that.recordsdata.page) < parseInt(that.recordsdata.page) ){
      //         Toast({
      //           message: 'What the hell is going on',
      //           duration: 1200,
      //           className: 'noticeWidth' // 所加样式名
      //         })
      //         return false
      //       }
      //       that.recordsdata.page++
      //       that.getrecords()
      //     }
      //   }else{ // Historical
      //     if(that.activefour == 0){
      //       if( parseInt(that.periodtotal) / parseInt(that.perioddata.page) < parseInt(that.perioddata.page)){
      //         Toast({
      //           message: 'What the hell is going on',
      //           duration: 1200,
      //           className: 'noticeWidth' // 所加样式名
      //         })
      //         return false
      //       }
      //       that.perioddata.page++
      //       that.getperiod_histroy()
      //     }else{
      //       if( parseInt(that.periodtotal) / parseInt(that.perioddata.page) < parseInt(that.perioddata.page)){
      //         Toast({
      //           message: 'What the hell is going on',
      //           duration: 1200,
      //           className: 'noticeWidth' // 所加样式名
      //         })
      //         return false
      //       }
      //       that.perioddata.page++
      //       that.getperiod_histroy()
      //     }
      //   }
      // }
    }
  },
  destroyed() {
    // 离开页面关掉轮询
    window.clearInterval(this.timenull)
    window.clearInterval(this.timenuller)
    window.clearInterval(this.timenullsan)
    window.clearInterval(this.timenullsi)
    // 离开页面取消监听
    // window.removeEventListener("scroll", this.giftdrown, false)
  },
}
</script>

<style lang="less" scoped>
@import '../style/theme/style.less'; // 引入主题样式文件
.activity {
  background-color: rgba(@baseColor,1);
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;

  .activity-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;

    .title {
      padding-top: 2.5rem;
      color: #fff;
      margin-left: -2rem;
      font-size: 1.8rem;
      font-weight: 600;
    }

    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }

  .content {
    width: 100%;
    height: 9rem;
    margin-top: 2rem;
    background-color: #3a3a3a;
    border-radius: 1rem;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 0rem 2rem;

    .content-left {
      width: 4.6rem;
      height: 4.6rem;
      margin-right: 1.2rem;

      img {
        width: 4.6rem;
        height: 4.6rem;
      }
    }

    .content-right {
      font-size: 1.4rem;
      color: #fff;
      font-weight: 600;

      .info {
        font-size: 1.1rem;
        color: #fa7d1f;
        margin-top: 0.5rem;
      }
    }
  }

  .box {
    width: 100%;
    height: 25rem;
    margin-top: 5rem;
    //  background-color: #fff;
    background: url('../assets/qidai.png') no-repeat;
    background-size: 100% 100%;
    opacity: .7;
  }
}
// k线
.kx{
  background-color: rgba(@baseColor,1);
  // padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  padding-bottom: 10rem;
  // min-height: 100vh;
  // overflow: auto;
  // color: #fff;
  .nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0rem 1.6rem;
    .navtitle{
      color: rgba(@apptext);
      font-size: 1.7rem;
      font-weight: 700;
    }
    img{
      width: 2rem;
    }
    .vipdiv{
      position: relative;
      .vip{
        width: 5rem;
      }
      .spanvip{
        position: absolute;
        bottom: 0.9rem;
        right: 2rem;
        color: rgba(@apptext);
        font-size: 700;
      }
    }
    .rightimg{
      text-align: center;
      color: rgba(@apptext);
    }
    .normal{
      background-color: rgba(@baseColor,1);
      border: 1px solid rgba(@apptext);
      padding: 0.5rem 1rem;
      color: rgba(@apptext);
    }
  }
  .mesg{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0rem 1.6rem;
    margin-top: 1.5rem;
    .mesgone{
      color: rgb(2, 194, 144);
      text-align: center;
      margin-right: 6rem;
    }
    .mesgtwo{
      color: rgb(171, 194, 223);
      font-weight: 500;
      .marbottom{
        margin-bottom: 0.5rem;
      }
    }
    .mesgthree{
      color: rgba(@apptext);
      font-weight: 550;
      .marbottom{
        margin-bottom: 0.5rem;
      }
    }
  }
  .kxbody{
    margin-top: 1rem;
    width: 100%;
    .kxtabar{
      display: flex;
      justify-content: space-between;
      align-items: center;
      div{
        border: 1px solid #f0f0f0;
        background-color: rgba(@baseColor,1);
        color: rgba(@apptext);
        width: 100%;
        text-align: center;
        padding: 0.3rem 0;
        font-size: 1.5rem;
      }
    }
  }
  .fxk{
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
  .bone{
    padding: 0 1rem;
    color: rgba(@apptext);
    ::v-deep .van-tabs__nav{
      background-color: rgba(@pageBgColor,1);
      border: none;
    }
    .bonetop{
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .bonex{
      border: 2px solid #000;
      margin: 1rem 0;
    }
    .bonebottom{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .bxz{
    padding: 0 1rem;
    margin: 1rem 0;
    margin-bottom: 0.5rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .bxztop{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .bxztopdiv{
        width: 100%;
        // border: 1px solid #ccc;
        font-weight: 700;
        text-align: center;
        padding: 0.3rem 0;
        margin-right: 0.5rem;
        margin-top: 0.5rem;
        width: 8rem;
      }
    }
  }
  .btwo{
    padding: 0 1rem;
    margin-top: 1rem;
    ::v-deep .van-tabs__nav{
      background-color: rgba(@pageBgColor,1);
      border: none;
    }
    .trade{
      margin: 2rem 1rem;
      .waitin{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(@pageBgColor,1);
        color: rgba(@apptext);
        padding: 1rem;
        border-radius: .3rem;
        .waitinleft{
          display: flex;
          align-items: center;
          img{
            width: 3rem;
            margin-right: 2rem;
          }
          .waitinleftzi{
            font-size: 1.5rem;
            color: rgba(@apptext);
          }
        }
        .waitinright{
          .waitinrightzi{
            text-align: center;
            font-weight: 700;
          }
        }
      }
    }
    .historical{
      margin: 2rem 1rem;
      .minute{
        padding: 1rem;
        border-radius: .3rem;
        background: rgba(@pageBgColor,1);
        color: rgba(@apptext);
        .minone,.mintwo{
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 700;
        }
        .minone{
          margin-bottom: 1rem;
        }
        .minthree{
          display: flex;
          align-items: center;
          margin-top: 1rem;
          div{
            padding: 0.3rem 0.325rem;
            border-radius: 3px;
            font-weight: 700;
            // margin-right: .5rem;
          }
        }
      }
    }
  }
}
.mesgone div:nth-child(1){
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
// tabbar
.kxictive{
  background: rgba(@giftkx) !important;
  color: rgba(@baseColor,1) !important;
}
.tabarmagin{
  margin-top: 1rem;
}
// 倒计时
.colon {
    display: inline-block;
    margin: 0 4px;
    color: #1989fa;
}
.block {
  display: inline-block;
  width: 22px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background-color: #1989fa;
}
// 下注分类
.Big{
  background-color: #FF4300 !important;
}
.Small{
  background-color: rgb(49, 207, 52) !important;
}
.Odd{
  background-color: rgb(245, 162, 103) !important;
}
.Even{
  background-color: rgb(3, 192, 255) !important;
}
.Big-Odd{
  background-color: rgb(202, 150, 199) !important;
}
.Small-Odd{
  background-color: rgb(150, 157, 68) !important;
}
.Big-Even{
  background-color: rgb(0, 0, 0) !important;
}
.Small-Even{
  background-color: rgb(180, 49, 98) !important;
  margin-right: 0;
}
.Big-Evenfff{
  color: #fff !important;
}
// 弹出层
.prpott{
  .ttone{
    font-size: 2rem;
    font-weight: 700;
  }
  .tter{
    color: #333;
    font-weight: 700;
  }
  .ttsan{
    color: rgb(153, 153, 153);
    margin: 0.8rem 0;
  }
  input{
    width: 100%;
    padding: 1rem;
    border-radius: 1rem;
    margin: 1rem 0;
  }
  .ttbutton{
    margin-bottom: 2rem;
  }
}
// 暂无数据
.datanull{
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  color: rgba(@apptext);
  img{
    width: 20rem;
  }
}
// 切换模式
.qh{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 1rem;
  div{
    width: 100%;
    text-align: center;
    background-color: rgba(@baseColor);
    color: rgba(@apptext);
    border: 1px solid #ccc;
    padding: .5rem 0;
    font-size: 1.9rem;
    font-weight: 700;
  }
}
.qhactive{
  background: rgba(@giftactive) !important;
  color: rgba(@baseColor,1) !important;
}
</style>
