// 2、创建英文语言包对象
export default {
  // 登录
  login: {
    language: 'language',
    login: 'Login / Registration',
    usernameplaceholder: 'Please fill in your phone number',
    passwordplaceholder: 'Please enter your login password',
    Forgotpassword: 'Forgot password',
    Determine: 'Log on',
    rulesusername: 'Phone number cannot be empty',
    rulespassword: 'Password cannot be empty'
  },
  // 忘记密码
  Forgot: {
    title: 'Forgot password',
    phone: 'phone number',
    Newpassword: 'New password',
    Confirmationpassword: 'Confirmation password',
    code: 'Verification code',
    codes: 'code',
    Determine: 'Determine',
    rulesemail: 'Phone number cannot be empty',
    emailplaceholder: 'please input your email', // 请输入邮箱
    newpassword: 'Please enter a new password',
    rulespassword: 'Please enter password',
    torulespassword: 'The two passwords must be consistent',
    rulescode: 'The verification code cannot be empty',
    Changecode: 'Please fill in your phone number first'
  },
  // 注册
  register: {
    username: 'Username',
    phone: 'Phone number',
    password: 'Password',
    code: 'Verification code',
    Invitation: 'Invitation code',
    Determine: 'Determine',
    phonecode: 'Mobile verification code',
    rulesusername: 'User name cannot be empty',
    rulesnumber: 'Phone number cannot be empty',
    rulespassword: 'Password cannot be empty',
    rulescode: 'The verification code cannot be empty',
    rulesInvitation: 'Invitation code cannot be empty',
    Changecode: 'Please fill in your phone number first',
    codes: 'code'
  },
  // 首页
  home: {
    top: 'Welcome back',
    platform: 'The platform is running safely',
    timeday: 'DAY',
    timehour: 'HOUR',
    timemin: 'MINUTE',
    timesec: 'SECOND',
    currencies: 'Popular currencies',
    SignIn1: 'One Day',
    SignIn2: 'Two Day',
    SignIn3: 'Three Day',
    SignIn4: 'Four Day',
    SignIn5: 'Five Day',
    SignIn6: 'Six Day',
    SignIn7: 'Seven Day',
    gift: 'Mystery gift box',
    Sign: 'sign in now',
    chick: 'Signed in today'
  },
  // 公告
  gonggao: {
    title: 'Announcement'
  },
  // 公告详情
  gonggaodetails: {
    title: 'Announcement Details'
  },
  // 签到
  SignIn: {
    title: 'Sign-in center',
    profit: 'Earn rewards',
    SignIn1: 'One Day',
    SignIn2: 'Two Day',
    SignIn3: 'Three Day',
    SignIn4: 'Four Day',
    SignIn5: 'Five Day',
    SignIn6: 'Six Day',
    SignIn7: 'Seven Day',
    gift: 'Mystery gift box',
    Sign: 'sign in now',
    chick: 'Signed in today'
  },
  // 签到记录
  CheckinRecord: {
    title: 'Daily check-in record'
  },
  // k线图
  diagram: {
    height: 'Height',
    low: 'low',
    quantity: '24H quantity',
    Recharge: 'Recharge',
    purchase: 'purchase',
    min1: '1 min',
    min5: '5 min',
    min30: '30 min',
    hour1: '1 hour',
    dayk: 'Day k',
    Weekk: 'Week k'
  },
  // 矿机运行
  Receive: {
    Remaining: 'Remaining start time',
    Start: 'Start',
    Click: 'Click to view',
    Help: 'Help Cente',
    Team: 'Team',
    Totalnumber: 'Total number of people',
    Todays: 'Today s earnings',
    available: 'Benefits of Next Level',
    Recharge: 'Recharge',
    Withdraw: 'withdraw',
    friends: 'Invite friends to join to get rewards',
    qd: 'Start', // 新增--启动
    yq: 'invite', // 邀请
    lrtj: 'Profit statistics', // 利润统计
    tjsj: 'statistical data', // 统计数据
    xj: 'particulars' // 细节
  },
  // 帮助中心
  Helpcenter: {
    title: 'Help Center'
  },
  // 帮助中心详情
  Helpcenterdetails: {
    title: 'Help Center Details'
  },
  // 团队
  party: {
    title: 'My team',
    Totalnumber: 'Total number of people',
    Todays: 'Registration Rewards',
    available: 'Recharge rewards',
    team1: 'Tier 1 team',
    team2: 'Tier 2 team',
    team3: 'Tier 3 team',
    Referral: 'Referral code',
    Share: 'Share link',
    Success: 'Replicating Success',
    Job: 'Job Title',
    Income: 'Income',
    Receive: 'Receive',
    zczrs: 'Total number of registrations', // 注册总人数
    czzrs: 'Total number of recharges', // 充值总人数
    czyj: 'Recharge Commission', // 充值佣金
    zczje: 'Total Recharge Amount' // 总充值金额
  },
  // 团队等级
  Grade: {
    title1: 'Level-1',
    title2: 'Level-2',
    title3: 'Level-3',
    Username: 'Username',
    Phone: 'Phone',
    Recharge: 'Recharge',
    qxz: 'Please select', // 请选择 修改
    wcz: 'Not recharged', // 未充值
    ycz: 'Recharged', // 有充值
    cpgq: 'Product Expiration', // 产品过期
    zzyx: 'Running' // 正在运行
  },
  // 充值选择
  Recharge: {
    title: 'Recharge Channel',
    recharge: 'Recharge',
    amount: 'Recharge amount',
    Please: 'Please enter the recharge amount',
    confirm: 'confirm',
    success: 'choose success',
    less: 'The recharge amount cannot be less than one'
  },
  // 充值详情
  Charge: {
    Address: 'Copy Address',
    reminder: 'Recharge reminder',
    assets: 'Dont add assets to accounts other than the official account, otherwisethe assets will not be retrieved',
    amount: 'The amount of the transfer must be in full accordance with the actualamount of the order',
    Upload: 'Submit recharge order',
    Please: 'Please ensure that the image is selected',
    Success: 'Replicating Success',
    Remark: 'Remark:Remember to upload the screenshot after recharging'
  },
  // 充值记录
  Rechargerecord: {
    title: 'Recharge record',
    Date: 'Date',
    Amount: 'Amount',
    Channel: 'Channel',
    State: 'State',
    recharged: 'To be recharged',
    successful: 'Recharge successful',
    Notrecharged: 'Not recharged',
    shzt: 'Audit status', // 审核状态
    shz: 'in review' // 审核中
  },
  // 提现
  Withdraw: {
    title: 'Withdraw',
    Amount: 'Amount',
    Available: 'Available',
    All: 'All',
    address: 'address',
    Submit: 'Submit',
    Currently: 'Currently supports two withdrawal methods',
    Bank: 'Bank withdrawal time: withdrawals can be made at any time from Monday to Friday',
    Arrival: 'Arrival time: prepare according to the bank arrival time, usually 30-48 hours',
    within: 'The arrival time is within 72 hours during the tax-free day subsidy period',
    withdrawal: 'The withdrawal amount cannot be less than 15',
    receiving: 'Please bind the receiving account first before proceeding with the operation',
    min: 'Min',
    max: 'Max',
    fee: 'Fee'
  },
  // 采矿页面
  Mining: {
    title: 'Trading straregy',
    Power: 'Power',
    progress: 'In progress',
    Computer: 'Computer power',
    Purchasing: 'Purchasing price',
    Daily: 'Daily output income',
    Valid: 'Valid period',
    Days: 'Days',
    Recharge: 'Recharge',
    purchase: 'Buy Now', // purchase
    Miner: 'Miner',
    After: 'After the expiration, the miner can give you at any time',
    Experience: 'Trading straregy',
    Price: 'Price',
    profit: 'Day profit',
    Start: 'Start date',
    End: 'End date',
    Agree: 'Agree',
    Escrow: 'Escrow Agreement',
    Artificial: 'Artificial intelligence mining solution',
    Users: ' Users can mine by purchasing mining machines or renting cloud computing power',
    Hash: 'Hash rate: 1TH/S-100TH/S ',
    Benefit: 'Benefit: Get 80% of each coin produced, andthe system will automatically settle to the users account',
    Withdrawal: 'Withdrawal: Withdrawal at any time, 24 hours for withdrawal',
    experience: '(The experience miner is for user experience only)',
    Special: 'Special Note',
    calculation: 'The calculation of this data is based on the current currency price and estimated according to the mining pool currency data, and the system automatically converts it into USD. For specific actual currency production data, please refer to the actual situation for the verified currency data',
    machine: 'Mining machine and cloud computing power income may decreaseover time, and the specific annualized income may be subject to the actual annualized income at that time',
    mining: 'Purchase a mining machine or rent a cloud computing power plan, and the currency production custody maintenance fee accounts for 20% of the cost of the early dynamic adjustment support equipment. The expected amount of currency will not be less than 70% per day. The current calculation is based on the lower output value to calculate the income. The actual annualized expectation is higher than 300%. The actual amount of currency produced shall prevail.',
    Currency: 'Currency production time T+1 (that is, within one day after the completion of nuclear delivery and custody, and deposit of pledged currency), please refer to the official actual official currency production date for details',
    Confirm: 'Confirm',
    first: 'Confirm first to agree to the escrow agreement',
    purchases: 'Number of purchases',
    gmsl: 'Purchase quantity',
    yjsy: 'Projected Revenue'
  },
  // 我的页面
  my: {
    Accumulated: 'Accumulated rewards',
    Today: 'Today s earnings',
    total: 'total revenue',
    available: 'Available balance',
    wallet: 'My wallet',
    GPT: 'My GPT',
    center: 'Help center',
    address: 'Wallet address',
    envelope: 'Code Coupon',
    Video: 'Video function',
    Download: 'App Download',
    company: 'About the company',
    Sign: 'Sign out',
    Exit: 'Exit successfully',
    mykj: 'My robot' // 我的矿机 修改
  },
  // 设置页面
  install: {
    title: 'install',
    switch: 'switch language',
    name: 'Modify name',
    Password: 'Change Password',
    Phone: 'modify phone',
    binding: 'App binding'
  },
  // 上传头像
  Headimage: {
    Avatar: 'Avatar upload',
    Upload: 'Upload',
    image: 'The uploaded image cannot be empty'
  },
  // 修改名字
  ModifyName: {
    name: 'Modify name',
    Determine: 'Determine',
    cannot: 'Name cannot be empty'
  },
  // 修改密码
  ChangePassword: {
    title: 'Change Password',
    Old: 'Old login password',
    New: 'New login password',
    Confirm: 'Confirm Password',
    Determine: 'Determine',
    oldpassword: 'Please enter old password',
    newpassword: 'Please enter a new password',
    Confirmationpassword: 'Please enter password',
    twopasswords: 'The two passwords must be consistent'
  },
  // 修改手机号
  Phonenumber: {
    title: 'Phone number',
    phone: 'phone number',
    code: 'Mobile verification code',
    codes: 'code',
    Determine: 'Determine',
    Phonerules: 'Phone number cannot be empty',
    coderules: 'The verification code cannot be empty',
    Please: 'Please fill in your phone number first'
  },
  // 所有记录
  Mypurse: {
    title: 'My purse',
    All: 'All',
    type: 'type',
    Recharge: 'Recharge',
    withdraw: 'withdraw',
    product: 'product purchase',
    daily: 'daily income',
    Direct: 'direct referral reward',
    envelope: 'Red envelope collection',
    fixed: 'fixed income',
    check: 'check in',
    benefits: 'team benefits',
    Cancel: 'Cancel',
    Theres: 'Theres no more',
    Loading: 'Loading',
    System: 'System adjustment'

  },
  // 矿机状态
  product: {
    title: 'Buy product',
    progress: 'In progress',
    purchases: 'Number of purchases',
    Computer: 'Computer power',
    price: 'Purchasing price',
    Daily: 'Daily output income',
    running: 'Running',
    expired: 'Expired',
    Expired: 'Expired',
    start: 'start time',
    end: 'end time'
  },
  // 购买记录
  Purchaserecord: {
    title: 'Purchase History',
    types: 'types of',
    product: 'product name',
    price: 'product price',
    Daily: 'Daily Return',
    start: 'start time',
    end: 'end time'
  },
  // 地址
  Address: {
    title: 'Wallet address',
    already: 'You have already added an address. If you need to modify it, please contact the administrator to modify it'
  },
  // 新增地址
  Addressdetails: {
    title: 'Add Address',
    Address: 'Address',
    Determine: 'Determine',
    empty: 'Address cannot be empty',
    Successfully: 'Successfully added'
  },
  // 领取红包
  Redemption: {
    title: 'Redemption code',
    Please: 'Please enter the redemption code',
    Determine: 'Determine',
    Exchange: 'Exchange code cannot be empty'
  },
  // 视频介绍
  Video: {
    title: 'video introduction'
  },
  // 公司介绍
  company: {
    title: 'About the company'
  },
  // 活动列表
  activity: {
    title: 'activity',
    hot: 'hot product',
    discounted: 'discounted products'
  },
  // 绑定邮箱
  mail: {
    mailbox: 'Mailbox',
    yzm: 'Code',
    srmail: 'Please enter your email address', // 请输入您的电子邮件地址
    sryzm: 'send', // 请输入验证码
    sendcode: 'Send', // 发送验证码
    emptyemail: 'Email cannot be empty' // 电子邮件不能为空
  },
  // 利润
  profit: {
    jrsy: "Today's earnings", // 今日收益
    zcyqjl: 'Register Invitation Rewards', // 注册邀请奖励
    jqsy: 'Machine revenue', // 机器收益
    tdyjjl: 'Team commission rewards', // 团队佣金奖励
    yxsr: 'Monthly salary income', // 月薪收入
    lrze: 'Total profit', // 利润总额
    zzcyqjl: 'Total Registration Invitation Rewards', // 总注册邀请奖励
    zjqsy: 'Total machine revenue', // 总机器收益
    ztdyjjl: 'Total team commission reward', // 总团队佣金奖励
    zyxsr: 'Total monthly salary income', // 总月薪收入
    jrzlr: "Today's total profit", // 今日总利润
    zlr: 'Total profit' // 总利润
  },
  // 底部导航
  foot: {
    home: 'Home', // 首页
    yx: 'Share', // 运行
    jy: 'Robot', // 交易
    hd: 'Transaction', // 活动
    my: 'My' // 我的
  },
  // rechargettwo
  rechargettwo: {
    name: 'name',
    bank: 'bank',
    account: 'account',
    qtjczjt: 'Please add a recharge screenshot',
    czje: 'Recharge amount'
  }
}
