<template>
  <!-- 余额宝 -->
  <div class="Video">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text='Pull down to refresh' loosing-text="Let it go and update" loading-text='Loading...' pull-distance='100'>
      <div style="min-height: 100vh;">
    <div class="Video-title">
      <van-icon
        @click="$router.back()"
        name="arrow-left"
        size="2rem"
        color="#fff"/>
      <div class="title">Yu'E Bao</div>
      <div class="img" @click="$router.push('/yuebaodetails')">
        <img src="../assets/czjl2.png" alt=""/>
      </div>
    </div>
	
	<div class="infobox">
			<div style="margin-top: 50%;transform: translateY(-50%);">
				<div>Saldo Transferido</div>
				<div style="font-size: 2rem;font-weight: 600;margin-top: 1rem;">{{yuebao}}</div>
			</div>
	</div>
	
    <div class="box" v-for="(i,v) in yuebaolist" :key="v" >
      <!-- <div class="btn">{{i.name}}</div> -->
      <div class="content">

        <div>
          <div class="cloclo" style="font-size: 1.3rem;">Monto de transferencia de entrada y salida</div>
          <!-- <div style="margin-top: 1rem; color: #FF5757;">{{i.price}} USDT</div> -->
		  <div class="money">
		    <input type="number" v-model="money" placeholder="Por favor ingrese el monto de la transferencia" />
			<div class="allpic" @click="priceall">ALL</div>
		  </div>
        </div>
       <div>
          <div class="receive">Una vez que la transferencia sea exitosa, se espera que se generen intereses en 3 días.</div>
        </div>
      </div>
      <div class="receive">Se espera que genere un {{bili}} de ingresos </div>
      <div class="xian"></div>
      <!-- <div class="Number">
        <div>Número de compras</div>
        <div style="color: #fff;">ilimitado</div>
      </div> -->
    <div style="display: flex;justify-content: space-evenly;">
		<div class="sbotton" @click="PurchaseonYuebao(1)">Transferencia +</div>
		<div class="sbotton" @click="PurchaseonYuebao(2)">Trasferencia -</div>
	</div>
    </div>
    <div class="fixed">
      <div style="font-size: 1.3rem;">Saldo disponible:</div>
      <div style="margin-left: .5rem; font-size: 1.3rem; color: #FF5757;">{{balance}}</div>
      <div class="Recharge" @click="tocharge">Completar</div>
    </div>
    </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { balancepreciousapi, PurchaseonYuebaoapi } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'AddRess',
  data () {
    return {
      yuebaolist: [],
      bili: '',
      balance: '',
      frequency: 1,
      isLoading: false,
	  money:'',
	  yuebao:''
    }
  },
  created () {
    this.Videointroduction()
  },
  methods: {
    onRefresh () {
      setTimeout(() => {
        this.$router.go(0)
      }, 1000)
    },
    // 余额宝列表
    async Videointroduction () {
      const res = await balancepreciousapi()
      // console.log('666', res)
      this.yuebaolist = res.data.data
      // console.log(this.yuebaolist)
      res.data.data.forEach(i => {
        // console.log(i.bili)
        this.bili = i.bili
        i.bili = parseFloat(i.bili) * 0.01
      })
        this.balance = parseFloat(res.data.balance).toFixed(2)
		this.yuebao = parseFloat(res.data.yuebao).toFixed(2)
		
    },
    // 余额宝购买
    async PurchaseonYuebao (index) {
		if(index==1) { //买入
			if (this.frequency === 1) {
			  this.frequency = 0
			  const data = {
			    price: this.money,
					  type:'1'
			  }
			  const res = await PurchaseonYuebaoapi(data)
			  // console.log(res)
			  if (parseFloat(res.code) === 1) {
			    this.frequency = 1
				this.money=''
			  } else {
			    setTimeout(()=>{
					this.frequency = 1
				},1000)
			  }
			  Toast({
			    message: res.msg,
			    duration: 1200,
			    className: 'noticeWidth' // 所加样式名
			  })
			  this.Videointroduction()
			}
		}
		if(index==2) { //买入
			if (this.frequency === 1) {
			  this.frequency = 0
			  const data = {
			    price: this.money,
					type:'2'
			  }
			  const res = await PurchaseonYuebaoapi(data)
			  // console.log(res)
			  if (parseFloat(res.code) === 1) {
			    this.frequency = 1
				this.money=''
			  } else {
			    setTimeout(()=>{
			    	this.frequency = 1
			    },1000)
			  }
			  Toast({
			    message: res.msg,
			    duration: 1200,
			    className: 'noticeWidth' // 所加样式名
			  })
			  this.Videointroduction()
			}
		}
      
    },
    // 去充值页面
    tocharge () {
      this.$router.push({
        path: '/recharges',
        query: {
          entrance: 'yuebao'
        }
      })
    },
	priceall() {
		if(this.balance>0) {
			this.money=this.balance
		}
	}
  }
}
</script>

<style lang="less" scoped>
@import '../style/theme/style.less'; // 引入主题样式文件
.Video {
  background-image: url('../assets/yuebao.png');
  background-size: 100% 100%;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  .Video-title {
     padding-top: 2.5rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #fff;
      font-size: 1.6rem;
    }
    .img {
      width: 1.55rem;
      height: 1.75rem;
    }
  }
  
  .infobox {
	  width: 24rem;
	  height: 24rem;
	  margin: 0 auto;
	  text-align: center;
	  // line-height: 20rem;
	  border-radius: 50%;
	  border: 1rem solid #efbb0b;
	  color: #fff;
	  font-size: 1.4rem;
	  /* x 偏移量 | y 偏移量 | 阴影模糊半径 | 阴影扩散半径 | 阴影颜色 */
	  // box-shadow: 2px 2px 2px 1px rgba(239, 187, 11, 0.2) ;
	  box-shadow: 0 0 2.5rem 2px rgba(239, 187, 11, 0.5) inset,0 0 3.5rem 2px rgba(239, 187, 11, 0.5);
	}
  
  .box {
    width: 100%;
    // height: 26.55rem;
    background-color: rgba(@baseColor,1);
    margin-top: 1.5rem;
    padding: 1.3rem;
    border-radius: 1rem;
	 text-align: center;
	 padding-top: 2rem;
    .btn {
      // width: 10rem;
      width: 100%;
      // height: 2.7rem;
      // background-color: #E8A622;
      border-radius: 1.4rem;
      color: rgba(@scrollBgColor,1);
      font-size: 1.4rem;
      text-align: center;
      // line-height: 2.7rem;
       
      // padding: .5rem;
      display: inline;
      padding: .5rem 1.5rem;
    }
    .content {
      // display: flex;
      color: rgba(255, 255, 255, .4);
      // align-items: center;
      // justify-content: space-between;
      text-align: left;
      margin-top: 1rem;
    }
	
	.cloclo {
		color: rgba(@apptext);
	}
	.money {
	  height: 4.5rem;
	  border: 1px solid #f6f6f6;
	  border-radius: 1rem;
	  background-color: rgba(@scrollBgColor,1);
	  color: rgba(@pageBgColor);
	  font-size: 1.4rem;
	  display: flex;
	  align-items: center;
	  padding: 0rem 1rem;
	  margin: 1.5rem 0rem;
	  position: relative;
	  
	
	  input {
	    width: 100%;
	    height: 100%;
	    background-color: rgba(@scrollBgColor,1);
	    color: rgba(@pageBgColor,1) !important;
	    border: none;
	    padding: 0rem 1rem;
		padding-right: 3.5rem;
	  }
	  // .all {
	  //   color: #15b4fb;
	  //   margin-left: 0.8rem;
	  // }
	  .allpic {
		  position: absolute;
		  top: 50%;
		  transform: translateY(-50%);
		  right: 1.5rem;
		  font-size: 1.2rem;
		  color: #efbb0b;
	  }
	}
	
    .receive {
      color: #E8A622;
	  font-size: 1.2rem;
      text-align: left;
      margin-top: 1.5rem;
    }
    .xian {
      width: 100%;
      height: 1px;
      background-color: rgba(255, 255, 255, .2);
       margin-top: 1.5rem;
    }
    .Number {
      display: flex;
      justify-content: space-between;
      color: rgba(255, 255, 255, .4);
      margin-top: 1.5rem;
    }
    .botton {
      width: 85%;
      height: 4rem;
      margin-left: 50%;
      transform: translateX(-50%);
      background-color: #15b4fb;
      text-align: center;
      line-height: 4rem;
      color: #fff;
      font-size: 1.2rem;
      border-radius: 2.8rem;
      // margin:2rem 0rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
	.sbotton {
		width: 40%;
		// flex: 1;
		height: 4rem;
		// margin-left: 50%;
		// transform: translateX(-50%);
		background-color: #efbb0b;
		text-align: center;
		line-height: 4rem;
		color: #fff;
		font-size: 1.4rem;
		border-radius: 2.8rem;
		// margin:2rem 0rem;
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
  }
  .fixed {
    width: 100%;
    height: 5.5rem;
    display: flex;
    justify-content: end;
    align-items: center;
    background-color: rgba(@baseColor,1);
    position: fixed;
    left: 0;
    bottom: 0;
    color: rgba(@scrollBgColor,1);
    .Recharge {
     width: 9rem;
     height: 3rem;
     background-color: #efbb0b;
     text-align: center;
     line-height: 3rem;
     border-radius: 2.4rem;
     margin: 0rem 1rem;
    }
  }
}
</style>
