export const themes = {
	default: {
		baseColor: `${246}, ${247},${249}`, // 基色（无变化） 灰黑
		pageBgColor: `${255}, ${255},${255}`, // 页面的背景色 白黑
		scrollBgColor: `${0}, ${0},${0}`, // 滚动条的背景色
		resultBgColor: `${255}, ${192},${203}`, // 结果背景色
		resultBorderColor: `${255}, ${255},${0}`, // 结果区背景色
		resultTextColor: `${0}, ${0},${0}, 0.9`, // 结果文字
		apptext: `${22}, ${22}, ${24}, 1`, // 自定义文字
		indextext: `${22}, ${22}, ${24}, 1`, // 首页文字
		indexbi: `${226}, ${226}, ${226}, 1`,
		recetext: `${209}, ${209}, ${209}, 1`, // 团队页 灰
		giftactive: `${22}, ${22}, ${24}, 1`, // 大乐透背景1
		giftkx: `${22}, ${22}, ${24}, 1`, // k线选择背景
		baicolor: `${255}, ${255}, ${255}, 1`, // k线选择背景
		heicolor: `${0}, ${0}, ${0}, 1`, // k线选择背景
		zhbgcolor: `${249}, ${249}, ${249}, 1`, // 转换盒子背景
		zhbg:`${255}, ${255}, ${255}, 1`, // 转换页面背景
		zhtxtcolor:`${0}, ${0}, ${0}, .3`, // 转换页面背景
	},
	dark: {
		baseColor: `${20}, ${21},${33}`, // 基色（无变化） 
		pageBgColor: `${0}, ${0},${0}`, // 页面的背景色
		scrollBgColor: `${255}, ${255},${255}`, // 滚动条的背景色
		resultBgColor: `${135}, ${206},${235}`, // 结果背景色
		resultBorderColor: `${0}, ${128},${0}`, // 结果区背景色
		resultTextColor: `${255}, ${255},${255}, 0.9`, // 结果文字
		apptext: `${255}, ${255}, ${255}, 0.9`, // 自定义文字
		indextext: `${158}, ${158}, ${158}, 1`, // 首页文字
		indexbi: `${20}, ${21}, ${30}, 1`,
		recetext: `${209}, ${209}, ${209}, 1`, // 团队页 灰
		giftactive: `${239}, ${187}, ${11}, 1`, // 大乐透背景1
		giftkx: `${255}, ${255}, ${255}, 1`, // k线选择背景
		heicolor: `${255}, ${255}, ${255}, 1`, // k线选择背景
		baicolor: `${0}, ${0}, ${0}, 1`, // k线选择背景
		zhbgcolor: `${32}, ${32}, ${32}, 1`, // 转换页面背景
		zhbg:`${24}, ${25}, ${32}, 1`, // 转换页面背景
		zhtxtcolor:`${255}, ${255}, ${255}, .3`, // 转换页面背景
	},
};