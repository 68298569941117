<template>
    <div class="HistoricalLottery">
        <!-- 自定义nav-->
        <div class="Phonenumber-title">
            <van-icon
                name="arrow-left"
                size="2rem"
                @click="$router.push('/gift')" />
            <div class="title">{{$t('addgift.rtitle')}}</div>
            <div></div>
        </div>
        <!-- 内容-->
        <div class="tab">
          <div v-html="rulelist"></div>
        </div>
    </div>
  </template>
  
  <script>
  import { prop } from '@/api/index'
  import { Toast } from 'vant'
  export default {
    name: 'RuleDisplay',
    data() {
      return {
        rulelist: ''
      }
    },
    created() {
      this.getrule()
    },
    methods: {
      async getrule() {
        const url = '/api/base/prop'
        await prop(url).then(res=>{
          if(res.code == 1){
            this.rulelist = res.data.game_rule
            // console.log(this.rulelist)
          }else{
            Toast({
              message: res.msg,
              duration: 1200,
              className: 'noticeWidth' // 所加样式名
            })
          }
        })
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  @import '../style/theme/style.less'; // 引入主题样式文件

  .HistoricalLottery{
    background-color: rgba(@baseColor,1);
    min-height: 100vh;
    padding-bottom: 5rem;
    ::v-deep .van-icon{
      color: rgba(@apptext);
    }
    .Phonenumber-title {
        padding-top: 1.5rem;
        display: flex;
        justify-content: space-between;
        // margin-bottom: 3.5rem;
        .title {
          color: rgba(@apptext);
          font-size: 1.8rem;
          // margin-left: 9rem;
          font-weight: 700;
        }
        ::v-deep .van-icon-arrow-left:before {
          margin-top: 0.3rem;
        }
    }
    .tab{
      margin: 2rem;
      color: rgba(@apptext);
    }
  }
  </style>
  