<template>
  <!-- 帮助中心详情 -->
  <div class="Announcement">
    <div class="Announcement-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        @click="$router.back()"
      />
      <!-- <div class="title">{{$t('Helpcenterdetails.title')}}</div> -->
      <div class="title">{{title}}</div>
      <div></div>
    </div>
    <div class="detil" ref="detils"></div>
  </div>
</template>

<script>
import { assistdetailsapi } from '@/api/index'
export default {
  name: 'AnnounCement',
  data () {
    return {
      id: 0,
      details: '',
      title: ''
    }
  },
  async mounted () {
    await this.detils()
    await this.assistdetails()
  },
  methods: {
    detils () {
      this.id = this.$route.query.id
      this.title = this.$route.query.title
    },
    async assistdetails () {
      const res = await assistdetailsapi()
      res.data.forEach(i => {
        if (parseFloat(this.id) === i.id) {
          this.details = i.content
        }
      })
      this.$refs.detils.innerHTML = this.details
    }
  }
}
</script>

<style lang="less" scoped>
@import '../style/theme/style.less'; // 引入主题样式文件
.Announcement {
  background-color: rgba(@baseColor,1);
  // background-image: url('../assets/suoyou.png');
  background-size: 100%  25rem;
  background-repeat:no-repeat;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  ::v-deep .van-icon{
    color: rgba(@apptext);
  }
  .Announcement-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    .title {
      color: rgba(@apptext);
      font-size: 1.8rem;
      // margin-left: 7rem;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }
  .detil {
     margin-top: 3rem;
     color: rgba(@apptext);
      font-size: 1.5rem;
    //   text-align: center;
    margin-left: .6rem;

    }

}
</style>
