<template>
  <div class="Trendanalysis">
    <!-- 自定义nav-->
    <div class="Phonenumber-title">
      <van-icon
          name="arrow-left"
          size="2rem"
          @click="$router.push('/gift')" />
      <div class="title">{{$t('addgift.Ttitle')}}</div>
      <div></div>
    </div>
    <!-- 内容-->
    <div class="body">
      <div class="botitle">
        <div class="botitleone"></div>
        <div>{{$t('addgift.tbodyyi')}}</div>
      </div>
      <div class="content" v-for="(item,index) in robotlist" :key="index" @click="toxq(item.predict.robot_id,item.title)">
        <div class="conleft">
          <img src="../assets/trendanaimg.png" />
        </div>
        <div class="conright">
          <div style="font-weight: 700;">{{item.title}}</div>
          <div>{{$t('addgift.tbodyer')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { robotList } from '@/api/index'
export default {
  name: 'TrenDanalysis',
  data() {
      return {
        robotlist: []
      }
  },
  created() {
    this.getrobotlist()
  },
  methods: {
    toxq(robot_id,title) {
      this.$router.push({
        path: '/TrenDanalyxq',
        query: {
          robot_id: robot_id,
          title: title
        }
      })
    },
    getrobotlist() {
      robotList().then(res=>{
        console.log(res)
        this.robotlist = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../style/theme/style.less'; // 引入主题样式文件
.Trendanalysis{
  background-color: rgba(@baseColor,1);
  min-height: 100vh;
  ::v-deep .van-icon{
    color: rgba(@apptext);
  }
  .Phonenumber-title {
      padding-top: 1.5rem;
      display: flex;
      justify-content: space-between;
      margin-bottom: 3.5rem;
    .title {
      color: rgba(@apptext);
      font-size: 1.8rem;
      // margin-left: 9rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.3rem;
    }
  }
  .body{
    margin: 2rem;
    .botitle{
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 1.3rem;
      color: rgba(@apptext);
      .botitleone{
        width: .4rem;
        height: 1.4rem;
        background-color: rgb(240, 185, 11);
        margin-right: .5rem;
      }
    }
    .content{
      background-color: rgba(@pageBgColor,1);
      color: rgba(@apptext);
      padding: 1.5rem;
      margin-top: 1rem;
      display: flex;
      align-items: center;
      border-radius: 1rem;
      .conleft{
        margin-right: 2rem;
        img{
          width: 4rem;
        }
      }
      .conright{
        font-size: 1.5rem;
      }
    }
  }
}
.conright div:nth-child(2){
  color: rgb(144, 144, 144);
}
</style>
