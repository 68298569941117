<template>
  <!-- 登录 -->
  <div class="login" :style="{ backgroundImage: `url(${bgurl})`}">
    <div class="logo">
      <img src="../assets/logo.png" alt="" class="logoimg"/>
    </div>
    <!-- <div class="title">AMP Robot</div> -->
    <div class="box">
      <div class="loginname">{{ $t("add.addlogintitle") }}</div>
      <div></div>
      <!-- <div @click="switchlanguage">{{ $t("login.language") }}</div> -->
    </div>
    <van-form @submit="onSubmit" ref="from" class="fromwith">
      <div style="position: relative;" class="dingwei">
        <div class="fromplace">{{$t('login.usernameplaceholder')}}</div>
        <van-field
          v-model="username"
          name="username"
          type="text"
          :rules="rules.username"
        />
        <div class="quhao" @click="showPopover = true">{{areacode}}</div>
        <!-- 下拉选择区号-->
        <van-popover v-model="showPopover" :actions="actions" @select="onSelect" :offset="[70, 10]">
        </van-popover>
      </div>
      <div class="position">
        <div class="fromplace">{{$t('login.passwordplaceholder')}}</div>
        <van-field
          v-model="password"
          name="password"
          type="password"
          :rules="rules.password"/>
      </div>
      <div class="Forgot">
        <!-- <div @click="toregister">Register</div> -->
        <div></div>
        <div @click="toForgot">{{ $t("login.Forgotpassword") }}?</div>
      </div>
      <div class="formbtn">
        <van-button round block type="info" native-type="submit">{{
          $t("login.Determine")
        }}</van-button>
      </div>
    </van-form>
    <div class="bottombj"></div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getlogin, getservice } from '@/api/index'
import { setTheme } from "../style/theme/theme" // 引入切换主题方法

export default {
  name: 'LoGin',
  data () {
    return {
      Language: localStorage.getItem('lagn') || 'pt-br',
      username: '',
      password: '',
      rules: {},
      loading: 1,
      hrefurl: '',
      bgurl: require('../assets/loginbk.png'),
      areacode: '+57',
      showPopover: false,
      actions: [{ text: '+57' }, { text: '+58' }],
    }
  },
  created () {
    this.geturl()
    // document.documentElement.setAttribute('theme-mode', 'dark')
    if (localStorage.getItem('theme') == 'dark') {
      this.bgurl = require('../assets/loginhs.png')
    }else{
      this.bgurl = require('../assets/loginbk.png')
    }
    // setTheme("dark")
    // localStorage.setItem("theme", 'default'); // 保存主题到本地，下次进入使用该主题
  },
  mounted () {
    this.setfromrules()
  },
  watch: {
    // 监听语言切换
    '$i18n.locale' () {
      this.setfromrules()
    }
  },
  methods: {
    onSelect (e) {
      console.log(e)
      this.areacode = e.text
    },
    // 判断是否是手机端，如果是，返回true
    // tochange () {
    //   this.username = this.username.replace(/[^\d]/g, '')
    // },
    // 校验规则
    setfromrules () {
      this.rules = {
        username: [
          {
            required: true,
            message: this.$t('login.rulesusername'),
            trigger: ['blur', 'change']
          }
        ],
        password: [
          {
            required: true,
            message: this.$t('login.rulespassword'),
            trigger: ['blur', 'change']
          }
        ]
      }
    },
    async onSubmit (value) {
      if (this.loading === 1) {
        this.loading = 0
        const params = {
          account: this.username,
          password: this.password
        }
        const res = await getlogin(params)
        this.loading = 1
        // console.log('123', res)
        // return false
        if (parseFloat(res.code) === 1) {
          localStorage.setItem('token', res.data.userinfo.token)
          localStorage.setItem('is_email', res.data.is_email)
          if (localStorage.getItem('tink_var')) {
          // console.log(111)
          } else {
            localStorage.setItem('tink_var', 'en-us')
          }
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
          setTimeout(() => {
            this.$router.push('/home')
          }, 1500)
        } else {
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        }
      }
    },
    // 去忘记密码
    toForgot () {
      this.$router.push('/forgot')
      // window.location.href = this.hrefurl
    },
    // 去注册页面
    toregister () {
      this.$router.push('/register')
    },
    // 切换语言
    switchlanguage () {
      console.log(this.Language)
      if (this.Language === 'zh-cn') {
        this.Language = 'en-us'
        this.$i18n.locale = 'en-us'
        localStorage.setItem('lang', 'en-us')
        localStorage.setItem('tink_var', 'en-us')
      } else if (this.Language === 'en-us') {
        this.$i18n.locale = 'zh-cn'
        this.Language = 'zh-cn'
        localStorage.setItem('lang', 'zh-cn')
        localStorage.setItem('tink_var', 'zh-cn')
      }
    },
    // 获取绑定电报url
    async geturl () {
      const url = '/api/index/get_service'
      const res = await getservice(url)
      this.hrefurl = res.data.telegram
      console.log(res.data.telegram, 'url')
    }
  }
}
</script>

<style lang='less' scoped>
@import '../style/theme/style.less'; // 引入主题样式文件
::v-deep .van-field__control::-webkit-input-placeholder {
  color: #E5E5E5 !important;
}
.login {
  // background: url('../assets/loginbk.png') no-repeat;
  // background-color: rgba(@baseColor,1);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 7.5rem 1.6rem;
  padding-top: 1.5rem;
  padding-left: 0;
  padding-bottom: 0rem;
  width: 100%;
  min-height: 100vh;
  .logo {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    .logoimg{
      width: 20rem;
    }
  }
  .fromplace{
    color: #BABFC6;
    font-size: 1.3rem;
    margin-left: 2rem;
    margin-top: 1.5rem;
  }
  .fromwith{
    width: 80%;
  }
  .box {
    display: flex;
    // color: #fff;
    justify-content: space-between;
    font-size: 1.4rem;
    margin-top: 15rem;
  }
  .title {
    color: #000;
    text-align: center;
    font-size: 2rem;
    // margin-top: 2.1rem;
  }
  .loginname {
    color: rgba(@apptext);
    font-size: 2.5rem;
    margin-left: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  ::v-deep .van-cell {
    background-color: #fff;
    border: 1px solid #E5E5E5;
    border-left: none;
    color: #000000;
    box-shadow: 0px 3px 20px 1px rgba(0,0,0,0.1);
    // border-radius: 1rem;
    margin-top: 2rem;
    padding: 1.2rem 0rem;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.3rem;
    margin-left: 1rem;
    &::after {
      border-bottom: 0px solid #000;
    }
  }
  ::v-deep .van-field__control {
    color: #000;
  }
  ::v-deep .position {
    position: relative;
  }
  // .code {
  //   color: #15b4fb;
  //   font-size: 1.4rem;
  //   position: absolute;
  //   top: 1.2rem;
  //   right: 2.2rem;
  // }
  ::v-deep .van-button {
    border-radius: 2.2rem;
    height: 4.4rem;
  }
  ::v-deep .van-button--info {
    background-color: #fff;
    border: 2px solid #EFBB0B;
    color: #333;
    // padding: 0 1rem;
    font-weight: bold;
    font-size: 1.4rem;
    margin-top: 5rem;
    width: 9rem;
  }
  ::v-deep .van-field__error-message {
    font-size: 1.4rem;
  }
  .Forgot {
    color: #EFBB0B;
    text-align: right;
    font-weight: 700;
    font-size: 1.3rem;
    margin: 1.5rem 0rem;
    // margin-bottom: 4rem;
    display: flex;
    justify-content: space-between;
    padding: 0rem 1rem;
  }

  ::v-deep input[type="password"]::-ms-reveal {
    display: none;
  }
  // .bottombj {
  //   position: fixed;
  //   bottom: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 25rem;
  //   background: url('../assets/dibubj.png') no-repeat;
  //   background-size: 100% 100%;
  // }
  .formbtn{
    display: flex;
    justify-content: end;
    padding-right: 3rem;
    ::v-deep .van-button{
      background-color: rgba(@baseColor,1);
      color: rgba(@apptext);
    }
  }
  .dingwei{
    ::v-deep .van-cell {
      padding-left: 5rem !important;
    }
  }
  .quhao{
    width: 5rem;
    height: 4.4rem;
    position: absolute;
    color: #EFBB0B;
    top: 53%;
    font-size: 1.5rem;
    left: 2rem;
  }
}
// 媒体查询
@media only screen and (max-width: 375px){
  // .box{
  //   margin-top: 9rem !important;
  // }
  // .fromwith{
  //   width: 60% !important;
  // }
  .login ::v-deep .van-button--info {
    margin-top: 1rem;
  }
}
</style>
