<template>
	<!-- 等级页面 -->
	<div class="Grade">
		<van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('addpull.pullyi')"
			:loosing-text="$t('addpull.puller')" :loading-text="$t('addpull.pullsan')" pull-distance='100'>
			<div style="min-height: 100vh;">
				<div class="Grade-title">
					<van-icon name="arrow-left" size="2rem" @click="$router.back()" />
					<div class="title">{{ title }}</div>
					<div></div>
				</div>
				<!-- 活跃人数-->
				<div class="huoy">
					<!-- <div >{{$t('addparty.gradeyi')}}: {{valid_user[1]}}</div>
      <div >{{$t('addparty.gradeer')}}: {{valid_user[2]}}</div>
      <div >{{$t('addparty.gradesan')}}: {{valid_user[3]}}</div> -->
					<div>Número de miembros activos de nivel 1: {{valid_user[1]}}</div>
					<div>Número de miembros activos de nivel 2: {{valid_user[2]}}</div>
					<div>Número de miembros activos de nivel 3: {{valid_user[3]}}</div>
					<!-- <div v-else>Level {{qqdata.level}} Current number of active people: 0</div> -->
					<!-- <div>Level 2 Current number of active people: </div>
      <div>Level 3 Current number of active people: </div> -->
				</div>
				<div class="zsearch" style="display: flex;justify-content: space-between">
					<!-- 选择级数-->
					<div class="search searchone" style="margin-right: 2rem;width: 20%;">
						<div>{{actions[index].text}}</div>
						<div>
							<van-icon name="arrow-down" @click="showPopover = true" />
							<van-popover v-model="showPopover" trigger="click" @select="onSelect" :actions="actions"
								placement="bottom-start" :offset="[-60, 10]">
								<!-- <div class="items">11111</div> -->
							</van-popover>
						</div>
					</div>
					<!-- <div class="search searchtwo" style="margin-right: 1rem;">
						<div v-if="qqdata.rech_status == ''">{{$t('Grade.qxz')}}</div>
						<div v-else>{{actions2[index2].text}}</div>
						<div>
							<van-icon name="arrow-down" @click="showPopovertwo = true" />
							<van-popover v-model="showPopovertwo" trigger="click" @select="onSelectcz"
								:actions="actions2" placement="bottom-end" :offset="[20, 10]">
							</van-popover>
						</div>
					</div> -->
					<div class="search searchtwo" style="width: 40%;">
						<div v-if="qqdata.run_status == ''">{{$t('Grade.qxz')}}</div>
						<div v-else>{{actions3[index3].text}}</div>
						<div>
							<van-icon name="arrow-down" @click="showPopoverthree = true" />
							<van-popover v-model="showPopoverthree" trigger="click" placement="bottom-end"
								:offset="[20, 10]">
								<div style="padding: 0rem 1rem;" v-for="(i,v) in actions3" :key="v">
									<div style="padding: 1.3rem 1.5rem; font-size: 1.4rem;border-bottom: 1px solid #f7f8f9;text-align: center;"
										@click="onSelectyx(i)">{{i.text}}</div>
								</div>
							</van-popover>
						</div>
					</div>

				</div>
				
					<div class="rqxz">
						<div class="rqinput" @click="rqxuanze(1)">{{fecha1}}</div>———<div class="rqinput" @click="rqxuanze(2)">{{fecha2}}</div>
					</div>

					<!-- <van-calendar color="#efbb0b" v-model="rqshow" :min-date="minDate" :max-date="maxDate" title="Selección de fecha" :show-subtitle="false" /> -->

					<div>
						<van-popup v-model="rqshow" position="bottom">
							<van-datetime-picker v-model="currentDate" type="date" title="Selección de fecha"
								:min-date="minDate" :max-date="maxDate" confirm-button-text='Confirmar'
								cancel-button-text='Cancelar' @confirm="rqconfirm" :formatter="formatter" @cancel=cancel />
						</van-popup>
					</div>
				
				
				<div class="top">
					<div>ID</div>
					<div>{{$t('Grade.Username')}}</div>
					<div>{{$t('Grade.Phone')}}</div>
					<!-- <div>{{$t('Grade.Recharge')}}</div> -->
					<div>Correr</div>
				</div>
				<div class="bottom" v-for="(i, v) in lvlist" :key="v">
					<div>{{i.id}}</div>
					<div>{{i.nickname}}</div>
					<div>{{i.mobile.replace(/^(.{3})(?:\d+)(.{4})$/, "$1****")}}</div>
					<div>{{i.active_status==0?'NO':'Yes'}}</div>
				</div>
			</div>
		</van-pull-refresh>
	</div>
</template>

<script>
	import {
		teamdetailsapi
	} from '@/api/index'
	import { Toast } from 'vant'
	export default {
		name: 'GraDe',
		data() {
			return {
				title: this.$t('addparty.gradetitle'),
				number: '',
				lvlist: [],
				valid_user: '',
				isLoading: false,
				actions: [{
					text: 'LV1'
				}, {
					text: 'LV2'
				}, {
					text: 'LV3'
				}],
				actions3: [{
					text: this.$t('Grade.cpgq')
				}, {
					text: this.$t('Grade.zzyx')
				}],
				actions2: [{
					text: this.$t('Grade.wcz')
				}, {
					text: this.$t('Grade.ycz')
				}],
				index: 0,
				index2: 0,
				index3: 0,
				showPopover: false,
				showPopovertwo: false,
				showPopoverthree: false,
				qqdata: {
					level: 1,
					run_status: '',
					rech_status: ''
				},
				rqshow: false,
				currentDate: new Date(),
				minDate: new Date(2018, 0, 1),
				maxDate: new Date(2028, 11, 31),
				fecha1: 'Por favor seleccione una fecha de inicio',
				fecha2: 'Por favor seleccione una fecha de finalización',
				fecha1s: '',
				fecha2s: '',
				value1:'',
				value2:'',
				value3:'',
				rqxzindex:'',
				timestamp1:'',
				timestamp2:''
			}
		},
		async created() {
			await this.tuandui()
			await this.teamdetails()
		},
		methods: {
			onRefresh() {
				setTimeout(() => {
					this.$router.go(0)
				}, 1000)
			},
			// 团队接口
			async teamdetails() {
				// const params = {
				//   level: this.number
				// }
				// console.log('231323', params)
				const res = await teamdetailsapi(this.qqdata)
				console.log('666666', res)
				this.lvlist = res.data.list
				this.valid_user = res.data.valid_user
			},
			// 判断团队
			tuandui() {
				// 根据点击的等级团队改变标题
				this.number = this.$route.query.number
				// console.log(this.number)
				// if (this.number === '3') {
				//   this.title = 'Level team 3'
				// } else if (this.number === '2') {
				//   this.title = 'Level team 2'
				// } else {
				//   this.title = 'Level team'
				// }
			},
			onSelect(e) {
				const text = e.text
				if (text == 'LV1') { // eslint-disable-line
					this.qqdata.level = 1
					this.index = 0
				} else if (text == 'LV2') { // eslint-disable-line
					this.qqdata.level = 2
					this.index = 1
				} else {
					this.qqdata.level = 3
					this.index = 2
				}
				this.teamdetails()
			},
			onSelectcz(e) {
				const text = e.text
				console.log(text)
				if (text == this.$t('Grade.wcz')) { // eslint-disable-line
					this.qqdata.rech_status = 1
					this.index2 = 0
				} else if (text == this.$t('Grade.ycz')) { // eslint-disable-line
					this.qqdata.rech_status = 2
					this.index2 = 1
				}
				// console.log(this.qqdata.run_status)
				this.teamdetails()
			},
			onSelectyx(e) {
				const text = e.text

				if (text == this.$t('Grade.cpgq')) { // eslint-disable-line
					this.qqdata.run_status = 1
					this.index3 = 0
					this.showPopoverthree = false
				} else if (text == this.$t('Grade.zzyx')) { // eslint-disable-line
					this.qqdata.run_status = 2
					this.index3 = 1
					this.showPopoverthree = false
				}
				this.teamdetails()
			},
			rqxuanze(index) {
				this.rqshow = true
				this.rqxzindex=index
			},
			cancel() {
				this.rqshow = false
			},
			formatter(type,value) {
					 if (type === 'year') {
					        this.value1 = value   // 可以拿到当前点击的数值
					        return `${value}`
					      } else if (type === 'month') {
					        this.value2 = value
					        return `${value}`
					      }else {
							  this.value3 = value
							  return `${value}`
						  }
					      
				},
				// 日期选项格式化函数
				rqconfirm () {
					this.rqshow = false
					  if(this.rqxzindex==1) {
						 this.fecha1=`${this.value1}-${this.value2}-${this.value3}` 
						 this.fecha1s=`${this.value1}-${this.value2}-${this.value3}` 
						  this.timestamp1 = +new Date(this.fecha1);//年月日转时间戳
					  }
					  if(this.rqxzindex==2) {
					  	 this.fecha2=`${this.value1}-${this.value2}-${this.value3}`	
						 this.fecha2s=`${this.value1}-${this.value2}-${this.value3}`
						 this.timestamp2 = +new Date(this.fecha2);//年月日转时间戳
					  }
				this.qqdata = {
					level: this.index+1,
					btime: this.fecha1,
					etime: this.fecha2,
					run_status: '',
					rech_status: ''
				}
				
				if(this.fecha1s!==''&&this.fecha2s!=='') {
					if(this.timestamp2<=this.timestamp1) {
						// console.log(this.timestamp2,'shiji2');
						// console.log(this.timestamp1,'shiji1');
						Toast({
						  message: 'Por favor elige la hora correcta',
						  duration: 1200,
						  className: 'noticeWidth' // 所加样式名
						})
						return
					}else {
						this.teamdetails()
					}
					
				}
				}

		}
	}
</script>

<style lang="less" scoped>
	@import '../style/theme/style.less'; // 引入主题样式文件

	.Grade {
		background-color: rgba(@baseColor, 1);
		// background-image: url('../assets/suoyou.png');
		background-size: 100% 25rem;
		background-repeat: no-repeat;
		padding: 0rem 1.6rem;
		// padding-top: 2.5rem;
		width: 100%;
		min-height: 100vh;

		::v-deep .van-icon {
			color: rgba(@apptext);
		}

		.Grade-title {
			padding-top: 2.5rem;
			display: flex;
			justify-content: space-between;
			margin-bottom: 1.7rem;

			.title {
				color: rgba(@apptext);
				font-size: 1.8rem;
				// margin-left: 11rem;
				font-weight: 700;
			}

			::v-deep .van-icon-arrow-left:before {
				margin-top: 0.4rem;
			}
		}

		.top,
		.bottom {
			color: #B2B2B2;
			display: flex;
			justify-content: center;
			padding: 1.5rem 0rem;
			border-bottom: 1px solid rgba(255, 255, 255, 0.1);
			font-size: 1.4rem;
			font-weight: bold;

			div {
				width: 25%;
				text-align: center;
			}
		}

		.bottom {
			border-bottom: 0px solid rgba(255, 255, 255, 0.1);
			font-weight: 600;
			color: rgba(@apptext);
		}
	}

	/* 修改*/
	.zsearch {
		display: flex;
		color: rgba(@apptext);
	}

	.rqxz {
		color: rgba(@apptext);
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 1rem;

		.rqinput {
			// padding: 1rem .5rem;
			// width: 13rem;
			// height: 2.5rem;
			// line-height: 2.5rem;
			text-align: center;
			padding: 0.5rem;
			border-radius: 0.5rem;
			border: 1px solid rgba(@apptext);
		}
	}

	.search {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border: 1px solid rgba(@apptext);
		border-radius: 0.5rem;
		padding: 0.5rem;
	}

	.searchone {
		width: 25rem;
	}

	.searchtwo {
		width: 35rem;
	}

	// 活跃人数
	.huoy {
		margin-bottom: 1rem;
		color: rgba(@apptext);

		div {
			margin-bottom: .5rem;
			font-size: 1.25rem;
			font-weight: 700;
		}
	}

	::v-deep .van-popover__action {
		// padding: 0rem !important;
		text-align: center !important;
	}

	::v-deep .van-picker__confirm {
		color: #efbb0b;
	}
</style>