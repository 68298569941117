import Vue from 'vue'
// 在这里引入你所需的组件
import {
	Button,
	Skeleton,
	Field,
	Form,
	Icon,
	NoticeBar,
	Popup,
	ActionSheet,
	Tabbar,
	TabbarItem,
	Tab,
	Tabs,
	Swipe,
	SwipeItem,
	CountDown,
	Checkbox,
	CheckboxGroup,
	Uploader,
	List,
	PullRefresh,
	Popover,
	DropdownMenu,
	DropdownItem,
	Switch,
	Picker,
	Overlay,
	Calendar,
	Cell,
	DatetimePicker,
	Dialog
} from 'vant'


// 按需引入UI组件
Vue.use(Button)
Vue.use(Skeleton)
Vue.use(Form)
Vue.use(Field)
Vue.use(Icon)
Vue.use(NoticeBar)
Vue.use(Popup)
Vue.use(ActionSheet)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(CountDown)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Uploader)
Vue.use(List)
// 全局注册
Vue.use(Dialog);
Vue.use(PullRefresh)

Vue.use(Popover)

Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Switch)
Vue.use(Picker)
Vue.use(Overlay)
Vue.use(Calendar);

Vue.use(Cell);

Vue.use(DatetimePicker);
