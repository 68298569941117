<template>
  <!-- 修改名字 -->
  <div class="ModifyName">
    <div class="ModifyName-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        @click="$router.push('/install')"
      />
      <div class="title">{{$t('ModifyName.name')}}</div>
    </div>
    <div class="money">
      <input type="text" v-model="name" />
    </div>
    <div class="bottom" @click="Modifyinformation">{{$t('ModifyName.Determine')}}</div>
  </div>
</template>

<script>
import { Modifyinformationapi } from '@/api/index'
import { Toast } from 'vant'
export default {
  data () {
    return {
      name: ''
    }
  },
  methods: {
    async Modifyinformation () {
      if (this.name.trim() !== '') {
        const data = {
          field: 'nickname',
          value: this.name
        }
        const res = await Modifyinformationapi(data)
        // console.log(res)
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        setTimeout(() => {
          this.$router.push('/install')
        }, 1000)
      } else {
        Toast({
          message: this.$t('ModifyName.cannot'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../style/theme/style.less'; // 引入主题样式文件
.ModifyName {
  // background: url('../assets/ampbk.png') no-repeat;
  // background-size: cover;
  background-color: rgba(@baseColor,1);
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  ::v-deep .van-icon{
    color: rgba(@apptext);
  }
  .ModifyName-title {
     padding-top: 2.5rem;
    display: flex;
    margin-bottom: 2.7rem;
    .title {
      color: rgba(@apptext);
      font-size: 1.8rem;
      margin-left: 8rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }
  .money {
    height: 5.5rem;
    // border: 1px solid rgba(255, 255, 255, 0.5);
    // border-radius: 1rem;
    // border-radius: 1rem;
    color: rgba(@apptext);
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    padding: 0rem 1rem;
    margin-top: 3rem;
    input {
      width: 100%;
      height: 100%;
      background-color: rgba(@baseColor,1);
      color: rgba(@apptext);
      border: 4px solid #F6F6F6;
      border-radius: 20px 20px 20px 20px;
      padding: 0rem 2.5rem;
    }
    .all {
      color: #15b4fb;
      margin-left: 0.8rem;
    }
  }
  .bottom {
    width: 100%;
    height: 4.4rem;
    background-color: #EFBB0B;
    text-align: center;
    line-height: 4.4rem;
    margin-top: 27rem;
    border-radius: 20px;
    font-weight: bold;
    font-size: 1.6rem;
    color: #333;
  }
}
</style>
