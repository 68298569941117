<template>
  <!-- 绑定电报 -->
  <div class="Phonenumber">
    <div class="Phonenumber-title">
      <van-icon
        name="arrow-left"
        size="2rem"
         @click="$router.push('/install')"/>
      <div class="title">{{$t('addmy.iboer')}}</div>
      <div></div>
    </div>
   <div v-if="show">
    <div>
      <div>
        <div class="Email">{{$t('addmy.appyi')}}</div>
        <input type="text" v-model="value" class="input">
		
      </div>
      <div class="submit" >
		  <div v-if="unlock==0" class="btn" @click="bangding">{{$t('addmy.appsi')}}</div>
    <div v-else>
			<van-button v-if="conta==1" round block type="info" @click="bangding">{{$t('Phonenumber.Determine')}}</van-button>
			<van-button v-else round block type="info" @click="tishi">{{$t('Phonenumber.Determine')}}</van-button>
		</div>
		
      </div>
    </div>
    <!-- <div v-else>
      <div class="chneggong">
          <img src="../assets/chengong.png" alt="">
      </div>
      <div class="successfully">{{$t('addmy.tele')}}</div>
      <div class="info">
          <div>{{$t('addmy.appsan')}}</div>
          <div>{{conta}}</div>
      </div>
      <div class="btn" @click="tishi">{{$t('addmy.appsi')}}</div>
    </div> -->
   </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { myuserapi, bangdingapi } from '@/api/index'
export default {
  name: 'PhoneNumber',
  data () {
    return {
      value: '',
      show: false,
      conta: '',
	  unlock:1
    }
  },
  created () {
    this.myuser()
  },
  methods: {
    async myuser () {
      const res = await myuserapi()
      // console.log('111', res)
      if(res.data.user.telegram) {
		  this.value = res.data.user.telegram
		  this.unlock = res.data.unlock
	  }else{
		  this.conta=1
	  }
      this.show = true
    },
    async bangding () {
      const data = {
        field: 'telegram',
        value: this.value
      }
      const res = await bangdingapi(data)
      // console.log(res)
      Toast({
        message: res.msg,
        duration: 1200,
        className: 'noticeWidth' // 所加样式名
      })
      if (parseFloat(res.code) === 1) {
        // this.value = this.conta
        setTimeout(() => {
          this.$router.push('/install')
        }, 2000)
      }
    },
    tishi () {
      Toast({
        message: this.$t('addmy.appts'),
        duration: 1200,
        className: 'noticeWidth' // 所加样式名
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../style/theme/style.less'; // 引入主题样式文件
.Phonenumber {
  background-color: rgba(@baseColor,1);
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  ::v-deep .van-icon{
    color: rgba(@apptext);
  }
  .Phonenumber-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.5rem;
    .title {
      color: rgba(@apptext);
      font-size: 1.8rem;
      // margin-left: 9rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }
   .input {
    margin-top: 1rem;
    width: 100%;
    height: 5rem;
    background-color: rgba(@pageBgColor,1);
    color: rgba(@apptext);
    // border: 1px solid rgba(255, 255, 255, 0.5);
    border: 2px solid #F6F6F6;
    border-radius: 1rem;
    // margin-top: 2rem;
    color: #333;
    padding-left: 2rem;
     padding-right: 2rem;
    font-size: 1.3rem;
  }
  ::v-deep .van-field__control {
    color: #fff;
  }
  ::v-deep .position {
    position: relative;
  }
  .code {
    color: #15b4fb;
    font-size: 1.4rem;
    position: absolute;
     top: 40%;
    transform: translateY(-50%);
    right: 2.2rem;
  }
  ::v-deep .van-button {
    border-radius: 2.2rem;
    height: 4.4rem;
  }
  ::v-deep .van-button--info {
    background-color: #EFBB0B;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    font-size: 1.6rem;
    color: rgba(@apptext);
  }
  ::v-deep .van-field__error-message {
    font-size: 1.4rem;
  }
  .Email {
    color: #fff;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.4rem;
  }
  .submit {
    margin-top: 15rem;
  }
  .bottom {
    width: 100%;
    height: 4.4rem;
    background-color: #EFBB0B;
    text-align: center;
    line-height: 4.4rem;
    // margin-top: 11rem;
    border-radius: 10px;
    font-weight: bold;
    font-size: 1.6rem;
    color: rgba(@apptext);
  }
  .chneggong {
    width: 8.4rem;
    height: 8.8rem;
    margin: 0 auto;
    margin-top: 5rem;
  }
  .successfully {
    color: #858585;
    text-align: center;
    margin-top: 2rem;
    font-size: 1.3rem;
  }
  .info {
    display: flex;
    color: rgba(@apptext);
    margin-left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    margin-top: 2rem;
    font-size: 1.6rem;
  }
  .btn {
    width: 80%;
    height: 4.4rem;
    background-color: #EFBB0B;
    text-align: center;
    line-height: 4.4rem;
    margin: 0 auto;
    margin-top: 15rem;
    border-radius: 10px;
    font-weight: bold;
    font-size: 1.6rem;
    color: #333;
  }
}
</style>
