import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Forgot from '../views/Forgot.vue'
import Home from '../views/Home.vue'
import Announcement from '../views/Announcement.vue'
import SignIn from '../views/SignIn.vue'
import CheckinRecord from '../views/CheckinRecord.vue'
import Allrecords from '../views/Allrecords.vue'
import Receive from '../views/Receive.vue'
import Helpcenter from '../views/Helpcenter.vue'
import Recharges from '../views/Recharges.vue'
import withdraw from '../views/Withdraw.vue'
import party from '../views/party.vue'
import Grade from '../views/Grade.vue'
import Record from '../views/Record.vue'
import Charge from '../views/Charge.vue'
import gift from '../views/gift.vue'
import Mining from '../views/Mining.vue'
import my from '../views/my.vue'
import product from '../views/product.vue'
import install from '../views/install.vue'
import ModifyName from '../views/ModifyName.vue'
import ChangePassword from '../views/ChangePassword.vue'
import Phonenumber from '../views/Phonenumber.vue'
import Modifyemail from '../views/Modifyemail.vue'
import diagram from '../views/diagram.vue'
import gonggaodetails from '../views/gonggaodetails.vue'
import bangzhudetails from '../views/bangzhudetails.vue'
import Address from '../views/Address.vue'
import Addressdetails from '../views/Addressdetails.vue'
import Purchaserecord from '../views/Purchaserecord.vue'
import register from '../views/register.vue'
import Headimage from '../views/Headimage.vue'
import Redemption from '../views/Redemption.vue'
import Video from '../views/Video.vue'
import Mypurse from '../views/Mypurse.vue'
import CompanyDetails from '../views/CompanyDetails.vue'
import EventDetails from '../views/EventDetails.vue'
import turntable from '../views/turntable.vue'
import BankCard from '../views/BankCard.vue'
import Withdrawalsrecord from '../views/Withdrawalsrecord.vue'
import YuEbao from '../views/YuEbao.vue'
import YuEbaodetails from '../views/YuEbaodetails.vue'
import airplane from '../views/airplane.vue'
import telegraph from '../views/telegraph.vue'
import Error403 from '../views/Error403.vue'
import lotteryrecord from '../views/lotteryrecord.vue'
import MailBox from '../views/MailBox.vue'
import ProfitStatistics from '../views/ProfitStatistics.vue'
import RechargeTwo from '../views/RechargeTwo.vue'
import HistoricalLottery from '../views/HistoricalLottery.vue'
import Rules from '../views/Rules.vue'
import Trendanalysis from '../views/Trendanalysis.vue'
import TrenDanalyxq from '../views/TrenDanalyxq.vue'
import gifter from '../views/gifter.vue'
import Activity from '../views/Activity.vue'
import TaskRecord from '../views/TaskRecord.vue'
import Duration from '../views/Duration.vue'
import Share from '../views/Share.vue'
import ActivityCenter from '../views/ActivityCenter.vue'
import Activityinfo from '../views/Activityinfo.vue'
import Community from '../views/Community.vue'
import Activityinfos from '../views/Activityinfos.vue'
import Fund from '../views/Fund/index.vue'
import FundRule from '../views/Fund/fundrule.vue'
import FundHistory from '../views/Fund/fundhistory.vue'
import Conversion from '../views/Conversion/index.vue'

Vue.use(VueRouter)  

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: Forgot
  },
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/announcement',
    name: 'Announcement',
    component: Announcement
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/checkinrecord',
    name: 'CheckinRecord',
    component: CheckinRecord
  },
  {
    path: '/allrecords',
    name: 'Allrecords',
    component: Allrecords
  },
  {
    path: '/receive',
    name: 'Receive',
    component: Receive
  },
  {
    path: '/helpcenter',
    name: 'Helpcenter',
    component: Helpcenter
  },
  {
    path: '/recharges',
    name: 'Recharges',
    component: Recharges
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: withdraw
  },
  {
    path: '/party',
    name: 'party',
    component: party
  },
  {
    path: '/grade',
    name: 'Grade',
    component: Grade
  },
  {
    path: '/record',
    name: 'Record',
    component: Record
  },
  {
    path: '/charge',
    name: 'Charge',
    component: Charge
  },
  {
    path: '/gift',
    name: 'gift',
    component: gift
  },
  {
    path: '/mining',
    name: 'Mining',
    component: Mining
  },
  {
    path: '/my',
    name: 'my',
    component: my
  },
  {
    path: '/product',
    name: 'product',
    component: product
  },
  {
    path: '/install',
    name: 'install',
    component: install
  },
  {
    path: '/modifyname',
    name: 'ModifyName',
    component: ModifyName
  },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    component: ChangePassword
  },
  {
    path: '/phonenumber',
    name: 'Phonenumber',
    component: Phonenumber
  },
  {
    path: '/modifyemail',
    name: 'Modifyemail',
    component: Modifyemail
  },
  {
    path: '/diagram',
    name: 'diagram',
    component: diagram
  }, {
    path: '/gonggaodetails',
    name: 'gonggaodetails',
    component: gonggaodetails
  }, {
    path: '/bangzhudetails',
    name: 'bangzhudetails',
    component: bangzhudetails
  }, {
    path: '/address',
    name: 'Address',
    component: Address
  }, {
    path: '/addressdetails',
    name: 'Addressdetails',
    component: Addressdetails
  }, {
    path: '/purchaserecord',
    name: 'Purchaserecord',
    component: Purchaserecord
  }, {
    path: '/register',
    name: 'register',
    component: register
  }, {
    path: '/Headimage',
    name: 'Headimage',
    component: Headimage
  }, {
    path: '/redemption',
    name: 'Redemption',
    component: Redemption
  }, {
    path: '/video',
    name: 'Video',
    component: Video
  }, {
    path: '/mypurse',
    name: 'Mypurse',
    component: Mypurse
  }, {
    path: '/companycetails',
    name: 'CompanyDetails',
    component: CompanyDetails
  }, {
    path: '/eventdetails',
    name: 'EventDetails',
    component: EventDetails
  }, {
    path: '/turntable',
    name: 'turntable',
    component: turntable
  }, {
    path: '/bankcard',
    name: 'BankCard',
    component: BankCard
  }, {
    path: '/withdrawalsrecord',
    name: 'Withdrawalsrecord',
    component: Withdrawalsrecord
  }, {
    path: '/yuebao',
    name: 'YuEbao',
    component: YuEbao
  }, {
    path: '/yuebaodetails',
    name: 'YuEbaodetails',
    component: YuEbaodetails
  }, {
    path: '/airplane',
    name: 'airplane',
    component: airplane
  }, {
    path: '/telegraph',
    name: 'telegraph',
    component: telegraph
  }, {
    path: '/error',
    name: 'Error403',
    component: Error403
  }, {
    path: '/lotteryrecord',
    name: 'lotteryrecord',
    component: lotteryrecord
  }, {
    path: '/MailBox',
    name: 'MailBox',
    component: MailBox
  }, {
    path: '/ProfitStatistics',
    name: 'ProfitStatistics',
    component: ProfitStatistics
  }, {
    path: '/RechargeTwo',
    name: 'RechargeTwo',
    component: RechargeTwo
  }, {
    path: '/HistoricalLottery',
    name: 'HistoricalLottery',
    component: HistoricalLottery
  }, {
    path: '/Rules',
    name: 'Rules',
    component: Rules
  }, {
    path: '/Trendanalysis',
    name: 'Trendanalysis',
    component: Trendanalysis
  }, {
    path: '/TrenDanalyxq',
    name: 'TrenDanalyxq',
    component: TrenDanalyxq
  }, {
    path: '/gifter',
    name: 'gifter',
    component: gifter
  }, {
    path: '/Activity',
    name: 'Activity',
    component: Activity
  }, {
    path: '/TaskRecord',
    name: 'TaskRecord',
    component: TaskRecord
  }, {
    path: '/Duration',
    name: 'Duration',
    component: Duration
  }, {
    path: '/Share',
    name: 'Share',
    component: Share
  },
  {
    path: '/ActivityCenter',
    name: 'ActivityCenter',
    component: ActivityCenter
  },
  {
    path: '/Activityinfo',
    name: 'Activityinfo',
    component: Activityinfo
  },
  {
    path: '/Community',
    name: 'Community',
    component: Community
  },
  {
    path: '/Activityinfos',
    name: 'Activityinfos',
    component: Activityinfos
  },{
    path: '/Fund',
    name: 'Fund',
    component: Fund
  },
  ,{
    path: '/FundRule',
    name: 'FundRule',
    component: FundRule
  },{
    path: '/FundHistory',
    name: 'FundHistory',
    component: FundHistory
  },{
    path: '/Conversion',
    name: 'Conversion',
    component: Conversion
  },

  // {   
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }  //动态路由匹配
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

// 添加以下代码即可  解决重复跳转路由的问题
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
