var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mailbox"},[_c('div',{staticClass:"Phonenumber-title"},[_c('van-icon',{attrs:{"name":"arrow-left","size":"2rem"},on:{"click":function($event){return _vm.$router.push('/install')}}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('addmy.ibosan')))]),_c('div')],1),_c('div',{staticClass:"ChangePassword"},[_c('van-form',[_c('div',[_c('div',{staticClass:"Email"},[_vm._v(_vm._s(_vm.$t('mail.mailbox')))]),_c('van-field',{attrs:{"rules":[
                            {
                                required: true,
                                message: this.$t('mail.srmail'),
                                trigger: ['blur', 'onSubmit']
                            }
                        ]},model:{value:(_vm.oldpassword),callback:function ($$v) {_vm.oldpassword=$$v},expression:"oldpassword"}})],1),_c('div',{staticClass:"mailts",staticStyle:{"color":"red","margin":"1rem 0"}},[_vm._v(" * "+_vm._s(_vm.$t('addmy.yxts'))+" ")]),_c('div',{staticClass:"submit"},[(_vm.unlock==0)?_c('div',{staticClass:"btn",on:{"click":_vm.tishi}},[_vm._v(_vm._s(_vm.$t('addmy.appsi')))]):_c('div',[(_vm.conta==1)?_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit"},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.$t('ChangePassword.Determine')))]):_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit"},on:{"click":_vm.tishi}},[_vm._v(_vm._s(_vm.$t('ChangePassword.Determine')))])],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }