<template>
  <!-- 矿机状态 -->
  <div class="product">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh"
    :pulling-text="$t('addpull.pullyi')" :loosing-text="$t('addpull.puller')" :loading-text="$t('addpull.pullsan')" pull-distance='100'>
      <div style="min-height: 100vh;">
        <div class="protitle">
          <div class="product-title">
            <van-icon
              @click="$router.back()"
              name="arrow-left"
              size="2rem"
            />
            <div class="title">{{ $t("product.title") }}</div>
            <div class="img" @click="topurchaserecord">
              <img src="../assets/record.png" alt="" />
            </div>
          </div>
        </div>
        <div class="probody">
          <van-tabs
            v-model="active"
            color="#EFBB0B"
            title-active-color="#EFBB0B"
            title-inactive-color="#7E650E"
            background="transparent"
            style="margin-top: 3rem;"
          >
            <van-tab :title="$t('product.running')">
              <div
                class="Content"
                v-for="(item, index) in Miningmachinelist"
                :key="index"
                v-show="item.status == 1"
              >
                <div class="top">
                  <div class="img">
                    <img :src="item.pic" alt="" />
                  </div>
                  <div class="Content-title">
                    <div>{{ item.mining_name }}</div>
                    <div class="green">
                      {{ item.status_text }}
                    </div>
                  </div>
                  <div class="number">{{ $t("Mining.purchases") }} : 1</div>
                </div>
                <div class="Content-info">
                  <div class="left">{{ $t("product.price") }}</div>
                  <div class="right">{{ item.mining_price }} USDT</div>
                </div>
                <div class="Content-info">
                  <div class="left">{{ $t("product.Daily") }}</div>
                  <div class="right">{{ item.daily_income }} USDT</div>
                </div>

                <div class="Content-info">
                  <div class="left">{{ $t("product.start") }}</div>
                  <div class="right righter">{{ item.starttime }}</div>
                </div>
                <div class="Content-info">
                  <div class="left">{{ $t("product.end") }}</div>
                  <div class="right righter">{{ item.endtime }}</div>
                </div>
                <!-- <div class="Content-info">
                  <div class="left">{{ $t("Mining.Valid") }}</div>
                  <div class="right">{{ item.days }} {{ $t("Mining.Days") }}</div>
                </div> -->
              </div>
            </van-tab>
            <van-tab :title="$t('product.expired')">
              <div
                class="Content"
                v-for="(item, index) in Miningmachinelist"
                :key="index"
                v-show="item.status == 2"
              >
                <!-- <div class="top">
                  <div class="Content-title">
                    <div>{{ item.mining_name }}</div>
                    <div class="hui">
                      {{ $t("product.Expired") }}
                    </div>
                  </div>
                </div> -->
                <div class="top">
                  <div class="img">
                    <img :src="item.pic" alt="" />
                  </div>
                  <div class="Content-title">
                    <div>{{ item.mining_name }}</div>
                    <div class="hui">
                      {{ $t("product.Expired") }}
                    </div>
                  </div>
                  <div class="number">{{ $t("Mining.purchases") }} : 1</div>
                </div>
                <!-- <div class="Content-info">
                  <div class="left">{{ $t("product.types") }}</div>
                  <div class="right">{{ item.category }}</div>
                </div> -->
                <div class="Content-info">
                  <div class="left">{{ $t("product.price") }}</div>
                  <div class="right">{{ item.mining_price }} USDT</div>
                </div>
                <div class="Content-info">
                  <div class="left">{{ $t("product.Daily") }}</div>
                  <div class="right">{{ item.daily_income }} USDT</div>
                </div>

                          <div class="Content-info">
                  <div class="left">{{ $t("product.start") }}</div>
                  <div class="right">{{item.starttime}}</div>
                </div>
                <div class="Content-info">
                  <div class="left">{{ $t("product.end") }}</div>
                  <div class="right">{{ item.endtime }}</div>
                </div>
              </div>
            </van-tab>
          </van-tabs>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { Productpurchaseapi, Purchaserecordapi } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'proDuct',
  data () {
    return {
      active: 0,
      show: false,
      Miningmachinelist: [],
      checked: false,
      list: {},
      id: 0,
      status: '',
      isLoading: false
    }
  },
  created () {
    this.Purchaserecord()
  },
  methods: {
    onRefresh () {
      setTimeout(() => {
        this.$router.go(0)
      }, 1000)
    },
    // 矿机购买记录
    async Purchaserecord () {
      const res = await Purchaserecordapi()
      console.log('6666666', res)
      this.Miningmachinelist = res.data.list
    },
    async showcell (id) {
      // console.log(id)..
      this.id = id
      this.show = true
      // console.log('0000000000', this.list)
    },
    // 产品购买
    async Purchase () {
      if (this.checked) {
        const data = {
          pro_id: this.id
        }
        const res = await Productpurchaseapi(data)
        // console.log('0123', res)

        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        this.show = false
        this.checked = false
        // 购买之后重新获取数据
        this.Miningmachine()
      } else {
        Toast({
          message: this.$t('Mining.first'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
      }
    },
    onSelect (item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false
      //   Toast(item.name)
    },
    torecharge () {
      // 去充值页面
      this.$router.push('/recharges')
    },
    // 去购买记录
    topurchaserecord () {
      this.$router.push('/purchaserecord')
    }
  }
}
</script>

<style lang="less" scoped>
@import '../style/theme/style.less'; // 引入主题样式文件
.product {
  // background-image: linear-gradient(to bottom,#DFA3FF,#5687FD);
  background-color: rgba(@baseColor,1);
  // padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  padding-bottom: 5.8rem;
  width: 100%;
  min-height: 100vh;
  ::v-deep .van-icon{
    color: #333;
  }
  .protitle{
    padding: 0 2rem;
    padding-top: 2.5rem;
    padding-bottom: 16rem;
    background-image: url('../assets/tearbk.png');
    background-size: cover;
    background-repeat: no-repeat;
    color: rgba(@apptext);
    font-size: 1.6rem;
    text-align: center;
    font-weight: 600;
    margin-bottom: 2.5rem;
  }
  .probody{
    padding: 0 2rem;
    position: relative;
    bottom: 17rem;
  }
  .product-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #333;
      font-size: 1.6rem;
    }
    .img {
      width: 1.55rem;
      height: 1.75rem;
    }
  }
  ::v-deep .van-tabs__wrap{
    border-radius: 3rem;
  }
  ::v-deep .van-tab {
    line-height: 2rem;
  }
  ::v-deep .van-tab__text--ellipsis {
    font-size: 1.6rem;
  }
  ::v-deep .van-tabs__line {
    width: 4rem;
    height: 0.2rem;
  }
  .Content {
    width: 100%;
    // height: 39.1rem;
    margin-top: 2.7rem;
    background-color: rgba(@pageBgColor,1);
    box-shadow: 0px 3px 12px 1px rgba(0,0,0,0.08);
    border-radius: 8px 8px 8px 8px;
    padding-bottom: 1.55rem;
    .top {
      width: 100%;
      position: relative;
      height: 13.25rem;
      border-radius: 1rem 1rem 0rem 0rem;
      .img {
        width: 100%;
        height: 13.25rem;
        img {
          width: 100%;
          height: 13.25rem;
        }
      }
      .Content-title {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        color: #7E650E;
        width: 100%;
        height: 3rem;
        line-height: 3rem;
        font-size: 1.3rem;
        padding: 0rem 1.3rem;
        background-color: #EFBB0B;
        .green {
          right: 0;
          color: #043E5B;
        }
      }
      .number {
        position: absolute;
        bottom: 0.5rem;
        right: 1rem;
        color: #fc9900;
        //  font-weight: 600;
        font-size: 1.3rem;
      }
    }
    .Content-info {
      color: rgba(@apptext);
      display: flex;
      justify-content: space-between;
      padding: 1rem 0rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin: 0rem 1.3rem;
      font-size: 1.2rem;
      .left {
        color: rgba(@apptext);
        font-weight: 700;
      }
      .right{
        color: #2DBE64;
        font-weight: bold;
      }
      .righter{
        color: #BE7A2D;
      }
    }
    .tips {
      color: #fb8815;
      font-size: 1.1rem;
      margin-left: 1.3rem;
      margin-top: 1.5rem;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      margin: 0rem 1.3rem;
      margin-top: 1.75rem;
      .left,
      .right {
        width: 14.9rem;
        height: 4.4rem;
        background-color: #15b4fb;
        border-radius: 0.7rem;
        text-align: center;
        line-height: 4.4rem;
        color: #fff;
        font-size: 1.3rem;
        font-weight: 600;
      }
      .right {
        background-color: #fb8815;
      }
    }
  }
  ::v-deep .van-action-sheet {
    background-color: #1f1f1f;
    padding: 0rem 1.6rem;
    .title {
      margin-top: 2rem;
    }
    .power {
      padding: 1.6rem 0rem;
      display: flex;
      justify-content: space-between;
      color: rgba(255, 255, 255, 0.6);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      font-size: 1.2rem;
      .Price {
        font-size: 1.4rem;
        color: #fff;
      }
    }
    .agreement {
      display: flex;
      color: #fff;
      font-size: 1.2rem;
      margin-top: 1.9rem;
      .Agree {
        margin-left: 1.3rem;
      }
    }
    // ::v-deep .van-radio__icon {
    //   width: 2rem;
    //   height: 2rem;
    //   border: 1px solid #787373;
    //   margin-right: 1.2rem;
    //   margin-top: 0.3rem;
    // }
    .btn {
      width: 34.3rem;
      height: 4.4rem;
      background-color: #15b4fb;
      margin: 0 auto;
      margin-top: 20rem;
      border-radius: 2.75rem;
      text-align: center;
      line-height: 4.4rem;
      color: #fff;
      margin-bottom: 5rem;
    }
  }
}
</style>
