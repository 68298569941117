<template>
  <!-- 视频介绍 -->
  <div class="Video">
    <div class="Video-title">
      <van-icon
        @click="$router.push('/my')"
        name="arrow-left"
        size="2rem"/>
      <div class="title">{{$t('Video.title')}}</div>
      <div></div>
    </div>
    <div class="video" v-for="(i,v) in videolist" :key="v">
      <video :src="$imgUrl+i.url" controls loop x5-video-player-fullscreen="true" x5-video-player-type="h5"></video>
    </div>
  </div>
</template>

<script>
import { Videointroductionapi } from '@/api/index'
// import { Toast } from 'vant'
export default {
  name: 'AddRess',
  data () {
    return {
      videolist: []
    }
  },
  created () {
    this.Videointroduction()
  },
  methods: {
    // 视频介绍
    async Videointroduction () {
      const res = await Videointroductionapi()
      this.videolist = res.data
    }

  }
}
</script>

<style lang="less" scoped>
@import '../style/theme/style.less'; // 引入主题样式文件
.Video {
  background-color: rgba(@baseColor,1);
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100vw;
  min-height: 100vh;
  ::v-deep .van-icon{
    color: rgba(@apptext);
  }
  .Video-title {
     padding-top: 2.5rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: rgba(@apptext);
      font-size: 1.6rem;
    }
    .img {
      width: 1.55rem;
      height: 1.75rem;
    }
  }
  Video {
    width: 100%;
    height: 19rem;
    margin-top: 2rem;
    border-radius: 1rem;
    background-color: #2b2b2b;
  }
}
</style>
