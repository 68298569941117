<template>
  <!-- k线图 -->
  <div class="diagram">
    <div class="diagram-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        @click="$router.back()"
      />
      <div class="title">
        <span class="span">
          <img class="img" src="../../src/assets/san.png" alt="" /></span
        >{{title}}
      </div>
    </div>
    <div class="info">
      <div class="info-left">
        <div style="font-size: 3rem; font-weight: 600; color: #02D79A">
          {{ obj.open }}
        </div>
          <div v-if="parseFloat(baifb) < 0" style="color: #02D79A; font-size: 1.4rem">
            {{ baifb }}
          </div>
          <div v-else style="color: #fd1050; font-size: 1.4rem">
            +{{ baifb }}
        </div>
      </div>
      <div class="info-right">
        <div style="text-align: right;color: #333;">{{$t('diagram.height')}} {{ obj.high }}</div>
        <div style="text-align: right; margin: 1.5rem 0rem;color: #333">
          {{$t('diagram.low')}} {{ obj.low }}
        </div>
        <div style="text-align: right;color: #333">{{$t('diagram.quantity')}} {{ obj.vol }}</div>
      </div>
    </div>
    <div class="time-share">
      <div :class="[active === 1 ? 'red' : 'shi']" @click="redactive(1)">
       {{$t('diagram.min1')}}
      </div>
      <div :class="[active === 2 ? 'red' : 'shi']" @click="redactive(2)">
        {{$t('diagram.min5')}}
      </div>
      <div :class="[active === 3 ? 'red' : 'shi']" @click="redactive(3)">
        {{$t('diagram.min30')}}
      </div>
      <div :class="[active === 4 ? 'red' : 'shi']" @click="redactive(4)">
        {{$t('diagram.hour1')}}
      </div>
      <div :class="[active === 5 ? 'red' : 'shi']" @click="redactive(5)">
        {{$t('diagram.dayk')}}
      </div>
      <div :class="[active === 6 ? 'red' : 'shi']" @click="redactive(6)">
       {{$t('diagram.Weekk')}}
      </div>
    </div>
    <div class="main"></div>
    <div class="bottom">
      <div class="left" @click="torecharge">{{$t('diagram.Recharge')}}</div>
      <div class="right" @click="showcell">{{$t('diagram.purchase')}}</div>
    </div>
  </div>
</template>

<script>
import { Candlestickchartapi } from '@/api/index'
import * as echarts from 'echarts'
export default {
  name: 'diaGram',
  data () {
    return {
      ktulist: [],
      active: 1,
      // bfb: 0,
      obj: {},
      name: '',
      baifb: '', // 首页传的百分比
      title: ''
    }
  },
  async created () {
    // await this.Candlestickchart()
    this.name = this.$route.query.name
    this.baifb = this.$route.query.bfb
    // console.log(this.name)
    await this.redactive(1)
    // await this.bfbfan()
  },
  //  () {
  //   this.zhexiantu()
  // },
  methods: {
    // 分时段点击的事
    async redactive (index) {
      if (index === 2) {
        this.active = 2
        // console.log(this.active)
        await this.Candlestickchart('5min', '41')
        // this.ktulist = this.ktulist.slice(0, 41)
        await this.zhexiantu()
        // console.log(this.ktulist)
      } else if (index === 3) {
        this.active = 3
        await this.Candlestickchart('30min', '61')
        // this.ktulist = this.ktulist.slice(0, 61)
        await this.zhexiantu()
        // console.log(this.active)
        // console.log(this.ktulist)
      } else if (index === 4) {
        this.active = 4
        await this.Candlestickchart('60min', '91')
        // this.ktulist = this.ktulist.slice(0, 91)
        await this.zhexiantu()
        // console.log(this.active)
      } else if (index === 5) {
        this.active = 5
        await this.Candlestickchart('1day', '121')
        // this.ktulist = this.ktulist.slice(0, 121)
        await this.zhexiantu()
        // console.log(this.active)
      } else if (index === 6) {
        await this.Candlestickchart('1week', '201')
        // this.ktulist = this.ktulist.slice(0, 201)
        await this.zhexiantu()
        this.active = 6
        // console.log(this.active)
      } else if (index === 1) {
        this.active = 1
        // console.log(this.active)
        await this.Candlestickchart('1min', '31')
        // console.log(this.ktulist)
        // this.ktulist = this.ktulist.slice(0, 31)
        await this.zhexiantu()
      }
    },
    async Candlestickchart (time, number) {
      const data = {
        period: time,
        symbol: this.name,
        limit: number
      }
      const res = await Candlestickchartapi(data)
      // console.log('kkkkkkkkk', res)
      this.ktulist = res.data.data
      this.obj = res.data.data[0]
      console.log(res.data.data[0])
      this.title = res.data.title
      // console.log(this.ktulist)
      // this.zhexiantu()
    },
    torecharge () {
      // 去充值页面
      this.$router.push('/recharges')
    },
    showcell () {
      // 去购买页面
      this.$router.push('/gift')
    },
    zhexiantu () {
      const chartDom = document.querySelector('.main')
      const myChart = echarts.init(chartDom)
      let option = ''

      // prettier-ignore
      const rawData = this.ktulist.reverse()
      // const rawData = [['2015/12/31', '3570.47', '3539.18', '-33.69', '-0.94%', '3538.35', '3580.6', '176963664', '25403106', '-'], ['2015/12/30', '3566.73', '3572.88', '9.14', '0.26%', '3538.11', '3573.68', '187889600', '26778766', '-']]
      function calculateMA (dayCount, data) {
        const result = []
        for (let i = 0, len = data.length; i < len; i++) {
          if (i < dayCount) {
            result.push('-')
            continue
          }
          let sum = 0
          for (let j = 0; j < dayCount; j++) {
            sum += +data[i - j][1]
          }
          result.push(sum / dayCount)
        }
        return result
      }

      const dates = rawData.map(function (item) {
        const date = new Date(item.id * 1000)
        const Y = date.getFullYear() + '-'
        const M =
          (date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1) + '-'
        const D = date.getDate() + ' '
        const h = date.getHours() + ':'
        const m = date.getMinutes()
        // const s = date.getSeconds()
        // var Y = date.getFullYear() + '/';
        // var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
        // var D = date.getDate();
        item.id = Y + M + D + h + m
        return item.id
      })
      // console.log(dates)
      // console.log('ddddd', dates)
      const data = rawData.map(function (item) {
        // console.log(item)
        return [+item.open, +item.close, +item.low, +item.high]
      })
      // console.log(data)
      option = {
        // legend: {
        //   data: ['日K', 'MA5', 'MA10', 'MA20', 'MA30'],
        //   inactiveColor: '#777'
        // },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            animation: false,
            type: 'cross',
            lineStyle: {
              color: '#376df4',
              width: 1,
              opacity: 1
            }
          }
        },
        xAxis: {
          type: 'category',
          data: dates,
          axisLine: { lineStyle: { color: '#8392A5' } }
        },
        yAxis: {
          scale: true,
          axisLine: { lineStyle: { color: '#8392A5' } },
          splitLine: { show: false }
        },
        grid: {
          left: 70
        },
        series: [
          {
            type: 'candlestick',
            name: 'Day',
            data: data,
            itemStyle: {
              color: '#FD1050',
              color0: '#0CF49B',
              borderColor: '#FD1050',
              borderColor0: '#0CF49B'
            }
          },
          {
            name: 'MA5',
            type: 'line',
            data: calculateMA(5, data),
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 1
            }
          }
          // {
          //   name: 'MA10',
          //   type: 'line',
          //   data: calculateMA(10, data),
          //   smooth: true,
          //   showSymbol: false,
          //   lineStyle: {
          //     width: 1
          //   }
          // },
          // {
          //   name: 'MA20',
          //   type: 'line',
          //   data: calculateMA(20, data),
          //   smooth: true,
          //   showSymbol: false,
          //   lineStyle: {
          //     width: 1
          //   }
          // },
          // {
          //   name: 'MA30',
          //   type: 'line',
          //   data: calculateMA(30, data),
          //   smooth: true,
          //   showSymbol: false,
          //   lineStyle: {
          //     width: 1
          //   }
          // }
        ]
      }
      option && myChart.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
@import '../style/theme/style.less'; // 引入主题样式文件
.diagram {
  background-color: rgba(@baseColor,1);
  // background-image: url('../assets/suoyou.png');
  background-size: 100%  25rem;
  background-repeat:no-repeat;
  // padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
   width: 100%;
  min-height: 100vh;
  color: #15fbb5;
  ::v-deep .van-icon{
    color: rgba(@apptext);
  }
  .diagram-title {
     padding-top: 2.5rem;
    display: flex;
    .title {
      color: rgba(@apptext);
      font-size: 1.8rem;
      margin-left: 9.5rem;
      .span {
        width: 1.6rem;
        height: 1.4rem;
        margin-right: 0.6rem;
        .img {
          width: 1.6rem;
          height: 1.4rem;
          display: inline;
          vertical-align: top;
          margin-top: 0.5rem;
        }
      }
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
      margin-left: 1.6rem;
    }
  }
  .info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 3rem;
    font-size: 1.2rem;
    padding: 0rem 1.6rem;
    .info-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .time-share {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    // background-color: #fff;
    .shi {
      width: 16.66%;
      height: 3rem;
      // border-radius: 0.5rem;
      color: rgba(@apptext);
      background-color: #fff;
      color: #333;
      border: 1px solid #ccc;
      text-align: center;
      line-height: 3rem;
      // margin: 0rem .5rem;
    }
    .red {
      background-color: #333;
      color: #fff;
      width: 16.66%;
      height: 3rem;
      // border-radius: 0.5rem;
      text-align: center;
      line-height: 3rem;
      // margin: 0rem .5rem;
    }
  }
  .main {
    width: 100%;
    // left: 1.6rem;
    height: 30rem;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    margin: 0rem 1.3rem;
    margin-top: 1.75rem;
    .left,
    .right {
      width: 14.9rem;
      height: 4.4rem;
      background-color: #15b4fb;
      border-radius: 0.7rem;
      text-align: center;
      line-height: 4.4rem;
      color: #fff;
      font-size: 1.3rem;
      font-weight: 600;
    }
    .right {
      background-color: #fb8815;
    }
  }
}
</style>
