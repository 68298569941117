<template>
	<!-- 地址 -->
	<div class="Address">
		<div style="height: 100vh;overflow-y: scroll;padding: 0rem 1.6rem;">
			<div class="Address-title">
				<van-icon @click="$router.back()" name="arrow-left" size="2rem" />
				<div class="title">Fondo OPTCOIN</div>
				<div class="img">
					<!-- <img src="../../assets/jjjl.png" alt="" /> -->
				</div>
			</div>
            <div style="font-size: 1.6rem;color: #fff;margin-top: 3rem;">Contenido de introducción</div>
			<div v-if="fundobj.fund_detail" class="activect" v-html="fundobj.fund_detail">
			
			</div>
		</div>

	</div>
</template>

<script>
	import {
		getfund
	} from '@/api/index'
	import {
		Toast
	} from 'vant'
	export default {
		name: 'FUND',
		data() {
			return {
				fundobj:''
			}
		},
		created() {
			this.getfund()
		},
		methods: {
			finish() {
				this.activitylistapi()
			},
			async getfund() {
			 const res = await getfund()
			 // console.log(res,'33');
			  this.fundobj = res.data
			}
		}
	}
</script>

<style lang="less" scoped>
	@import '../../style/theme/style.less'; // 引入主题样式文件

	.Address {
		// background: url('../../assets/jjbj.png') no-repeat;
		// background-size: 100% 90vh;
		// background-position: 0rem 8rem;
		background-color: #242533;
		// padding: 0rem 1.6rem;
		// padding-top: 2.5rem;
		width: 100%;
		height: 100vh;

		::v-deep .van-icon {
			color: rgba(@apptext);
		}

		::v-deep .van-tabs__nav {
			background-color: rgba(@baseColor, 1);
		}

		.Address-title {
			padding-top: 2.5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.title {
				color: rgba(@apptext);
				font-size: 1.6rem;
			}

			.img {
				width: 1.55rem;
				height: 1.75rem;
			}
		}

		.activect {
			width: 100%;
			height: 70vh;
			background: url('../../assets/gzbj.png') no-repeat;
			background-size: 100% 100%;
            margin-top: 1.5rem;
			overflow-y: scroll;
			padding: 1.5rem;
			font-size: 1.3rem;
			color: #fff;
		}

		::v-deep .van-tab__text--ellipsis {
			font-size: 1.4rem;
		}

		.Bitcoin {
			width: 100%;
			height: 5.8rem;
			// background-color: #fff;
			border-radius: 1rem;
			margin-top: 1.5rem;
			// border: 2px solid #f6f6f6;
			background-color: rgba(@baseColor, 1);
			color: rgba(@apptext);
			font-size: 1.2rem;
			font-weight: 700;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0rem 1.5rem;
			margin-top: 2.5rem;

			.Bitcoin-left {
				display: flex;
				align-items: center;

				.Bitcoin-img {
					width: 2.84rem;
					height: 2.84rem;
					margin-right: 1rem;
					// margin-top: 0.5rem;
				}

				.Bitcoin-name {
					width: 24rem;
					margin-top: 0.3rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					-o-text-overflow: ellipsis;
				}
			}
		}
	}

	.message {
		margin-top: 1rem;
		color: red;
		font-weight: 700;
	}

	.colon {
		display: inline-block;
		margin: 0 4px;
		color: #ee0a24;
	}

	.block {
		display: inline-block;
		width: 22px;
		color: #fff;
		font-size: 12px;
		text-align: center;
		background-color: #ee0a24;
	}
</style>