<template>
  <!-- 充值选择 -->
  <div class="Recharge">
    <div class="Recharge-title">
      <van-icon
        @click="$router.push('/home')"
        name="arrow-left"
        size="2rem"
      />
      <div class="title">{{ $t("Recharge.title") }}</div>
      <div class="img" @click="torecord">
        <img src="../assets/czjl.png" alt="" v-if="jlurl == 0"/>
        <img src="../assets/czjl2.png" alt="" v-if="jlurl == 1"/>
      </div>
    </div>
    <div class="Bitcoin" v-for="(i,v) in list" :key="v" @click="tocharge(i.id,i.name,i.url,i.min,i.type)">
      <div class="Bitcoin-left">
        <div class="Bitcoin-img">
          <img src="../assets/btb.png" alt="" />
        </div>
        <div class="Bitcoin-name">
          <div style="font-size: 1.4rem">{{i.name}}</div>
          <div class="btc">{{ $t("Recharge.recharge") }}</div>
        </div>
      </div>
      <div class="Bitcoin-right">
        <img src="../assets/RightArrow.png" alt="" />
      </div>
    </div>
    <!-- 提示-->
    <div class="retishi">
      <div>*1.{{$t('addrech.tsmessyi')}}</div>
      <div>*2.{{$t('addrech.tsmesser')}}</div>
    </div>
    <!-- <div class="Bitcoin" @click="toBank">
      <div class="Bitcoin-left">
        <div class="Bitcoin-img">
          <img src="../assets/btb.png" alt="" />
        </div>
        <div class="Bitcoin-name">
          <div style="font-size: 1.4rem">Bank</div>
          <div class="btc">Recharge</div>
        </div>
      </div>
      <div class="Bitcoin-right">
        <img src="../assets/RightArrow.png" alt="" />
      </div>
    </div> -->

    <van-popup v-model="show">
      <div class="title">{{ $t("Recharge.amount") }}</div>
      <div class="popupbox">
        <div class="titles">{{ $t("Recharge.Please") }}</div>
        <input
          class="input"
          type="number"
          v-model="money"
          oninput="if(this.value.length > 6 ) this.value = this.value.slice(0,6)"
        />
      </div>
      <div class="confirm" @click="confirmshow">
        {{ $t("Recharge.confirm") }}
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { Rechargechannelapi } from '@/api/index'
export default {
  name: 'RecHarge',
  data () {
    return {
      show: false,
      money: '',
      xuanze: 'mining',
      list: [],
      id: '', // 充值通道的id
      url: '', // 充值通道的地址
      jlurl: 0
      // min: '' // usdt最小金额
    }
  },
  created () {
    if (localStorage.getItem('theme') == 'dark') {
      this.jlurl = 1
      // console.log(this.kfurlqh)
    } else {
      this.jlurl = 0
    }
    this.xuanze = this.$route.query.entrance?this.$route.query.entrance:this.xuanze
    console.log('666', this.xuanze)
    this.Rechargechannel()
  },
  methods: {
    // 充值通道
    async Rechargechannel () {
      const res = await Rechargechannelapi()
      console.log('6546', res)
      this.list = res.data
    },
    // 去充值记录
    torecord () {
      this.$router.push('/record')
    },
    // 去充值页面
    tocharge (id, title, url, min, type) {
      // console.log(id)
      // console.log(min)
      this.id = id
      this.url = url
      if (parseFloat(type) === 1) { /// USDT
        this.show = true
        this.money = ''
      } else { // 法币
        // console.log('选择', this.url)
        this.$router.push({
          path: '/bankcard',
          query: {
            id,
            url,
            min,
            type,
			entrance: this.xuanze
          }
        })
      }
      // if (parseFloat(id) === 3) {

      // }
    },
    // 去银行卡充值
    // toBank () {
    //  this.$router.push('/bankcard')
    // },
    // usd充值操作
    async confirmshow () {
      if (parseFloat(this.money) >= this.list[0].min) {
        if (parseFloat(this.money) <= 0 || this.money.trim() === '') {
          Toast({
            message: this.$t('Recharge.less'),
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        } else {
          if (parseFloat(this.money) > 5000) {
            Toast({
              message: this.$t('addrech.tsyi'),
              duration: 1200,
              className: 'noticeWidth' // 所加样式名
            })
          } else {
            Toast({
              message: this.$t('Recharge.success'),
              duration: 1200
              // className: 'noticeWidth' // 所加样式名
            })
            setTimeout(() => {
              this.show = false
              this.$router.push({
                path: '/charge',
                query: {
                  money: this.money,
                  entrance: this.xuanze,
                  id: this.id
                }
              })
            }, 2000)
          }
        }
      } else {
        Toast({
          message:  this.$t('addrech.tser') + this.list[0].min ,
          duration: 1200
          // className: 'noticeWidth' // 所加样式名
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../style/theme/style.less'; // 引入主题样式文件
.Recharge {
  // background: url('../assets/ampbk.png') no-repeat;
  // background-size: cover;
  background-color: rgba(@baseColor,1);
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  ::v-deep .van-icon{
    color: rgba(@apptext);
  }
  .Recharge-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: rgba(@apptext);
      font-size: 1.6rem;
    }
    .img {
      width: 1.55rem;
      height: 1.75rem;
    }
  }
  .Bitcoin {
    width: 100%;
    height: 5.8rem;
    // background-color: #F4F6F8;
    // border-radius: 1rem;
    margin-top: 1.5rem;
    color: rgba(@apptext);
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #F1F1F1;
    align-items: center;
    padding: 0rem 1.5rem;
    margin-top: 2.5rem;
    .Bitcoin-left {
      display: flex;
      .Bitcoin-img {
        width: 2.84rem;
        height: 2.84rem;
        margin-right: 1rem;
        margin-top: 0.5rem;
      }
      .Bitcoin-name {
        .btc {
          color: #ABADAE;
          margin-top: 0.4rem;
        }
      }
    }
    .Bitcoin-right {
      width: 0.9rem;
      height: 1rem;
      margin-bottom: 1rem;
    }
  }
  ::v-deep .van-popup {
    width: 32rem;
    border-radius: 1rem;
    padding: 0rem 1.5rem;
    padding-bottom: 3rem;
    padding-top: 3rem;
    background-color: rgba(255, 255, 255, 0.8);
    .title {
      text-align: center;
      font-size: 1.3rem;
      font-weight: 600;
      margin-bottom: 3rem;
    }
    .popupbox {
      width: 100%;
      .input {
        width: 100%;
        height: 4rem;
        // margin-left: 50%;
        // transform: translateX(-50%);
        // margin: 0 auto;
        margin: 2rem 0rem;
        text-align: center;
        color: #000;
        // background-color: #060606;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 0.5rem;
      }
    }
    .confirm {
      width: 100%;
      height: 4.4rem;
      background-color: #15b4fb;
      margin: 0 auto;
      border-radius: 1rem;
      text-align: center;
      line-height: 4.4rem;
      font-size: 1.4rem;
      color: #fff;
      font-weight: 600;
    }
  }
  .retishi{
    margin-top: 10rem;
    div{
      margin-bottom: 1rem;
      color: red;
    }
  }
}
</style>
