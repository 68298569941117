<template>
	<!-- 地址 -->
	<div class="Address">
		<div style="height: 100vh;overflow-y: scroll;padding: 0rem 1.6rem;padding-bottom: 6rem;">
			<div class="Address-title">
				<van-icon @click="$router.back()" name="arrow-left" size="2rem" />
				<div class="title">Fondo OPTCOIN</div>
				<div class="img" @click="tpjl()">
					<img src="../../assets/jjjl.png" alt="" />
				</div>
			</div>

			<div class="activect">
				<div class="xian"></div>
				<div class="jjyx">
					<img src="../../assets/jjyx.png" alt="" />
					<div class="dwbox">
						<div style="color: #A7A7A7;">Garantía</div>
						<div style="font-size: 2.2rem;margin: .8rem 0rem;">
							{{ parseFloat(fundobj.total_money).toFixed(2)}}
						</div>
						<div>USDT</div>
					</div>
				</div>
				<div class="zsbox">
					<div class="leftbox" style="margin-right: 2rem;">
						<div style="color: #41D6A5;margin-bottom: .4rem;font-size: 1.3rem;">
							{{parseFloat(fundobj.day_profit).toFixed(2)}} OPT
						</div>
						<div>ingreso diario</div>
					</div>
					<div class="leftbox">
						<div style="color: #41D6A5;margin-bottom: .4rem;font-size: 1.3rem;">
							{{parseFloat(fundobj.total_profit).toFixed(2) }} OPT
						</div>
						<div>Ingresos acumulados</div>
					</div>
				</div>

				<div class="ckgz" @click="togz()">Introducción a OPTcoin</div>
				<div class="xian" style="margin: 2rem 0rem;"></div>

				<div class="infobox" v-for="(i,v) in fundobj.list" :key="v">
					<div class="infotop">
						<div class="infotit">
							<div class="infotx">
								<img :src="i.img" alt="" />
							</div>
							<div>{{i.name}}</div>
						</div>
						<div class="infoxq" @click="qhxqshow(i)">Detalles</div>
					</div>
					<div class="infocen">
						<div class="infoitem">
							<div style="color: #41D6A5;">{{i.bili}}(OPT)</div>
							<div>ingreso diario</div>
						</div>
						<div class="infoitem">
							<div style="color: #EFBB0B;">{{i.days}} días</div>
							<div>el término</div>
						</div>
						<div class="infoitem">
							<div style="color: #EFBB0B;">{{i.price}}(USDT）</div>
							<div>Garantía</div>
						</div>
					</div>
					<div style="margin-top: -.5rem;margin-bottom: 1rem;color: #fff;font-size: 1.1rem;">Límite de compra:
						{{i.limit_buy}}
					</div>
					<div class="infobtn" @click="qrbuy(i.id)">Garantía</div>
				</div>
			</div>
		</div>

		<!-- <div style="display: flex;align-items: center;position: fixed;left: 0;bottom: 0;width: 100%;background: linear-gradient(225deg, #19191B 0%, #23233B 53%, #161618 100%);">
			<div style="background-color: #ee822f;border-radius: .5rem;width: 9rem;height: 4rem;text-align: center;padding-top: .5rem;" @click="$router.push('/recharges?entrance=yuebao')">{{$t('jjbuy.title11')}}</div>
			<div style="background-color: #92d050;border-radius: .5rem;margin-left: 1.5rem;width: 11rem;height: 4rem;text-align: center;line-height: 1.1;padding-top: .5rem;" @click="getback_money()">{{$t('jjbuy.title12')}}</div>
		</div> -->

		<div
			style="position: fixed;bottom: 0;left: 0;width: 100%;background: linear-gradient(225deg, #19191B 0%, #23233B 53%, #161618 100%);display: flex;justify-content: space-between;align-items: center;padding:1.6rem;font-size: 1.4rem;color: #fff;">
			<div>
				<span style="white-space:nowrap;">saldo disponible</span>：{{parseFloat(fundobj.money).toFixed(2)}}
			</div>
			<div style="display: flex;align-items: center;">
				<div style="background-color: #ee822f;border-radius: .5rem;width: 9rem;height: 4rem;text-align: center;display: flex; flex-direction：column;justify-content: center;align-items: center;"
					@click="$router.push('/recharges?entrance=fund')">completar</div>
				<div style="background-color: #92d050;border-radius: .5rem;margin-left: 1.5rem;width: 11rem;height: 4rem;text-align: center;line-height: 1.1;display: flex; flex-direction：column;justify-content: center;align-items: center;"
					@click="getback_money()">Reembolso de saldo</div>
			</div>
		</div>

		<van-popup v-model="show" overlay-class="zzcbox">
			<div class="pupbox">
				<h3 style="text-align: center;margin-bottom: 2rem;">Garantía</h3>
				<div class="gb" @click="close()">
					<img src="../../assets/jjgb.png" alt="" />
				</div>
				<div>Ingrese la cantidad</div>
				<input class="money" type="text" v-model="money" />
				<div style="color: #959494;margin-top: -.8rem;margin-bottom: 1rem;font-size: 1.2rem;">saldo
					disponible：{{fundobj.money}} OPT</div>
				<!-- <div>输入密码</div>
				<input class="money" type="text" v-model="password" /> -->

				<div class="qebtn" @click="qrbuy()">Seguro</div>
			</div>
		</van-popup>

		<van-popup v-model="xqshow" overlay-class="zzcbox">
			<div class="xqpupbox">
				<h3 style="text-align: center;margin-bottom: 2rem;">Detalles</h3>
				<div class="xqinfo" v-html="xqhtml"></div>
			</div>
		</van-popup>

		<van-dialog v-model="tsshow" title="" show-cancel-button confirmButtonText='Confirmar'
			cancelButtonText="Cancelar" @confirm='confirm'>
			<div style="text-align: center;padding: 1.6rem;">
				<!-- <div>¿Estás seguro de comprar el producto modificado?</div> -->
				<div style="color: #959494;margin-bottom: 1rem;font-size: 1.2rem;margin-top: 1rem;">
					saldo disponible：{{parseFloat(fundobj.money).toFixed(2)}} USDT</div>
			</div>
		</van-dialog>

	</div>
</template>

<script>
	import {
		getfund,
		buyfund,
		backfundls
	} from '@/api/index'
	import {
		Toast
	} from 'vant'

	export default {
		name: 'FUND',
		data() {
			return {
				show: false,
				xqshow: false,
				money: '',
				password: '',
				fundobj: {},
				xqhtml: '',
				buyid: '',
				buyindex: 1,
				tsshow: false
			}
		},
		created() {
			this.getfund()
		},
		methods: {
			togz() {
				this.$router.push({
					path: '/FundRule'
				})
			},
			showPopup(id) {
				// this.money = ''
				this.buyid = id
				this.show = true
			},
			close() {
				this.show = false
			},
			qhxqshow(item) {
				this.xqhtml = item.detail
				this.xqshow = true
			},
			tpjl() {
				this.$router.push({
					path: '/FundHistory'
				})
			},
			async getfund() {
				const res = await getfund()
				// console.log(res,'33');
				this.fundobj = res.data
			},
			async buyfund() {
				let data = {
					id: this.buyid
				}
				const res = await buyfund(data)
				// console.log(res,'33');
				Toast({
					message: res.msg,
					duration: 2000,
					className: 'noticeWidth' // 所加样式名
				})
				if (res.code == 1) {
					setTimeout(() => {
						this.show = false
						this.getfund()

					}, 1000)

				}
				setTimeout(() => {
					this.buyindex = 1
				}, 1500)
			},
			confirm() {
				if (this.buyindex == 1) {
					this.buyindex = 2
					this.buyfund()
				}
			},
			qrbuy(id) {
				this.tsshow = true
				this.buyid = id
			},
			async getback_money() {
				const res = await backfundls()
				// console.log(res);
				Toast({
					message: res.msg,
					duration: 2000,
					className: 'noticeWidth' // 所加样式名
				})
				if(res.code==1) {
					setTimeout(()=>{
						this.$router.go(0)
					},1200)
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	@import '../../style/theme/style.less'; // 引入主题样式文件

	.Address {
		background: url('../../assets/jjbj.png') no-repeat;
		background-size: 100% 90vh;
		background-position: 0rem 8rem;
		background-color: #242533;
		// padding: 0rem 1.6rem;
		// padding-top: 2.5rem;
		width: 100%;
		height: 100vh;

		::v-deep .van-icon {
			color: #fff;
		}

		::v-deep .zzcbox {
			background-color: rgba(39, 38, 38, .7) !important;
		}

		::v-deep .van-tabs__nav {
			background-color: rgba(@baseColor, 1);
		}

		::v-deep .van-popup {
			background-color: #060605;
			border-radius: 1.5rem;
		}

		.Address-title {
			padding-top: 2.5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			// padding-bottom: 16rem;

			.title {
				color: #fff;
				font-size: 1.6rem;
			}

			.img {
				width: 1.55rem;
				height: 1.75rem;
			}
		}

		.pupbox,
		.xqpupbox {
			width: 32rem;
			// height: 30rem;
			background-color: #060605;
			position: relative;
			border-radius: 1.5rem;
			color: #fff;
			font-size: 1.3rem;
			padding: 1.5rem;

			.gb {
				width: 1.5rem;
				height: 1.5rem;
				position: absolute;
				top: 1.5rem;
				right: 1.5rem;
			}

			.money {
				width: 100%;
				background-color: #060605;
				border-radius: .7rem;
				border: 1px solid #707070;
				padding: 1rem;
				margin: 1.5rem 0rem;
			}

			.qebtn {
				width: 100%;
				height: 4rem;
				text-align: center;
				line-height: 4rem;
				background-color: #EFBB0B;
				border-radius: .8rem;
				margin-top: 2.5rem;
				margin-bottom: 1rem;
				color: #000;
				font-weight: 600;
			}
		}

		.xqpupbox {

			.xqinfo {
				height: 36vh;
				overflow-y: scroll;
			}
		}

		.activect {
			margin-top: 3rem;
			margin-bottom: 3rem;

			.jjyx {
				width: 18rem;
				height: 18rem;
				margin: 0 auto;
				position: relative;
				color: #41D6A5;

				img {
					width: 100%;
					height: 100%;
				}

				.dwbox {
					width: 18rem;
					height: 18rem;
					position: absolute;
					top: 0rem;
					left: 0rem;
					text-align: center;
					padding-top: 5.5rem;
					font-size: 1.3rem;
				}
			}

			.zsbox {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 2rem;

				.leftbox {
					background: #242533;
					display: inline-block;
					border-radius: 1.2rem;
					border: 1px solid #707070;
					padding: .5rem 3rem;
					color: #A7A7A7;
					font-size: 1.2rem;
					text-align: center;
					flex: 1;
					min-height: 6rem;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;
					// line-height: 6;
				}
			}

			.ckgz {
				width: 80%;
				height: 4rem;
				text-align: center;
				margin-left: 10%;
				line-height: 4rem;
				background: #EFBB0B;
				box-shadow: 0px 3px 6px 1px rgba(89, 121, 251, 0.2);
				border-radius: .5rem;
				margin-top: 2.5rem;
				font-weight: 600;
				font-size: 1.2rem;
			}

			.xian {
				width: 100%;
				height: 1px;
				background: linear-gradient(90deg, #242533 0%, #515158 49%, #242533 100%);
				margin: 1rem 0rem;
			}

			.infobox {
				background: linear-gradient(225deg, #19191B 0%, #23233B 53%, #161618 100%);
				border: 1px solid #707070;
				padding: 1.5rem;
				border-radius: 1rem;
				margin-bottom: 1.5rem;

				.infotop {
					display: flex;
					align-items: center;
					justify-content: space-between;

					.infoxq {
						display: inline-block;
						padding: .5rem 1.8rem;
						background-color: #0BEFB9;
						font-size: 1.2rem;
						border-radius: .5rem;
					}

					.infotit {
						display: flex;
						align-items: center;
						font-size: 1.3rem;
						color: #fff;

						.infotx {
							width: 4rem;
							height: 4rem;
							background-color: #fff;
							border-radius: .5rem;
							margin-right: .8rem;

							img {
								width: 100%;
								height: 1005;
								border-radius: .5rem;
							}
						}
					}
				}

				.infocen {
					display: flex;
					justify-content: space-between;
					align-items: center;
					border-radius: 4px 4px 4px 4px;
					border: 1px solid #363434;
					padding: 1rem 0rem;
					margin: 1.5rem 0rem;

					.infoitem {
						width: 33.33%;
						text-align: center;
						line-height: 1.8;
						color: #A7A7A7;
						font-size: 1.2rem;
					}
				}

				.infobtn {
					width: 100%;
					height: 3rem;
					text-align: center;
					line-height: 3rem;
					background-color: #EFBB0B;
					border-radius: .5rem;
					font-size: 1.2rem;
					font-weight: 600;
					color: #000;
				}
			}
		}

		::v-deep .van-tab__text--ellipsis {
			font-size: 1.4rem;
		}

		.Bitcoin {
			width: 100%;
			height: 5.8rem;
			// background-color: #fff;
			border-radius: 1rem;
			margin-top: 1.5rem;
			// border: 2px solid #f6f6f6;
			background-color: rgba(@baseColor, 1);
			color: rgba(@apptext);
			font-size: 1.2rem;
			font-weight: 700;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0rem 1.5rem;
			margin-top: 2.5rem;

			.Bitcoin-left {
				display: flex;
				align-items: center;

				.Bitcoin-img {
					width: 2.84rem;
					height: 2.84rem;
					margin-right: 1rem;
					// margin-top: 0.5rem;
				}

				.Bitcoin-name {
					width: 24rem;
					margin-top: 0.3rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					-o-text-overflow: ellipsis;
				}
			}
		}
	}

	.message {
		margin-top: 1rem;
		color: red;
		font-weight: 700;
	}

	.colon {
		display: inline-block;
		margin: 0 4px;
		color: #ee0a24;
	}

	.block {
		display: inline-block;
		width: 22px;
		color: #fff;
		font-size: 12px;
		text-align: center;
		background-color: #ee0a24;
	}
</style>