<template>
  <!-- 大转盘抽奖 -->
  <div class="turntable">
    <div class="turntable-title">
      <van-icon
        @click="$router.back()"
        name="arrow-left"
        size="2rem"
        color="#fff"
      />
    </div>
    <div class="remainingbox">
      <div class="remaining">
        <div class="remaining-img">
          <img src="../assets/turntabl.png" alt="" />
        </div>
        <div class="start-time">
          <div>{{$t('addturn.start')}}</div>
          <!-- <div style="color: #fa9803; font-size: 1.5rem">{{starttime ? starttime :'00:00:00'}}</div> -->
          <!-- <van-count-down :time="starttime" style="color: #fa9803;"></van-count-down> -->
        </div>
      </div>
      <!-- <div class="problen" v-if="parseFloat(Atividade)===0">{{$t('addturn.problenyi')}}</div> -->
      <div class="problens" v-if="parseFloat(Atividade)===0">{{$t('addturn.problener')}}<br />{{$t('addturn.problenerer')}}<br />{{$t('addturn.problenersan')}}</div>
      <!-- <div class="problen" v-if="parseFloat(Atividade)===1">A atividade da plataforma giratória está ativada</div> -->
      <div class="problens" v-if="parseFloat(Atividade)===1">{{$t('addturn.problener')}}<br />{{$t('addturn.problenerer')}}<br />{{$t('addturn.problenersan')}}</div>
      <!-- <div class="problen" v-if="parseFloat(Atividade)===3">{{$t('addturn.problensan')}}</div> -->
      <div class="problens" v-if="parseFloat(Atividade)===3">{{$t('addturn.problener')}}<br />{{$t('addturn.problenerer')}}<br />{{$t('addturn.problenersan')}}</div>
      <!-- <div class="problen" v-if="parseFloat(Atividade)===4">{{$t('addturn.problensi')}}</div> -->
      <div class="problens" v-if="parseFloat(Atividade)===4">{{$t('addturn.problener')}}<br />{{$t('addturn.problenerer')}}<br />{{$t('addturn.problenersan')}}</div> <!-- problens-->
    </div>
    <div class="content">
      <div class="content-box">
        <div>
          <van-swipe
          class="my-swipe"
          :autoplay="2500"
          :touchable="false"
          indicator-color="white"
          :show-indicators="false">
          <van-swipe-item style="text-align: center !important; background-color: #b94a1a; color:#fff; height: 3rem; line-height: 3rem; " v-for="(i, v) in swipelist" :key="v">
            {{i}}
          </van-swipe-item>
        </van-swipe>
        </div>
        <div class="top">
          <div class="topbox">
              <div class="points" style="font-size: 1.1rem;">{{$t('addturn.topboxyi')}}</div> <!-- : {{ integrante }}-->
              <div class="Saldo">{{$t('addturn.topboxer')}}: {{ integrante }}</div> <!-- member-->
              <div class="Saldo">{{$t('addturn.topboxsan')}}: {{count}}</div>
            </div>
          <div class="giftimg" ref="giftimg">
            <img src="../assets/gift.6cfa3773.png" alt="" />
          </div>
        </div>
        <!-- <div class="zhuanpan">
          <div class="neirong">
            <img ref="neirong" src="../assets/neirong.png" alt="">
          </div>
          <div class="kaishi" @click="kaishizhuanpan">
            <img src="../assets/kais.png" alt="">
          </div>
          <div class="zhizhen">
            <img src="../assets/zhizhen.png" alt="">
          </div>
        </div> -->
        <!-- 转盘抽奖 -->
        <div class="box">
          <LuckyWheel
            class="Lucky"
            ref="myLucky"
            :prizes="prizes"
            :blocks="blocks"
            :buttons="buttons"
            @start="startCallback"
            @end="endCallback"/>
          <div class="zhizhen">
            <img src="../assets/zhizhen.png" alt="" />
          </div>

        </div>
        <!-- <div style="padding: 1.3rem;">O sorteio da loteria giratória consumirá os pontos primeiro e mudará automaticamente para o saldo quando os pontos forem insuficientes</div> -->
      </div>
    </div>
    <div class="button">
      <div class="btn" @click="$router.push('/lotteryrecord')">{{$t('addturn.btn')}}</div>
    </div>
  </div>
</template>

<script>
import { turntablelistapi, Turntablestartapi } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'turnTable',
  data () {
    return {
      time: null,
      time1: null,
      time2: null,
      start: true,
      prizes: [
        { imgs: [{ src: require('../assets/Venus.png'), top: '125%', left: '10%', width: '20%' }], fonts: [{ text: '', top: '80%',left: '20%', fontSize: '0.8rem',fontWeight: '700' }] },
        { imgs: [{ src: require('../assets/Venus.png'), top: '125%', left: '10%', width: '20%' }], fonts: [{ text: '', top: '80%',left: '20%', fontSize: '0.8rem',fontWeight: '700' }] },
        { imgs: [{ src: require('../assets/Venus.png'), top: '125%', left: '10%', width: '20%' }], fonts: [{ text: '', top: '80%',left: '20%', fontSize: '0.8rem',fontWeight: '700' }] },
        { imgs: [{ src: require('../assets/Venus.png'), top: '125%', left: '10%', width: '20%' }], fonts: [{ text: '', top: '80%',left: '20%', fontSize: '0.8rem',fontWeight: '700' }] },
        { imgs: [{ src: require('../assets/Venus.png'), top: '125%', left: '10%', width: '20%' }], fonts: [{ text: '', top: '80%',left: '20%', fontSize: '0.8rem',fontWeight: '700' }] },
        { imgs: [{ src: require('../assets/Venus.png'), top: '125%', left: '10%', width: '20%' }], fonts: [{ text: '', top: '80%',left: '20%', fontSize: '0.8rem',fontWeight: '700' }] },
        { imgs: [{ src: require('../assets/Venus.png'), top: '125%', left: '10%', width: '20%' }], fonts: [{ text: '', top: '80%',left: '20%', fontSize: '0.8rem',fontWeight: '700' }] },
        { imgs: [{ src: require('../assets/Venus.png'), top: '125%', left: '10%', width: '20%' }], fonts: [{ text: '', top: '80%',left: '20%', fontSize: '0.8rem',fontWeight: '700' }] },
        { imgs: [{ src: require('../assets/Venus.png'), top: '125%', left: '10%', width: '20%' }], fonts: [{ text: '', top: '80%',left: '20%', fontSize: '0.8rem',fontWeight: '700' }] }
      ],
      buttons: [
        {
          radius: '50%',
          imgs: [{
            src: require('../assets/kais.png'),
            width: '50%',
            top: '-50%'
          }]
        }
      ],
      blocks: [
        {
          padding: '1rem',
          imgs: [{
            src: require('../assets/zhuanpan.png'),
            width: '85%',
            // height: '100%'
            top: '8%'
          }]
        },
        {
          imgs: [{
            src: require('../assets/neirong.png'),
            width: '75%',
            top: '12.5%',
            rotate: true
          }]
        }
      ],
      integrante: 0, // 抽奖积分
      list: [],
      starttime: 0,
      id: '', // 奖品id
      play_score: '',
      startgame: 1,
      Atividade: '', // 活动开始或关闭
      member: '', // 账户可用余额
      lotterytype: 1, // 抽奖类型判断是积分抽奖还是余额抽奖
      robot: '', // 判断有没有买机器人
      jifen: '', // 积分抽奖
      balance: '', // 余额抽奖
      count: '', // 抽奖次数
      swipelist: []
    }
  },
  created () {
    this.turntablelist()
  },
  mounted () {
    this.giftimg()
  },
  destroyed () {
    clearInterval(this.time)
    clearInterval(this.time1)
    clearInterval(this.time2)
  },
  methods: {
    // 获取是否开启
    async startCallback () {
      const res = await turntablelistapi()
      this.jifen = res.data.score_on
      this.balance = res.data.balance_on
      this.addchoujiang()
    },
    async addchoujiang () {
      console.log(this.Atividade)
      // this.Atividade = 1
      if (this.Atividade) {// 活动开启状态 && this.starttime>0
        if (parseFloat(this.robot) === 1) { // 判断有没有购买机器人
          if (parseFloat(this.balance) === 1 && parseFloat(this.jifen) === 1) { // 积分抽奖和余额抽奖都开启状态
            if (this.lotterytype === 1) { // 优先消耗积分
              if (this.integrante >= 20) {
                if (this.startgame === 1) {
                  this.startgame = 2
                  // if (this.integrante === 0 || this.integrante < 10) {
                  //   // console.log(1)
                  //   Toast('积分不足')
                  // } else {
                  // this.integrante -= 10
                  // 调用抽奖组件的play方法开始游戏
                  await this.$refs.myLucky.play()
                  // 模拟调用接口异步抽奖
                  await this.Turntablestart(1)

                  // 假设后端返回的中奖索引是0
                  // console.log(index)
                  // 调用stop停止旋转并传递中奖索引
                  console.log('2133', this.id)
                  await this.$refs.myLucky.stop(this.id)
                  await setTimeout(() => {
                    this.turntablelist()
                    this.startgame = 1
                  }, 6000)
                }
              } else {
                Toast({
                  message: this.$t('addturn.tsyi'),
                  duration: 1200
                // className: 'noticeWidth' // 所加样式名
                })
                this.lotterytype = 2
              }
            } else {
              if (this.member >= 5) {
                if(this.integrante >= 20) {
                   this.lotterytype = 1
                   this.addchoujiang()
                   return
                }
                if (this.startgame === 1) {
                  this.startgame = 2
                  // if (this.integrante === 0 || this.integrante < 10) {
                  //   // console.log(1)
                  //   Toast('积分不足')
                  // } else {
                  // this.integrante -= 10

                  // 调用抽奖组件的play方法开始游戏
                  await this.$refs.myLucky.play()
                  // 模拟调用接口异步抽奖
                  await this.Turntablestart(2)

                  // 假设后端返回的中奖索引是0
                  // console.log(index)
                  // 调用stop停止旋转并传递中奖索引
                  console.log('45676', this.id)
                  await this.$refs.myLucky.stop(this.id)
                  // this.lotterytype = 1
                  await setTimeout(() => {
                    this.turntablelist()
                    this.startgame = 1
                  }, 6000)
                }
              } else {
                Toast({
                  message: this.$t('addturn.tser'),
                  duration: 1200
                // className: 'noticeWidth' // 所加样式名
                })
              }
            }
          } else if (parseFloat(this.jifen) === 1) { // 积分抽奖开启状态
            if (this.integrante >= 20) {
              if (this.startgame === 1) {
                this.startgame = 2
                // if (this.integrante === 0 || this.integrante < 10) {
                //   // console.log(1)
                //   Toast('积分不足')
                // } else {
                // this.integrante -= 10
                // 调用抽奖组件的play方法开始游戏
                await this.$refs.myLucky.play()
                // 模拟调用接口异步抽奖
                await this.Turntablestart(1)

                // 假设后端返回的中奖索引是0
                // console.log(index)
                // 调用stop停止旋转并传递中奖索引
                console.log('2133', this.id)
                await this.$refs.myLucky.stop(this.id)
                await setTimeout(() => {
                  this.turntablelist()
                  this.startgame = 1
                }, 6000)
              }
            } else {
              Toast({
                message: this.$t('addturn.tssan'),
                duration: 1200
                // className: 'noticeWidth' // 所加样式名
              })
            }
          } else if (parseFloat(this.balance) === 1) { // 余额抽奖开启状态
            if (this.member >= 5) {
              if (this.startgame === 1) {
                this.startgame = 2
                // if (this.integrante === 0 || this.integrante < 10) {
                //   // console.log(1)
                //   Toast('积分不足')
                // } else {
                // this.integrante -= 10

                // 调用抽奖组件的play方法开始游戏
                await this.$refs.myLucky.play()
                // 模拟调用接口异步抽奖
                await this.Turntablestart(2)

                // 假设后端返回的中奖索引是0
                // console.log(index)
                // 调用stop停止旋转并传递中奖索引
                console.log('45676999', this.id)
                await this.$refs.myLucky.stop(this.id)
                // this.lotterytype = 1
                await setTimeout(() => {
                  this.turntablelist()
                  this.startgame = 1
                }, 6000)
              }
            } else {
              Toast({
                message: this.$t('addturn.tssi'),
                duration: 1200
                // className: 'noticeWidth' // 所加样式名
              })
            }
          } else {
            Toast({
              message: this.$t('addturn.tswu'),
              duration: 1200
              // className: 'noticeWidth' // 所加样式名
            })
          }
        } else {
          Toast({
            message: this.$t('addturn.tsliu'),
            duration: 1200
            // className: 'noticeWidth' // 所加样式名
          })
        }
      } else {
        // console.log(111)
        Toast({
          message: this.$t('addturn.tsqi'),
          duration: 1200
          // className: 'noticeWidth' // 所加样式名
        })
      }
    },
    async turntablelist () {
      const res = await turntablelistapi()
      console.log(res)
      // if (res.data.member_score >= 20) {
      //   this.lotterytype = 1
      // } else {
      //   this.lotterytype = 2
      // }
      this.list = res.data.lucky_history
      this.integrante = res.data.member_score
      this.starttime = res.data.countdown*1000
      console.log(this.starttime)
      this.play_score = res.data.play_score
      this.Atividade = res.data.is_on
      this.robot = res.data.miner_on
      this.count = res.data.count
      this.jifen = res.data.score_on
      this.balance = res.data.balance_on
      this.swipelist = res.data.notice
      if (parseFloat(this.jifen) === 1 && parseFloat(this.balance) === 1) {
        // console.log('111', this.jifen)
        // console.log('222', this.balance)
        this.Atividade = 1
      } else if (parseFloat(this.jifen) === 1 || parseFloat(this.balance) === 1) { // 判断是积分或是余额开启
        if (parseFloat(this.jifen) === 1) { // 积分抽奖开启
          // console.log('333', this.jifen)
          this.Atividade = 3
        } else if (parseFloat(this.balance) === 1) { // 余额抽奖开启
          this.Atividade = 4
          // console.log('444', this.balance)
        }
      }
      // console.log(this.Atividade)
      this.member = parseFloat(res.data.member_balance).toFixed(2)
      res.data.prize_list.forEach((i, index) => {
        // console.log(i.name)
        this.prizes[index].fonts[0].text = i.name
        if (i.image) {
          this.prizes[index].imgs[0].src = i.image
        } else {
          this.prizes[index].imgs[0].src = require('../assets/Venus.png')
        }
      })
    },
    giftimg () {
      this.time = setInterval(() => {
        this.time1 = setTimeout(() => {
          this.$refs.giftimg.style.transform = 'scale(1.3)'
        }, 600)
        this.time2 = setTimeout(() => {
          this.$refs.giftimg.style.transform = 'scale(1)'
        }, 800)
      }, 800)
    },
    // 点击抽奖按钮会触发star回调
    // async startCallback () {
    //   // this.jifen = ''
    //   // this.balance = ''
    //   // await this.turntablelist()
    //   this.getinfo()

    //   // }
    // },
    // 抽奖结束会触发end回调
    endCallback (prize) {
      console.log(prize, '回调')
    },
    async Turntablestart (type) {
      const data = {
        cost: type
      }
      const res = await Turntablestartapi(data)
      console.log(res, 'res')
      this.id = parseFloat(res.data.won_id) - 1
      if (res.code === 222) {
        setTimeout(() => {
          Toast({
            message: res.msg,
            duration: 1200
            // className: 'noticeWidth' // 所加样式名
          })
        }, 5000)
        return
      }
      console.log(this.id, 'id')
      if (res.code === 1) {
        setTimeout(() => {
          Toast({
            message: res.msg,
            duration: 1200
            // className: 'noticeWidth' // 所加样式名
          })
        }, 5000)
      } else {
        Toast({
          message: res.msg,
          duration: 1200
          // className: 'noticeWidth' // 所加样式名
        })
      }
    }

  }
}
</script>

<style lang="less" scoped>
.turntable {
  position: relative;
  // padding-top: 2.5rem;
  width: 100%;
  height: 46rem;
  background: url("../assets/beijing.png") no-repeat;
  background-size: 100% 46rem;
  .turntable-title {
    padding-top: 2.5rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ::v-deep .van-icon-arrow-left:before {
    margin-left: 1.6rem;
  }
  .remainingbox {
    width: 90%;
    height: 12rem;
    // background-color: red;
    position: absolute;
    top: 32rem;
    left: 50%;
    transform: translateX(-50%);
    .remaining {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 4rem;
      padding-bottom: 0;
      padding-left: 1rem;
      .remaining-img {
        width: 4rem;
        height: 4rem;
        margin-left: 1rem;
      }
      .start-time {
        width: 100%;
        // margin-left: 1rem;
        font-size: 1.7rem;
        color: #67beaf;
        font-weight: 800;
        text-align: center;
      }
    }
    .problen,
    .problens {
      width: 70%;
      // height: 4rem;
      // text-align: center;
      margin-left: 50%;
      margin-top: 1rem;
      transform: translateX(-50%);
      // line-height: 4rem;
      color: rgb(250, 152, 3);
      border-radius: 1rem;
      font-size: 1rem;
      // font-weight: 700;
    }
    .problens {
      width: 100%;
      padding: 0rem 1.5rem;
      padding-left: 3.5rem;
      margin-top:-.1rem;
    }
  }
  .content {
    width: 100%;
    height: 46.2rem;
    background: linear-gradient(rgb(155, 233, 211), rgb(66, 93, 88));
    margin-top: 39.3rem;
    padding: 1rem;
    .content-box {
      width: 100%;
      height: 100%;
      border: 0.6rem solid transparent;
      border-radius: 1.5rem;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(180deg, #f5e173, #f1be4e),
        linear-gradient(180deg, #ffefba, #e17434);
      .top {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        .topbox {
          // display: flex;
         .points {
          padding: 0.4rem;
          // height: 2.2rem;
          font-size: 1.3rem;
          background-color: #b94a1a;
          color: #fff;
          border-radius: 0.5rem;
        }
        .Saldo {
          padding: 0.5rem;
          // height: 2.2rem;
          font-size: 1.3rem;
          background-color: #b94a1a;
          color: #fff;
          border-radius: 0.5rem;
          margin-top: .6rem;
          margin-bottom: .5rem;
        }
        }
        .giftimg {
          width: 3rem;
          height: 3rem;
          transition: 0.6s all ease-out;
        }
      }
      .zhuanpan {
        width: 72%;
        height: 29rem;
        margin-left: 50%;
        transform: translateX(-50%);
        margin-top: -0.5rem;
        background-image: url("../assets/zhuanpan.png");
        background-size: 100% 100%;
        position: relative;
        .neirong {
          width: 79%;
          margin-left: 50%;
          transform: translateX(-50%);
          // margin-top: 2.5rem;
          img {
            margin-top: 2.6rem;
            transition: 3s all;
          }
        }
        .kaishi {
          width: 6rem;
          height: 6rem;
          position: absolute;
          top: 9.5rem;
          left: 50%;
          transform: translate(-50%);
        }
        .zhizhen {
          width: 4rem;
          position: absolute;
          top: 0rem;
          left: 55%;
          transform: translate(-50%);
        }
      }

      .Lucky {
  width: 30rem;
  height: 30rem;
  margin: 0 auto;
  margin-top: -3rem;
    }
    .box {
  position: relative;
   }
 .zhizhen {
  position: absolute;
  top: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 4rem;
  height: 6rem;
  z-index: 999;
  }
  img {
    width: 100%;
    height: 100%;
  }
    }
  }
  .button {
    width: 100%;
    // height: 5rem;
    margin-top: -1rem;
    background: linear-gradient(rgb(66, 93, 88), rgb(16, 51, 44));
    padding: 1rem;
    .btn {
      width: 100%;
      height: 4.4rem;
      background: #00967d;
      border: 3px solid #51c4b2;
      margin: 0.26667rem 0.26667rem 0.4rem;
      border-radius: 0.8rem;
      text-align: center;
      line-height: 4.4rem;
      color: #fff;
      font-size: 1.3rem;
      font-weight: 600;
    }
     ::v-deep .my-swipe {
        // width: 30rem;
        margin: 0 auto;
      }
      ::v-deep .van-swipe-item {
        color: #fff !important;
        font-size: 1.3rem;
        margin: 1rem 0rem;
        width: 30rem;
        height: 4rem;
        // line-height: 2rem;
        text-align: center !important;
        // background-color: #39a9ed;
      }
  }
}
</style>
