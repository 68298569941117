<template>
    <div class="RechargeTwo">
        <div class="Addressdetails-title">
            <van-icon
                name="arrow-left"
                size="2rem"
                @click="$router.back()"
            />
            <div class="title">{{ title }}</div>
            <div></div>
        </div>
        <!-- 内容-->
        <div class="body">
            <div class="bodyimg">
                <img src="../assets/newrechangebj.png" />
            </div>
            <div class="content">
                <div class="contentone">
                    <div>{{$t('rechargettwo.name')}}</div>
                    <div class="rightflex">
                        {{offlinearr.bank_name}}
                        <img class="rightimg" src="../assets/RechargeTworight.png" @click="share(offlinearr.bank_name)" />
                    </div>
                </div>
                <div class="contentone">
                    <div>{{$t('rechargettwo.bank')}}</div>
                    <div class="rightflex">
                        {{offlinearr.bank}}
                        <img class="rightimg" src="../assets/RechargeTworight.png" @click="share(offlinearr.bank)" />
                    </div>
                </div>
                <div class="contentone">
                    <div>{{$t('rechargettwo.account')}}</div>
                    <div class="rightflex">
                        {{offlinearr.bank_id}}
                        <img class="rightimg" src="../assets/RechargeTworight.png" @click="share(offlinearr.bank_id)" />
                    </div>
                </div>
                <div class="contentone">
                    <div>{{$t('rechargettwo.czje')}}</div>
                    <div class="rightflex">
                        {{qqdata.money}} USDT ≈<span class="bule">&nbsp;{{parseFloat(offlinearr.amount).toFixed(3)}} COP</span>
                        <!-- {{offlinearr.bank_id}} -->
                        <!-- <img class="rightimg" src="../assets/RechargeTworight.png" @click="share(offlinearr.bank_id)" /> -->
                    </div>
                </div>
            </div>
            <div class="pingz">
                <div class="pingztitle">{{$t('rechargettwo.qtjczjt')}}</div>
                <div class="pingzbody">
                    <van-uploader :after-read="afterRead" v-if="imgurl == ''">
                        <!-- <van-button icon="plus" type="primary">上传文件</van-button> -->
                        <div>+</div>
                    </van-uploader>
                    <img v-if="imgurl != ''" :src="imgurl" style="width: 100%;height: 100%;"/>
                </div>
                <div class="pingzbutton" @click="topostoffl">{{$t('addbank.btn')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { getoffline, uploadimg, postoffline } from '@/api/index'
import { Toast } from 'vant'
export default {
  data () {
    return {
      title: this.$t('addbank.banktitle'),
      qqdata: {
        pay_id: '',
        entrance: '',
        money: '',
        image: '',
        order_no: '',
        type: 2
      },
      fileList: [],
      offlinearr: '',
      imgurl: ''
    }
  },
  created () {
    this.qqdata.pay_id = this.$route.query.pay_id
    this.qqdata.entrance = this.$route.query.entrance
    this.qqdata.money = this.$route.query.money
    this.qqdata.order_no = this.$route.query.order_no
    // console.log(this.$route.query)
    this.getoffl()
  },
  methods: {
    async afterRead (file) {
    //   const formData = new FormData()
    //   formData.append('file', file.file)
    //   console.log(formData.get('file'))
    //   this.files = file.file
      const formData = new FormData()
      formData.append('file', file.file)
      const res = await uploadimg(formData)
      this.imgurl = res.data.fullurl
      this.qqdata.image = res.data.url
      console.log(res, 'img')
    },
    async getoffl () {
      const url = '/api/recharge/offline?pay_id=' + this.qqdata.pay_id + '&money=' + this.qqdata.money
      // console.log(data)
      const res = await getoffline(url)
      this.offlinearr = res.data
      console.log(res)
    },
    async topostoffl () {
      if (this.qqdata.image == '') { // eslint-disable-line
        Toast({
          message: this.$t('addbank.tssan'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      const res = await postoffline(this.qqdata)
      console.log(res, 'post')
      if (res.code == 1) { // eslint-disable-line
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        this.$router.push('/record')
      }
    },
    share (e) { // 复制
      this.$copyText(e).then()
      Toast({
        message: this.$t('Charge.Success'),
        duration: 1200,
        className: 'noticeWidth' // 所加样式名
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../style/theme/style.less'; // 引入主题样式文件
.RechargeTwo{
    color: rgba(@apptext);
    // background-image: url("../assets/indexback.png");
    background-color: rgba(@baseColor,1);
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 32rem;
    ::v-deep .van-icon{
        color: rgba(@apptext);
    }
    .Addressdetails-title {
        padding-top: 1.5rem;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2.7rem;
        padding: 2rem;
        .title {
            color: rgba(@apptext);
            font-size: 1.8rem;
            font-weight: 700;
        }
        ::v-deep .van-icon-arrow-left:before {
        margin-top: 0.4rem;
        // color: red;
        }
    }
    .body{
        margin: 0 2rem;
        font-size: 1.5rem;
        .content{
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            border: 2px solid #E1E1E1;
            padding: 1.5rem;
            margin-bottom: 2rem;
            .contentone{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 1rem;
                .rightflex{
                    display: flex;
                    font-size: 1.2rem;
                    color: rgba(@apptext);
                    .rightimg{
                        width: 2rem;
                        margin-left: 1rem;
                    }
                    .bule{
                        color: #40B3D2;
                    }
                }
            }
        }
        // .xian{
        //     border-bottom: 1px solid #393A48;
        //     margin: 2rem 0;
        //     margin-top: 3rem;
        // }
        .pingz{
            .pingztitle{
                color: #333;
                font-size: 1.5rem;
                font-weight: 700;
                margin-bottom: 2rem;
            }
            .pingzbody{
                width: 25rem;
                height: 17rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #F4F6F8;
                color: #333 !important;
                font-size: 9rem;
                font-weight: 700;
                border-radius: 1rem;
                margin-left: 4.5rem;
            }
            .pingzbutton{
                width: 100%;
                background: #EFBB0B;
                color: #333;
                font-weight: bold;
                display: flex;
                justify-content: center;
                border-radius: 20px;
                padding: 1.5rem 0;
                margin-top: 3rem;
            }
        }
        .bodyimg{
            width: 100%;
			
            margin: 5rem 0;
			margin-bottom: 3rem;
            // margin-left: 10rem;
        }
    }
}
.contentone div:nth-child(1){
    color: #8D8D8D;
}
</style>
