<template>
	<!-- 首页 -->
	<!-- <div class="Homebg"> -->
	<div class="homevue">
		<!-- <div class="top">
      <div class="logo">
        <img src="../assets/logo.png" alt="" />
      </div>
      <div class="title">Xiao-i Robot</div>
      <div class="signIn" @click="tosignin">
        <img src="../assets/sign.png" alt="" />
      </div>
    </div> -->
		<div class="top">
			<div class="topone">
				<div>{{ $t('home.top') }}</div>
				<div class="toponeright">
					<!-- <img src="../assets/indexkefu.png" @click="tosignin" /> -->
					<!-- <img src="../assets/indextop.png" /> -->
					<div class="right">
						<van-switch v-model="checkedzt" active-color="#EFBB0B" size="20px" @change="qhzt" />
						<div class="rightqhzt">{{qhtitle}}</div>
					</div>
					<!-- <img src="../assets/indexkefu.png" @click="tosignin" v-if="kfurlqh == 0"/>
          <img src="../assets/indexkefu2.png" @click="tosignin" v-if="kfurlqh == 1"/> -->
					<img src="../assets/indextop2.png" @click="$router.push('/announcement')" v-if="kfurlqh == 0"
						style="width: 2.5rem;height: 2.5rem" />
					<img src="../assets/indextop2two.png" @click="$router.push('/announcement')" v-if="kfurlqh == 1"
						style="width: 2.5rem;height: 2.5rem" />
					<!-- <img src="../assets/indextop3.png" @click="tokefu" style="margin-right: 0;" /> -->
				</div>
			</div>
			<div class="toptwo">
				{{username}}
			</div>
		</div>
		<div class="Broadcast">
			<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
				<van-swipe-item v-for="(i, v) in bannerlist" :key="v" @click="togift(i.href)">
					<img class="hornimg" :src="i.url" alt="" />
				</van-swipe-item>
			</van-swipe>
		</div>
		<div class="infos" @click="toAnnouncement">
			<div class="megaphone">
				<img src="../assets/horn.png" alt="" class="infosimg" />
			</div>
			<div class="gonggao">
				<marquee class="announcement" style="margin: 0.4rem 0.625rem">
					<span style="
                  font-size: 1.4rem;
                  font-family: PingFang SC;
                  font-weight: 400;
                " class="gonggaospan">{{ announcement }}</span>
				</marquee>
			</div>
			<div class="arrow">
				<img src="../assets/arrowheads.png" alt="" class="infosimg" />
			</div>
		</div>
		<!-- <div class="usdt">
      <div class="usdtleft">
        <img src="../assets/indexlin.png" />
        <div>Latest News</div>
      </div>
      <div class="ustright">
        <div>+{{coin}}&nbsp;</div>
        <div>usdt</div>
      </div>
    </div> -->
		<!-- <div class="content">
      <div class="time">
        <div class="time-DAY">
          <div class="time-title">{{ $t("home.timeday") }}</div>
          <div>{{ day }}</div>
        </div>
        <div class="time-MIN">
          <div class="time-title">{{ $t("home.timehour") }}</div>
          <div>{{ shi }}</div>
        </div>
        <div class="time-HOUR">
          <div class="time-title">{{ $t("home.timemin") }}</div>
          <div>{{ fen }}</div>
        </div>
        <div class="time-SEC">
          <div class="time-title">{{ $t("home.timesec") }}</div>
          <div>{{ miao }}</div>
        </div>
      </div>
    </div> -->
		<div class="homebody">
			<!-- 11.14修改one-->
			<div class="inbody">
				<div class="bodyone">
					<div class="bodyonediv" @click="$router.push('/companycetails')">
						<img src="../assets/inbody.png" />
						<div>{{$t('addhome.inbodyyi')}}</div>
					</div>
					<div class="bodyonediv" @click="$router.push('/recharges')">
						<img src="../assets/inbody2.png" />
						<div>{{$t('addhome.inbodyer')}}</div>
					</div>
					<div class="bodyonediv" @click="towithdraw">
						<img src="../assets/inbody3.png" />
						<div>{{$t('addhome.inbodysan')}}</div>
					</div>
					<div class="bodyonediv" @click="todowapp">
						<img src="../assets/inbody4.png" />
						<div>{{$t('addhome.inbodyxz')}}</div>
					</div>
					<!-- <div class="bodyonediv" v-if="dwstatus == 0" @click="torecharge">
            <img src="../assets/inbody3er.png" />
            <div>Withdrawal</div>
          </div>  -->
				</div>
				<div class="bodyone" style="margin-top: 1rem;">
					<div class="bodyonediv">
						<img src="../assets/homeqd.png" @click="$router.push('/signin')" />
						<!-- ../assets/inbody5.png-->
						<div>{{$t('addhome.homeqd')}}</div> <!-- addhome.inbodysi-->
					</div>
					<div class="bodyonediv" @click="toactive(1)">
						<img src="../assets/actavect.png" /> <!-- ../assets/inbody6.png-->
						<div>{{$t('my.envelope')}}</div> <!-- addhome.inbodywu-->
					</div>
					<!-- Community -->
					<div class="bodyonediv" @click="toactive(2)">
						<img src="../assets/shequ.png" />
						<!-- <div>{{$t('addhome.inbodyliu')}}</div> -->
						<div>Comunidad</div>
					</div>
					<div class="bodyonediv" @click="tokefu">
						<img src="../assets/inbody8.png" />
						<div>{{$t('addhome.inbodykf')}}</div>
					</div>
				</div>
			</div>
			
		<div style="width: 100%;height: 7rem;margin-top: -2rem;margin-bottom: 2rem;" @click="$router.push('/Fund')" v-if="jjopen==1">
			<img style="width: 100%;height: 100%;" src="../assets/jjrk.png" />
		</div>
			
			<!-- 11.14修改My wallet-->
			<div class="wallet">
				
				<div class="wallettitle">{{$t('addhome.wallet')}}</div>
				<div class="walletbody bibody">
					<div class="walletflex">
						<span class="wallzione">{{$t('addhome.walletyi')}}: </span><span
							class="wallzitwo">{{parseFloat(walletarr.total_balance).toFixed(2)}} USDT</span>
					</div>
					<div class="walletmargin walletflex">
						<span class="wallzione">{{$t('addhome.walleter')}}: </span><span
							class="wallzitwo">{{parseFloat(money).toFixed(2)}} USDT</span>
					</div>
					<div class="walletmargin walletflex">
						<span class="wallzione">{{$t('addhome.walletsan')}}: </span><span
							class="wallzitwo">{{parseFloat(award_profit).toFixed(2)}} USDT</span>
					</div>
					
					<!-- <div class="walletmargin walletflex">
						<span class="wallzione">{{$t('addhome.walletsi')}}: </span><span
							class="wallzitwo">{{parseFloat(today_earn).toFixed(2)}} USDT</span>
					</div> -->
				</div>
				<div class="walletbody bibody" style="margin-top: 2rem;">
				
					<div class="walletflex ">
						<span class="wallzione">{{$t('addhome.walletqi')}}: </span><span
							class="wallzitwo">{{walletarr.per_money}} USDT</span>
					</div>
					<div class="walletmargin walletflex">
						<span class="wallzione">{{$t('addhome.walletwu')}}: </span><span
							class="wallzitwo">{{parseFloat(walletarr.coin).toFixed(2)}} OPT</span>
					</div>
					<div class="walletmargin walletflex">
						<span class="wallzione">{{$t('addhome.walletliu')}}: </span><span
							class="wallzitwo">{{parseFloat(walletarr.coin_money).toFixed(2)}} USDT</span>
					</div>
				</div>
			</div>
			<!-- 11.14修改插图-->
			<!-- <div class="ctimg" @click="$router.push('/Activity')">
				<img src="../assets/indexhbsc.png" />
			</div> -->
			<!-- 抽奖-->
			<!-- <div class="indexcj" @click="tocj()">
				<img src="../assets/addhome2.png" class="indexcjimg" />
				<div>
					<div class="indexcjzi">sorteo</div>
					<div>Exclusivo y tu suerte.</div>
				</div>
				
			</div> -->
			<div class="indexbody">
				<div class="currencies">{{ $t("home.currencies") }}</div>
				<div v-if="btclist.length>0" class="bibody">
					<!-- 第一种币 -->
					<div class="Bitcoin" @click="todiagram(btclist[0].symbol, btclist[0].rate)">
						<div class="Bitcoin-left">
							<div class="Bitcoin-img">
								<img src="../assets/coin1.png" alt="" />
							</div>
							<div class="Bitcoin-name">
								<div style="font-size: 1.4rem;font-weight: bold;">
									{{ btclist[0].name }}
								</div>
								<div style="font-size: 1rem;">
									{{ btclist[0].category }}
								</div>
							</div>
						</div>
						<div class="Bitcoin-right">
							<div class="Price">$ {{ btclist[0].price }}</div>
							<div :class="{ red: btclist[0].rate.indexOf('-') !== -1 }" class="increase">
								{{
                  parseFloat(btclist[0].rate) > 0
                  ? `+${btclist[0].rate}`
                  : btclist[0].rate
                }}
							</div>
						</div>
					</div>
					<div class="indexbodyx"></div>
					<!-- 第二种币 -->
					<div class="Bitcoin1" @click="todiagram(btclist[1].symbol, btclist[1].rate)">
						<div class="Bitcoin-left">
							<div class="Bitcoin-img">
								<img src="../assets/coin2.png" alt="" />
							</div>
							<div class="Bitcoin-name">
								<div style="font-size: 1.4rem;font-weight: bold;">
									{{ btclist[1].name }}
								</div>
								<div style="font-size: 1rem;">
									{{ btclist[1].category }}
								</div>
							</div>
						</div>
						<div class="Bitcoin-right">
							<div class="Price">$ {{ btclist[1].price }}</div>
							<div :class="{ red: btclist[1].rate.indexOf('-') !== -1 }" class="increase">
								{{
                  parseFloat(btclist[1].rate) > 0
                  ? `+${btclist[1].rate}`
                  : btclist[1].rate
                }}
							</div>
						</div>
					</div>
					<div class="indexbodyx"></div>
					<!-- 第三种币 -->
					<div class="Bitcoin2" @click="todiagram(btclist[2].symbol, btclist[2].rate)">
						<div class="Bitcoin-left">
							<div class="Bitcoin-img">
								<img src="../assets/coin3.png" alt="" />
							</div>
							<div class="Bitcoin-name">
								<div style="font-size: 1.4rem;font-weight: bold;">
									{{ btclist[2].name }}
								</div>
								<div style="font-size: 1rem;">
									{{ btclist[2].category }}
								</div>
							</div>
						</div>
						<div class="Bitcoin-right">
							<div class="Price">$ {{ btclist[2].price }}</div>
							<div :class="{ red: btclist[2].rate.indexOf('-') !== -1 }" class="increase">
								{{
                  parseFloat(btclist[2].rate) > 0
                  ? `+${btclist[2].rate}`
                  : btclist[2].rate
                }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 11.14修改 Help-->
			<div class="help">
				<div class="helptitle">{{$t('addhome.help')}}</div>
				<div class="helpbody">
					<div class="helpbodyone" v-for="(item,index) in bangzhulist" :key="index" @click="tohelp(item.id)">
						<div>{{item.title}}</div>
						<img src="../assets/helpright.png" />
					</div>
					<!-- <div class="helpbodyone">
            <div>Company Profile</div>
            <img src="../assets/helpright.png" />
          </div>
          <div class="helpbodyone">
            <div>Company Profile</div>
            <img src="../assets/helpright.png" />
          </div>
          <div class="helpbodyone" style="border-bottom: none;">
            <div>Company Profile</div>
            <img src="../assets/helpright.png" />
          </div> -->
				</div>
			</div>
			<!-- 11.14修改bottom-->
			<div class="bottomone">
				{{$t('addhome.bottom')}}
				<!-- <br /> -->
				<!-- {{$t('addhome.bottomer')}} -->
			</div>
			<!-- 11.14修改bottomer-->
			<div class="bottomer">
				<div class="left" style="margin-right: 3rem;">
					<div>{{$t('addhome.bottomtwo')}}</div>
					<div>{{day}} {{$t('addhome.bottomtwoday')}}</div>
				</div>
				<div class="left">
					<div>{{$t('addhome.bottomtwoer')}}</div>
					<div>{{total_revenue}} USDT</div>
				</div>
			</div>
			<!-- 11.20合作平台-->
			<div class="hezuo">
				<div class="hztitle">{{$t('addhome.hezuotitle')}}</div>
				<div class="hzbody">
					<div class="bodyflex">
						<div calss="one">
							<div class="onediv">1.
								<img src="../assets/hz1.png" />{{$t('addhome.hezuoyi')}}
							</div>
							<div class="onediv">3.
								<img src="../assets/hz2.png" />{{$t('addhome.hezuoer')}}
							</div>
							<div class="onediv">5.
								<img src="../assets/hz4.png" />{{$t('addhome.hezuosi')}}
							</div>
							<div class="onediv">7.
								<img src="../assets/hz6.png" />{{$t('addhome.hezuosi')}}
							</div>
							<div class="onediv">9.
								<img src="../assets/hz8.png" />{{$t('addhome.hezuowu')}}
							</div>
						</div>
						<div calss="one">
							<div class="onediv" style="margin-left: 0.8rem;">2.
								<img src="../assets/hz10.png" />{{$t('addhome.hezuoliu')}}
							</div>
							<div class="onediv" style="margin-left: 0.8rem;">4.
								<img src="../assets/hz3.png" />{{$t('addhome.hezuoqi')}}
							</div>
							<div class="onediv" style="margin-left: 0.8rem;">6.
								<img src="../assets/hz5.png" />{{$t('addhome.hezuoba')}}
							</div>
							<div class="onediv" style="margin-left: 0.8rem;">8.
								<img src="../assets/hz7.png" />{{$t('addhome.hezuojiu')}}
							</div>
							<div class="onediv">10.
								<img src="../assets/hz9.png" />{{$t('addhome.hezuoshi')}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 弹出框 -->
		<van-popup round v-model="show"> <!-- @click-overlay="gonggaoclcik"-->
			<div class="SignIn-content">
				<div class="content-box">
					<div class="box" :class="{ bgcolor: bgcolor >= 1 }">
						<div class="img">
							<img src="../assets/Venus.png" alt="" v-if="bgcolor < 1" />
							<img src="../assets/sign2.png" alt="" v-if="bgcolor >= 1" />
						</div>
						<div class="day">{{ $t("home.SignIn1") }}</div>
					</div>
					<div class="box" :class="{ bgcolor: bgcolor >= 2 }">
						<div class="img">
							<img src="../assets/Venus.png" alt="" v-if="bgcolor < 2" />
							<img src="../assets/sign2.png" alt="" v-if="bgcolor >= 2" />
						</div>
						<div class="day">{{ $t("home.SignIn2") }}</div>
					</div>
					<div class="box" :class="{ bgcolor: bgcolor >= 3 }">
						<div class="img">
							<img src="../assets/Venus.png" alt="" v-if="bgcolor < 3" />
							<img src="../assets/sign2.png" alt="" v-if="bgcolor >= 3" />
						</div>
						<div class="day">{{ $t("home.SignIn3") }}</div>
					</div>
					<div class="box" :class="{ bgcolor: bgcolor >= 4 }">
						<div class="img">
							<img src="../assets/Venus.png" alt="" v-if="bgcolor < 4" />
							<img src="../assets/sign2.png" alt="" v-if="bgcolor >= 4" />
						</div>
						<div class="day">{{ $t("home.SignIn4") }}</div>
					</div>
					<div class="box" :class="{ bgcolor: bgcolor >= 5 }">
						<div class="img">
							<img src="../assets/Venus.png" alt="" v-if="bgcolor < 5" />
							<img src="../assets/sign2.png" alt="" v-if="bgcolor >= 5" />
						</div>
						<div class="day">{{ $t("home.SignIn5") }}</div>
					</div>
					<div class="box" :class="{ bgcolor: bgcolor >= 6 }">
						<div class="img">
							<img src="../assets/Venus.png" alt="" v-if="bgcolor < 6" />
							<img src="../assets/sign2.png" alt="" v-if="bgcolor >= 6" />
						</div>
						<div class="day">{{ $t("home.SignIn6") }}</div>
					</div>
					<div class="Seven" :class="{ bgSeven: bgcolor >= 7 }"
						style="padding: 0.5rem;align-items: center;padding-left: 1rem;">
						<div class="info" style="text-align: center;">
							<div class="tian">
								<div class="day" style="width: 100%;margin-bottom: 1rem;">{{ $t("SignIn.SignIn7") }}
								</div>
								<!-- <div style="font-size: 1rem">Mysterious package</div> -->
							</div>
							<div class="title" style="margin-top: 0;">
								{{$t('addhome.tcyi')}}<br />{{$t('addhome.tcer')}}
							</div>
						</div>
						<div class="img">
							<img src="../assets/gift.png" alt="" />
						</div>
					</div>
				</div>
				<div class="button">
					<button class="btn" @click="receive">{{ chick }}</button>
				</div>
			</div>
		</van-popup>
		<!-- 公告 -->
		<van-popup round v-model="gonggaoshow">
			<div class="gonggaobox">
				<img class="gonggao" src="../assets/gonggao.png" alt="" />
				<div class="boxtitle">{{$t('addhome.tcsan')}}</div>
				<div class="info">
					<div ref="detils"></div>
				</div>
				
				<div class="changegg">
					<div @click="Previous()">{{$t('addmy.Previous')}}</div>
					
				    <div id="">{{this.ggindex+1}}/{{this.detils.length}}</div>
				
					<div @click="Next()">{{$t('addmy.Next')}}</div>
				</div> 
			</div>
		</van-popup>
		
		<!-- 客服弹窗-->
		<van-overlay :show="kfshow" z-index="2024">
			<div class="wrapper">
				<div class="block">
					<p>{{$t('addhome.kefu')}}</p>
					<div class="kflist">
						<div class="kflistone" @click="tokfurl(kfurl[0])">
							<img src="../assets/inbody8.png" />
							<span>Telegram</span>
						</div>
						<div class="kflistone" @click="tokfurl(kfurl[1])">
							<img src="../assets/inbody8.png" />
							<span>Whatsapp</span>
						</div>
					</div>
					<div class="btn" @click="kfshow = false">{{$t('addhome.gb')}}</div>
				</div>
			</div>
		</van-overlay>
		<!-- 气泡拖拽-->
		<BaoXiang v-if="turntable == 1" :turn_table="turntable" @changecjx="cjx"></BaoXiang>
		<!-- /气泡拖拽-->
		<suspended v-if="telegram || whatsapp"></suspended>
		<TabBar></TabBar>
	</div>
	<!-- </div> -->
</template>

<script>
	import {
		getreceive,
		getSignin,
		gethomeapi,
		Noticelistapi,
		myuserapi,
		assistdetailsapi
	} from '@/api/index'
	// import { gethomeapi } from '@/api/index'
	import {
		Toast
	} from 'vant'
	import Suspended from '../components/Suspended.vue'
	import BaoXiang from '../components/BaoXiang.vue'
	export default {
		name: 'HoMe',
		components: {
			Suspended,
			BaoXiang
		},
		data() {
			return {
				gonggaoshow: false,
				show: false,
				bgcolor: 0,
				bannerlist: [{
					id: 77,
					thumb_style: '',
					url: '/uploads/20230807/d5337373d4f168822352fec6b6c9fe79.png'
				}],
				announcement: '',
				btclist: [],
				username: '', // 用户名
				coin: '',
				time: 0,
				day: 0,
				shi: 0,
				fen: 0,
				miao: 0,
				times: null,
				chick: this.$t('SignIn.Sign'),
				float: 1,
				telegram: '',
				whatsapp: '',
				isLoading: false,
				// bfb: '-0.01%',
				statustype: 0,
				gonggaolist: [],
				detils: [], // 公告具体内容
				turntable: 0,
				dwstatus: 0, // 提款状态
				walletarr: {}, // 我的内容
				profit_return: 0,
				award_profit: 0,
				today_earn: 0,
				money: 0,
				rate: 0,
				bangzhulist: [], // 帮助列表
				total_revenue: '',
				offset: {
					x: 200,
					y: 400
				},
				kfurl: '',
				kfurlqh: 0,
				checkedzt: false,
				qhtitle: this.$t('addmy.addw'),
				kfshow: false, // 客服弹窗
				signtype: true, // 签到按钮防重复点击
				isclick: '',
				ggindex:0,
				jjopen: ''
			}
		},
		created() {
			this.qhztcon() // 判断当前右上角签到图标的主题颜色字样
			if (localStorage.getItem('theme') == 'dark') {
				this.kfurlqh = 1
				// console.log(this.kfurlqh)
			} else {
				this.kfurlqh = 0
			}
			// 首页数据
			this.homelist()
			// 签到天数接口
			this.Signin()
			// 公告
			this.Noticelistapi()
			if (parseFloat(this.statustype) === 0) {
				if (sessionStorage.getItem('show')) {
					this.show = false
				} else {
					this.show = true
					sessionStorage.setItem('show', 1)
				}
			}
			this.gonggaoshow = true // 公告显示
			// if (!this.show) {
			//   公告弹窗
			//   if (sessionStorage.getItem('gonggaoshow')) {
			//     this.gonggaoshow = false
			//   } else {
			//     this.gonggaoshow = true
			//     sessionStorage.setItem('gonggaoshow', 1)
			//   }
			// }
			this.getmyuserapi() // 获取我的
			this.assistdetails() // 获取帮助
		},
		destroyed() {
			// clearInterval(this.times)
		}, //$router.push('/Community')
		methods: {
			todowapp() {
				// console.log(111);
				window.location.href = 'https://www.sigopt.site/app'
			},
			toactive(index) {
				if (index == 1) {
					// if (this.isclick == 1) {
						this.$router.push('/ActivityCenter')
					// } else {
					// 	Toast({
					// 		message: 'In Development',
					// 		duration: 1200,
					// 		className: 'noticeWidth' // 所加样式名
					// 	})
					// }
				}
				if (index == 2) {
					// if (this.isclick == 1) {
						this.$router.push('/Community')
					// } else {
					// 	Toast({
					// 		message: 'In Development',
					// 		duration: 1200,
					// 		className: 'noticeWidth' // 所加样式名
					// 	})
					// }
				}

			},
			// gonggaoclcik () {
			// console.log(111)
			// 公告弹窗
			//  this.gonggaoshow = true
			//  sessionStorage.setItem('gonggaoshow', 1)
			// },
			togaonggao() {
				this.$router.push('/Announcement')
			},
			// // 公告
			async Noticelistapi() {
				const data = {
					type: '1',
					is_top:1
				}
				const res = await Noticelistapi(data)
				console.log(res, '公告')
				this.detils = res.data
				this.$refs.detils.innerHTML = this.detils[this.ggindex].content
				// this.gonggaolist = res.data
				// this.gonggaolist = this.gonggaolist.slice(0, 2)
			},
			onRefresh() {
				setTimeout(() => {
					this.$router.go(0)
				}, 1000)
			},
			// 去抽奖
			tocj() {
				if (this.turntable == 0) {
					Toast({
						message: 'The activity has ended',
						duration: 1200,
						className: 'noticeWidth' // 所加样式名
					})
					return false
				}
				this.$router.push('/turntable')
			},
			// 去活动页面
			togift(href) {
				this.$router.push(href)
			},
			// 去官网
			toguanwang() {
				// console.log(111)
			},
			async homelist() {
				const res = await gethomeapi()
				// console.log(res, 'home')

				this.username = res.data.nickname
				this.turntable = res.data.turntable
				this.coin = res.data.coin
				this.bannerlist = res.data.banner
				this.announcement = res.data.announcement
				this.btclist = res.data.symbol
				this.time = res.data.found_time
				this.telegram = res.data.telegram
				this.whatsapp = res.data.whatsapp
				this.dwstatus = res.data.dw_status
				this.kfurl = res.data.service_link
				this.total_revenue = res.data.total_revenue

				// console.log('6666666', this.btclist)
				this.times = setInterval(() => {
					const sjchuo = Date.now() - this.time * 1000
					this.formatDuring(sjchuo)
				}, 1000)
			},
			// 时间戳转天，时分秒
			formatDuring(mss) {
				const days = parseInt(mss / (1000 * 60 * 60 * 24))
				const hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
				const minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60))
				const seconds = ((mss % (1000 * 60)) / 1000).toFixed(0)
				// console.log(days, hours, minutes, seconds)
				this.day = days
				this.shi = hours
				this.fen = minutes
				this.miao = seconds
			},
			// 签到天数接口
			async Signin() {
				const res = await getSignin()
				console.log(res, '签到')
				this.bgcolor = res.data.sign_count
				this.statustype = res.data.status
				if (res.data.status === 0) {
					this.chick = this.$t('SignIn.Sign')
					this.signtype = true // 防止重复点击
				} else {
					this.chick = this.$t('SignIn.chick')
					this.signtype = true // 防止重复点击
				}
			},
			// 签到按钮
			async receive() {
				if (this.signtype) {
					this.signtype = false // 防止重复点击
					const res = await getreceive()
					if (res.code !== 1) {
						Toast({
							message: res.msg,
							duration: 1200,
							className: 'noticeWidth' // 所加样式名
						})
						this.signtype = true // 防止重复点击
					} else {
						Toast({
							message: res.msg,
							duration: 1200,
							className: 'noticeWidth' // 所加样式名
						})
						this.Signin()
					}
					setTimeout(() => {
						this.show = false
					}, 1000)
				}
				// setTimeout(() => {
				//   // 公告弹窗
				//   if (sessionStorage.getItem('gonggaoshow')) {
				//     this.gonggaoshow = false
				//   } else {
				//     this.gonggaoshow = true
				//     sessionStorage.setItem('gonggaoshow', 1)
				//   }
				// }, 1600)
			},
			// 去公告页面
			toAnnouncement() {
				this.$router.push('/announcement')
			},
			// 去签到页面
			tosignin() {
				this.$router.push('/signin')
			},
			// 去k线图
			todiagram(name, bfb) {
				// console.log(name)
				// console.log(bfb)
				this.$router.push({
					path: '/diagram',
					query: {
						name,
						bfb
					}
				})
				// this.$router.push({
				//   path: '/gift'
				// })
			},
			//11.15
			// 去提现
			towithdraw() {
				this.$router.push('/withdraw')
				// if(this.dwstatus == 1){
				//   this.$router.push('/withdraw')
				// }else{
				//   Toast({
				//     message: 'Please activate the machine first',
				//     duration: 2000,
				//     className: 'noticeWidth' // 所加样式名
				//   })
				//   this.$router.push('/mining')
				// }
			},
			torecharge() {
				// 去充值页面
				this.$router.push('/recharges')
			},
			//get 我的钱包
			async getmyuserapi() {
				const res = await myuserapi()
				this.walletarr = res.data.user
				this.isclick = res.data.invite_open
				this.jjopen = res.data.fund_open
				this.profit_return = parseFloat(this.walletarr.profit_return).toFixed(2)
				this.award_profit = parseFloat(this.walletarr.award_profit).toFixed(2)
				this.today_earn = parseFloat(this.walletarr.today_earn).toFixed(2)
				this.money = parseFloat(this.walletarr.money).toFixed(2)
				this.rate = parseFloat(res.data.rate).toFixed(2)
			},
			// get帮助列表
			async assistdetails() {
				const data = {
					type: 2
				}
				const res = await assistdetailsapi(data)
				// console.log(res)
				this.bangzhulist = res.data
			},
			tohelp(id) { // 跳转帮助详情
				this.$router.push({
					path: '/bangzhudetails',
					query: {
						id
					}
				})
			},
			tokefu() {
				// window.location.href = this.kfurl
				this.kfshow = true
			},
			tokfurl(item) {
				window.location.href = item
			},
			cjx(e) {
				// console.log(e)
				this.turntable = e
			},
			qhzt(e) {
				// console.log(e)
				// console.log(this.qhtitle)
				if (this.qhtitle == this.$t('addmy.addw')) {
					localStorage.setItem('theme', 'default')
					window.location.reload()
				} else {
					console.log(1)
					localStorage.setItem('theme', 'dark')
					window.location.reload()
				}
			},
			qhztcon() {
				if (localStorage.getItem('theme') == 'dark') {
					this.qhtitle = this.$t('addmy.addw')
				} else if (localStorage.getItem('theme') == 'default') {
					this.qhtitle = this.$t('addmy.addh')
				}
			},
			Previous() {
				if(this.ggindex<=0) {
					Toast({
						message: this.$t('addmy.last'),
						duration: 1200,
						className: 'noticeWidth' // 所加样式名
					})
				}else {
					this.ggindex--
					this.$refs.detils.innerHTML = this.detils[this.ggindex].content
				}
			},
			Next() {
				if(this.ggindex>=this.detils.length-1) {
					Toast({
						message: this.$t('addmy.last'),
						duration: 1200,
						className: 'noticeWidth' // 所加样式名
					})
				}else {
					this.ggindex++
					this.$refs.detils.innerHTML = this.detils[this.ggindex].content
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	@import '../style/theme/style.less'; // 引入主题样式文件

	html {

		// background-color: #060606;
		.homevue {
			width: 100%;
			// height: 140vh;
			background-color: rgba(@baseColor, 1);
			// background-image: url("../assets/indexback.png");
			background-size: cover;
			background-repeat: no-repeat;
			// padding: 0rem 1.6rem;
			padding-top: 2.5rem;
			padding-bottom: 5rem;
			overflow-y: scroll;
			// .top {
			//   position: relative;
			//   width: 100%;
			//   display: flex;
			//   justify-content: center;

			//   .logo {
			//     width: 1.9rem;
			//     height: 2.1rem;
			//   }

			//   .title {
			//     color: #fff;
			//     line-height: 2.2rem;
			//     margin-left: 0.4rem;
			//     font-size: 1.6rem;
			//     font-weight: bold;
			//   }

			//   .signIn {
			//     width: 1.75rem;
			//     height: 1.75rem;
			//     position: absolute;
			//     top: 0;
			//     right: 1rem;
			//     z-index: 9;
			//   }
			// }

			.top {
				padding: 0rem 1.6rem;

				.topone {
					display: flex;
					justify-content: space-between;
					align-items: center;
					color: rgba(@apptext);
					font-size: 1.625rem;

					// opacity: 0.2;
					// filter: blur(27px);
					.toponeright {
						display: flex;
						align-items: center;

						.right {
							display: flex;
							align-items: center;
							margin-right: 1rem;

							.rightqhzt {
								margin-left: .5rem;
							}
						}

						img {
							margin-right: 1rem;
						}
					}
				}

				.toptwo {
					color: rgba(@apptext);
					font-size: 2.125rem;
					font-weight: 700;
				}
			}

			.top .topone img {
				width: 2rem;
				height: 2rem;
			}

			.Broadcast {
				margin-top: 2.5rem;
				z-index: 9;
				padding: 0rem 1.6rem;

				::v-deep .van-swipe {
					z-index: 9;
				}

				::v-deep .van-swipe-item {
					width: 34.3rem;
					height: 16.8rem;
					line-height: 16, 8rem;
					text-align: center;
					z-index: 9;

					.hornimg {
						width: 100%;
						height: 100%;
						border-radius: 0.5rem !important;
						z-index: 9;
					}
				}
			}

			.infos {
				display: flex;
				justify-content: space-between;
				background-color: rgba(@baseColor, 1);
				margin-top: 1rem;
				padding: 0 1rem;
				border-radius: 10px;
				padding: 0rem 1.6rem;

				.gonggao {
					margin-top: 0.5rem;

					.gonggaospan {
						color: rgba(@apptext);
					}

					.announcement {
						width: 28rem;
						// height: 100%;
					}
				}

				.megaphone {
					width: 1.5rem;
					height: 1.5rem;
					margin-top: 1rem;
				}

				.arrow {
					width: 1.5rem;
					height: 1.5rem;
					margin-top: 1rem;
				}

				.infosimg {
					width: 2rem;
					height: 1.5rem;
				}
			}

			.homebody {
				margin-top: 2rem;
				background-color: rgba(@pageBgColor, 1);
				border-top-right-radius: 3rem;
				border-top-left-radius: 3rem;
				padding: 0rem 1.6rem;
				padding-top: .5rem;
				padding-bottom: 3rem;
			}

			.content {
				// width: 33rem;
				// height: 12.1rem;
				// border: 1px solid #0A3D2D;
				// margin: 0 auto;
				// background-color: rgba(255, 255, 255, 0.1);
				border-radius: 1rem;
				margin-top: 1.825rem;
				background: #F4F6F8;
				padding: 1.5rem 0;
				color: #9B9C9D;
				// padding: 1rem;
				// box-shadow: 0rem 0.1rem 0.5rem 0.5rem rgba(255, 255, 255, 0.2);

				.content-top {
					display: flex;
					margin-top: 0.5rem;
					// margin-left: 1.8rem;

					.Money {
						width: 2.05rem;
						height: 2.05rem;
					}

					.content-title {
						line-height: 2.2rem;
						font-size: 1.4rem;
						margin-left: 0.5rem;
					}
				}

				.time {
					width: 100%;
					display: flex;
					justify-content: space-evenly;
					color: #4065FF;

					// margin-top: 2.25rem;
					.time-DAY,
					.time-MIN,
					.time-HOUR,
					.time-SEC {
						width: 25%;
						text-align: center;
						border-right: 1px solid #E1E3E5;
						font-size: 1.5rem;

						.time-title {
							margin-bottom: 0.6rem;
							color: #9B9C9D;
							font-size: 1.2rem;
						}
					}

					.time-SEC {
						border-right: 0px solid #262626;
					}
				}
			}

			.indexbody {
				// background: #26272E;
				border-radius: 0.625rem;
				// padding: 1.875rem 0;
				margin-top: 3rem;
				// .indexbodyx{
				//   width: 27rem;
				//   margin-left: 4rem;
				//   border-bottom: 0.125rem solid #3C3D43;
				// }
			}

			.currencies {
				// margin-top: 2rem;
				color: rgba(@apptext);
				font-size: 2rem;
				font-weight: 700;
				margin-bottom: 2rem;
			}

			.Bitcoin,
			.Bitcoin1,
			.Bitcoin2 {
				// width: 34.2rem;
				height: 5rem;
				// background-color: #2b2b2b;
				// background: url("../assets/btc.png") no-repeat;
				background-size: 100% 100%;
				border-radius: 1rem;
				// margin-top: 1.5rem;
				color: rgba(@apptext);
				display: flex;
				justify-content: space-between;
				align-items: center;
				// padding: 1.5rem;

				.Bitcoin-left {
					display: flex;
					align-items: center;

					.Bitcoin-img {
						// margin-top: 0.5rem;
						width: 2.84rem;
						height: 2.84rem;
						margin-right: 1rem;
					}

					.Bitcoin-name {
						padding-top: 0.3rem;
					}
				}

				.Bitcoin-right {
					text-align: right;

					.Price {
						color: rgba(@apptext);
						margin-top: 0.4rem;
						font-weight: bold;
					}

					.increase,
					.red {
						color: #fd1050;
						font-size: 1.3rem;
						// margin-right: 1.5rem;
						margin-top: 0.2rem;
					}

					.increase {
						color: rgba(@indextext);
					}

					.red {
						color: #15fbb5;
					}
				}
			}

			.Bitcoin1 {
				// background: url("../assets/eth.png") no-repeat;
				background-size: 100% 100%;
			}

			.Bitcoin2 {
				// background: url("../assets/doge.png") no-repeat;
				background-size: 100% 100%;
			}

			.guanwang {
				width: 100%;
				height: 14rem;
				margin-top: 3rem;
				border-radius: 1rem;

				img {
					width: 100%;
					height: 100%;
					border-radius: 1rem;
				}
			}

			::v-deep .van-popup {
				background-color: #1f1f1f;
			}

			.SignIn-content {
				width: 34.3rem;
				height: 29.15rem;
				background-color: #fff;
				// margin-top: 2.5rem;
				padding: 1.7rem 1rem;
				border-radius: 1rem;

				.content-box {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;

					.box,
					.bgcolor {
						width: 7.4rem;
						height: 9rem;
						background-color: #FDF6E9;
						text-align: center;
						margin-bottom: 0.9rem;
						border-radius: 0.5rem;

						.day {
							color: #333333;
							font-weight: 600;
							margin-top: 1rem;
						}

						.img {
							width: 3.4rem;
							height: 3.45rem;
							margin: 0 auto;
							margin-top: 1.5rem;
						}
					}

					.bgcolor {
						background-color: #FDFBF7;
						color: #E0DEDA;
					}

					.Seven,
					.bgSeven {
						position: relative;
						width: 15.7rem;
						height: 9rem;
						background-color: #FDF6E9;
						padding: 0.8rem;
						display: flex;
						justify-content: space-between;
						// align-items: center;
						border-radius: 0.5rem;

						.info {
							font-size: 1.2rem;
							color: #000;
							font-weight: 600;

							.day {
								width: 4rem;
							}

							.tian {
								display: flex;
								justify-content: space-between;
							}

							.title {
								width: 80%;
								// font-weight: 400;
								color: #969696;
								margin-top: 1rem;
							}
						}

						.img {
							position: absolute;
							bottom: 2.2rem;
							right: 1rem;
							width: 35%;
							height: 5rem;
						}
					}

					.bgSeven {
						background-color: #FDFBF7;
					}
				}

				.button {
					width: 100%;
					text-align: center;
					margin-top: 1.3rem;

					.btn {
						background-color: #EFBB0B;
						border: none;
						width: 25.9rem;
						height: 4.4rem;
						border-radius: 4px;
						color: #333;
						font-size: 1.4rem;
						text-align: center;
						font-weight: bold;
						line-height: 4.4rem;
					}
				}
			}

			.gonggaobox {
				position: relative;
				text-align: center;
				padding-bottom: 2rem;
				background: #fff;

				.gonggao {
					width: 29rem;
				}
				
				.changegg {
					width: 100%;
					position: absolute;
					bottom: 1.5rem;
					left: 0rem;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0rem 1.5rem;
					font-size: 1.2rem;
				}

				.boxtitle {
					position: absolute;
					top: 9rem;
					left: 50%;
					transform: translateX(-50%);
					color: #000;
					font-size: 1.5rem;
					font-weight: 700;
					// margin-top: 2rem;
				}

				.info {
					position: absolute;
					top: 12rem;
					left: 50%;
					transform: translateX(-50%);
					width: 100%;
					height: 20rem;
					color: #000;
					text-align: left;
					padding: 0rem 1.5rem;
					padding-top: 1rem;
					overflow: auto;
					word-wrap: break-word;
					overflow-x: hidden;
					overflow-wrap: break-word;

					.neirong {
						padding-top: 2rem;
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-size: 1.2rem;

						.neirongtitle {
							width: 24rem;
							// overflow: hidden;
							// white-space: nowrap;
							// text-overflow: ellipsis;
							// -o-text-overflow: ellipsis;
						}

						.right {
							width: 0.8rem;
						}
					}
				}
			}
		}
	}

	/* 修改*/
	.usdt {
		color: #fff;
		font-size: 1.5rem;
		display: flex;
		background: #333;
		border-radius: 1rem;
		padding: 1.5rem;
		justify-content: space-between;
		align-items: center;
		margin-top: 1rem;

		.usdtleft {
			// display: flex;
			// align-items: center;
			width: 100%;

			// border-right: 1px solid #ccc;
			img {
				width: 3rem;
				margin-bottom: 1rem;
			}
		}

		.ustright {
			display: flex;
			width: 100%;
			justify-content: end;
			align-items: center;
		}
	}

	.usdtleft div:nth-child(1) {
		width: 2rem;
		height: 2rem;
		background: #fff;
		border-radius: 50%;
		margin-right: 2rem;
	}

	.ustright div:nth-child(1) {
		font-size: 2rem;
		color: #1FAB87;
		font-weight: 700;
	}

	.indexcj {
		margin-top: 2rem;
		background-image: url('../assets/addhome1.png');
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 3rem;

		.indexcjimg {
			width: 6rem;
		}

		.indexcjzi {
			font-size: 1.5rem;
			font-weight: 700;
		}

		.indexbx {
			display: flex;

			// justify-content: end;
			.cjX {
				width: 2rem;
				height: 2rem;
				margin-top: 2rem;
			}

			.bx {
				width: 6rem;
			}
		}
	}

	// 11.14修改
	.inbody {
		// box-shadow: 0px 3px 20px 1px rgba(89,121,251,0.2);
		// border-radius: 10px 10px 10px 10px;
		opacity: 1;
		// padding: 2rem;
		margin-top: 1.5rem;
		margin-bottom: 4rem;

		.bodyone {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-weight: 700;

			.bodyonediv {
				width: 7rem;
			}

			img {
				width: 3.5rem;
				margin-bottom: .5rem;
			}

			div {
				margin-top: .5rem;
				text-align: center;
				color: rgba(@indextext);
			}
		}
	}

	// 11.14修改my wallet
	.wallet {
		.wallettitle {
			margin: 2rem 0;
			margin-bottom: 3rem;
			font-size: 2rem;
			color: rgba(@apptext);
			font-weight: 700;
		}

		.walletbody {
			// box-shadow: 0px 3px 20px 1px rgba(89,121,251,0.2);
			// border-radius: 10px 10px 10px 10px;
			opacity: 1;
			// padding: 1.5rem 2rem;
			font-size: 1.2rem;

			.walletflex {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.walletmargin {
				margin-top: 2rem;
			}

			.wallzione {
				color: #acacac;
			}

			.wallzitwo {
				color: #02D79A;
			}
		}
	}

	// 11.14 修改插图img
	.ctimg {
		margin-top: 2rem;
	}

	// 11.14 修改help
	.help {
		.helptitle {
			margin: 2rem 0;
			font-size: 2rem;
			color: rgba(@apptext);
			font-weight: 700;
		}

		.helpbody {
			border-radius: 10px 10px 10px 10px;
			opacity: 1;
			border: 2px solid #EBEBEB;
			padding: 1rem;
			font-size: 1.4rem;
			color: rgba(@apptext);

			.helpbodyone {
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 2px solid #FBFBFB;
				padding-bottom: 1rem;
				margin-top: 1rem;

				div {
					font-weight: 700;
				}

				img {
					width: .5rem;
				}
			}
		}
	}

	// 11.14修改bottom
	.bottomone {
		margin-top: 2rem;
		color: rgba(@indextext);
		font-size: 1.3rem;
	}

	// 11.14修改bottomer
	.bottomer {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.5rem;
		margin-top: 2rem;
		font-weight: 700;

		.left {
			text-align: center;
		}
	}

	.bottomer .left div:nth-child(1) {
		color: rgba(@apptext);
	}

	.bottomer .left div:nth-child(2) {
		color: #EFBB0B;
		margin-top: 1rem;
	}

	.bibody {
		// box-shadow: 0px 3px 20px 1px rgba(89, 121, 251, 0.2);
		// border-radius: 10px 10px 10px 10px;
		opacity: 1;
		background-color: rgba(@indexbi);
		box-shadow: 0px 3px 6px 1px rgba(152, 152, 152, 0.2);
		border-radius: .5rem;
		// border: 1px solid #606060;
		padding: 1rem 1.5rem;
		// padding: 1.5rem 2rem;
	}

	// 11.20合作平台
	.hezuo {
		margin: 1rem 0;
		margin-top: 3rem;

		.hztitle {
			font-size: 1.5rem;
			font-weight: 700;
			color: rgba(@apptext);
		}

		.hzbody {
			// box-shadow: 0px 3px 20px 1px rgba(89, 121, 251, 0.2);
			border-radius: 10px 10px 10px 10px;
			// opacity: 1;
			color: rgba(@apptext);
			padding: 1.5rem 2rem;
			font-size: 1.3rem;
			font-weight: 700;

			// margin-top: 2rem;
			.bodyflex {
				display: flex;
				justify-content: space-between;

				.onediv {
					margin-top: 2rem;
					text-align: left;
				}

				// .onedivright{
				//   margin-left: 0.1rem !important;
				// }
			}

			img {
				width: 2rem;
				margin-right: .5rem;
			}
		}
	}

	/* 客服弹窗*/
	.wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		// background-color: #fff;
	}

	.block {
		width: 80%;
		// height: 120px;
		background-color: #fff;
		border-radius: 10px;
		padding: 1rem;

		p {
			text-align: center;
			font-weight: bold;
			font-size: 14px;
			margin-bottom: 2rem;
		}

		.kflist {
			margin: 0 4rem;

			.kflistone {
				display: flex;
				align-items: center;
				font-size: 14px;
				border: 2px solid #f9f9f9;
				border-radius: 10px;
				padding: .5rem;
				margin-top: 2rem;

				img {
					width: 3rem;
					height: 3rem;
				}

				span {
					margin-left: 2rem;
				}
			}
		}

		.btn {
			background-color: #EFBB0B;
			border-radius: 20px;
			font-size: 14px;
			color: #fff;
			padding: .5rem 0;
			width: 8rem;
			text-align: center;
			margin: 0 auto;
			margin-top: 4rem;
		}
	}
</style>